import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUser } from 'src/app/models/user.model'
import { UserStore } from "src/app/stores/user.store"
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserDashboardComponent } from '../user-dashboard.component';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss']
})
export class EditUserDialogComponent implements OnInit {

  editUserForm:any;
  minDate: Date;
  maxDate: Date=new Date();

  constructor(
    private formBuilder:FormBuilder,
    private userStore:UserStore,
    private _snackBar:MatSnackBar,
    private firebaseAuth: AngularFireAuth,


    public dailogRef:MatDialogRef<UserDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ){
    this.editUserForm=this.formBuilder.group({
      name:new FormControl(this.data.name, [
        Validators.required,
        Validators.minLength(4)
      ]),
      email:new FormControl(this.data.email,[
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]),
      dob:[new Date(this.data.dob.seconds*1000),Validators.required]
    });
  }

  ngOnInit(): void {
  }

  changePW(formData:any):void{
    try{
      if(formData.email){
        this.firebaseAuth.sendPasswordResetEmail(formData.email).then(()=>{
          this._snackBar.open("Your password reset link has been sent via email.","close",{ duration: 10000 })
        })
      }
    }
    catch(e){
      console.log(e)
    }
  }
  onSubmit(formData:any):void{
    let user=new IUser;
    const {name,email,dob} = formData;
    user={
      ...this.data,
      name,
      email,
      dob,
    }
    this.userStore.updateUser(user).then(()=>{
      this._snackBar.open("Update user information successfully","close",{ duration: 5000 })
    })
  }
}
