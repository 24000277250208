<!-- <section class="bg-img1 kit-overlay1">
  <div class="container size-h-3 p-tb-30 flex-col-c-c">
      <h2 class="t1-b-1 text-uppercase cl-0 txt-center m-b-25">
        {{this.projectStore?.project?.name}}
      </h2>

      <div class="flex-wr-c-c">
          <a [routerLink]="['/']" class="breadcrumb-item">
              Home
          </a>

          <a [routerLink]="['/projects']" class="breadcrumb-item">
              Projects
          </a>

          <span class="breadcrumb-item">
              Project Detail
          </span>
      </div>
  </div>
</section> -->
<section class="bg-0 p-t-100 p-b-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- style for img : max-s-full -->
              <img style="width: 100%;height: 100vh;object-fit: contain;" class="m-b-45 " [defaultImage]="'./assets/images/circle-loading.gif'" [lazyLoad]="projectStore?.project?.images?.downloadURL" alt="IMG" />
            </div>

            <!-- <div class="col-md-6 p-b-20">
                <img class="max-s-full m-b-30" [defaultImage]="'./assets/images/loading.gif'" [lazyLoad]="projectStore?.project?.images[1]?.imageDownloadURL" alt="IMG">
                <img class="max-s-full m-b-30" [defaultImage]="'./assets/images/loading.gif'" [lazyLoad]="projectStore?.project?.images[2]?.imageDownloadURL" alt="IMG">
            </div> -->

            <div class="col-md-12 p-b-20">
                <div class="p-l-35 m-b-30 p-l-0-sr991">
                    <h3 class="t1-b-3 cl-3 m-b-11 m-t--6 cardDetailTitle">
                        {{this.projectStore?.project?.name}}
                    </h3>

                    <ul class="bo-b-1 bcl-12 p-b-16 m-b-24">
                        <li class="m-b-7">
                            <span class="t1-s-5 cl-3 cardDetailText">
                                Clients:
                            </span>

                            <span class="t1-s-2 cl-2 cardDetailText">
                              {{this.projectStore?.project?.client?.name}}
                            </span>
                        </li>

                        <li class="m-b-7">
                            <span class="t1-s-5 cl-3 cardDetailText">
                                Date:
                            </span>

                            <span class="t1-s-2 cl-2 cardDetailText">
                              {{this.projectStore?.project?.created_at?.seconds*1000|date:'medium'}}
                            </span>
                        </li>

                        <li class="m-b-7">
                            <span class="t1-s-5 cl-3 cardDetailText">
                                Partners:
                            </span>

                            <span class="t1-s-2 cl-2 cardDetailText">
                              {{this.projectStore?.project?.partners}}
                            </span>
                        </li>

                        <li class="m-b-7">
                            <span class="t1-s-5 cl-3 cardDetailText">
                                Location:
                            </span>

                            <span class="t1-s-2 cl-2 cardDetailText">
                              {{this.projectStore?.project?.client?.location}}
                            </span>
                        </li>

                        <li class="m-b-7">
                            <span class="t1-s-5 cl-3 cardDetailText">
                                Website:
                            </span>

                            <span class="t1-s-2 cl-2 cardDetailText">
                              {{this.projectStore?.project?.client?.website}}
                            </span>
                        </li>
                    </ul>

                    <div class="bo-b-1 bcl-12 p-b-11 m-b-27">
                        <!-- <p class="t1-s-2 cl-6 m-b-12 cardDetailText">
                          {{this.projectStore?.project?.description}}
                        </p> -->
                        <p class="t1-s-2 cl-1 m-b-12 cardDetailText" [innerHTML]="this.projectStore?.project?.descriptions">
                            <!-- {{this.projectStore?.project?.description}} -->
                          </p>

                    </div>

                    <div>
                        <a *ngIf="this.projectStore?.project?.client?.socialMedia?.facebook" href={{this.projectStore?.project?.client?.socialMedia?.facebook}} class="fs-16 cl-5 hov-link2 trans-02 m-r-20" target="new">
                            <i class="fa fa-facebook-f"></i>
                        </a>

                        <a *ngIf="this.projectStore?.project?.client?.socialMedia?.twitter" href={{this.projectStore?.project?.client?.socialMedia?.twitter}} class="fs-16 cl-5 hov-link2 trans-02 m-r-20" target="new">
                            <i class="fa fa-twitter"></i>
                        </a>

                        <a *ngIf="this.projectStore?.project?.client?.socialMedia?.email" href={{this.projectStore?.project?.client?.socialMedia?.email}} class="fs-16 cl-5 hov-link2 trans-02 m-r-20" target="new">
                            <i class="fa fa-google-plus"></i>
                        </a>

                        <a *ngIf="this.projectStore?.project?.client?.socialMedia?.instagram" href={{this.projectStore?.project?.client?.socialMedia?.instagram}} class="fs-16 cl-5 hov-link2 trans-02 m-r-20" target="new">
                            <i class="fa fa-instagram"></i>
                        </a>

                        <a *ngIf="this.projectStore?.project?.client?.socialMedia?.linkin" href={{this.projectStore?.project?.client?.socialMedia?.linkin}} class="fs-16 cl-5 hov-link2 trans-02 m-r-20" target="new">
                            <i class="fa fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Block5 -->
        <div class="block5 flex-wr-sb-c bo-t-1 bcl-14">
            <!-- <a class="block5-1 bg-img2"  title="Training Skill"></a> -->
            <div class="col-sm-4"></div>

            <a routerLink="/projects" class="block5-2 flex-c-c bg-11 t1-m-7 text-uppercase cl-0 hov-btn1 trans-02">
                <i class="fa fa-th fs-24 m-r-10"></i>
                Projects
            </a>
            <div class="col-sm-4"></div>

            <!-- <a class="block5-3 bg-img2" title="Market Analysis"></a> -->
        </div>
    </div>
</section>
