<div style="width: 100%;">
    <app-admin-command-bar [tabData]="TABS" [name]="'User Management'" [buttonText]="'Add New User'" (onPress)="create()"></app-admin-command-bar>

    <div class="tableContainer">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col" width="5%">No</th>
                    <th scope="col" width="30%">Name</th>
                    <th scope="col" width="34%">Email</th>
                    <th scope="col" width="20%">Date of Birth</th>
                    <th scope="col" width="10%" >Action</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="this.userStore.data.length!=0">
                    <tr *ngFor=" let item of this.userStore.data;let i=index;">
                        <th scope="row">{{i+1}}</th>
                        <td>{{item?.name}}</td>
                        <td>{{item?.email}}</td>
                        <td>{{item?.dob?.seconds*1000 | date:'yyyy/MM/dd'}}</td>
                        <td>
                            <div class="actionButtonsContainer">
                                <!-- <i class="fas fa-pencil-alt" style="margin-right: 20px;" (click)=editUser(item)></i> -->
                                <mat-icon style="margin-right: 5px;" aria-label="edit" (click)=editUser(item)>mode_edit_outline</mat-icon>

                                <!-- <i class="fas fa-trash-alt" (click)=deleteUser(item)></i> -->
                                <mat-icon style="margin-left: 5px;" aria-label="delete" (click)=deleteUser(item)>delete_outline</mat-icon>
                                <mat-icon style="margin-left: 5px;" aria-label="delete" (click)=resetPassord(item)>lock_reset</mat-icon>


                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
