<!-- <section class="bg-img1 kit-overlay1">
    <div class="container size-h-3 p-tb-30 flex-col-c-c">
        <h2 class="t1-b-1 text-uppercase cl-0 txt-center m-b-25">
            {{ newsStore?.newsByKey?.title }}
        </h2>

        <div class="flex-wr-c-c">
            <a [routerLink]="['/']" class="breadcrumb-item">
                Home
            </a>

            <a [routerLink]="['/news']" class="breadcrumb-item">
                News
            </a>

            <span class="breadcrumb-item">
                News Detail
            </span>
        </div>
    </div>
</section> -->


<div class="bg-0 p-t-100 p-b-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-10 col-md-4 col-lg-3 p-b-30">
                <!-- Left bar -->
                <div>
                    <!-- Search -->
                    <!-- <form class="flex-wr-c-c m-b-45">
                        <input class="size-a-21 bg-none t1-s-5 cl-6 plh-6 bo-tbl-1 bcl-11 p-rl-20" type="text" name="search" placeholder="Search...">

                        <button class="size-a-22 fs-18 cl-0 bg-11 hov-btn1 trans-02">
                            <i class="fa fa-search"></i>
                        </button>
                    </form> -->

                    <!-- Categories -->
                    <div class="p-b-32">
                        <h4 class="t1-m-1 text-uppercase cl-3 kit-underline1 p-b-6">
                            Categories
                        </h4>

                        <ul class="p-t-22">

                            <li class="bo-b-1 bcl-14 m-b-18 customLink" (click)="categoryClicked(null)">
                                <a class="flex-wr-sb-c t1-s-5 cl-5 hov-link2 trans-02 p-tb-3">
                                    <span>All Categories</span>
                                </a>
                            </li>

                            <li class="bo-b-1 bcl-14 m-b-18 customLink" *ngFor="let item of newsCategoryStore.newsCategoriess" (click)="categoryClicked(item)">
                                <a class="flex-wr-sb-c t1-s-5 cl-5 hov-link2 trans-02 p-tb-3">
                                    <span>{{ item?.name }}</span>
                                </a>
                            </li>

                        </ul>
                    </div>

                    <!--  -->
                    <!-- <div class="bg-11 p-all-30 m-b-46">
                        <p class="t1-m-1 cl-0 m-b-13">
                            Want to receive news?
                        </p>

                        <p class="t1-s-2 cl-15 m-b-25">
                            Many desktop publishing packa-ges and web page editors now use Lorem Ipsum model text.
                        </p>

                        <a href="#" class="d-inline-flex flex-c-c size-a-1 bg-0 t1-s-2 cl-6 text-uppercase hov-btn1 trans-02 p-rl-10">
                            Subcribe
                        </a>
                    </div> -->

                </div>
            </div>

            <div class="col-sm-10 col-md-8 col-lg-9 p-b-30">
                <div class="p-l-50 p-l-15-sr991 p-l-0-sr767">
                    <!-- News detail -->
                    <div class="m-b-25">
                        <h4 class="t1-b-3 cl-3 m-b-11">
                            {{ newsStore?.newsByKey?.title }}
                        </h4>

                        <div class="flex-wr-s-c m-b-11">
                            <div class="p-r-20">
                                <i class="fs-14 cl-7 fa fa-calendar m-r-2 cardDetailText"></i>

                                <span class="t1-s-2 cl-7 cardDetailText">
                                    {{ newsStore?.newsByKey?.created_at.toDate() | date : 'medium' }}
                                </span>
                            </div>

                            <div class="p-l-20 bo-l-1 bcl-12">
                                <i class="fs-14 cl-7 fa fa-user m-r-2 cardDetailText"></i>

                                <a class="t1-s-2 cl-11 hov-link3 cardDetailText">
                                    {{ newsStore?.newsByKey?.created_by?.name }}
                                </a>
                            </div>
                        </div>
                        <!-- <p class="t1-s-2 cl-6 m-b-9 cardDetailText">
                            {{ newsStore?.newsByKey?.description }}
                        </p> -->
                        <p class="t1-s-2 cl-1 m-b-9 cardDetailText" [innerHTML]="newsStore?.newsByKey?.descriptions">
                            <!-- {{ newsStore?.newsByKey?.description }} -->
                        </p>
                        <ng-container *ngFor="let item of newsStore?.newsByKey?.images">
                        <img class="m-b-45 max-s-full" [defaultImage]="'./assets/images/circle-loading.gif'" [lazyLoad]="item?.downloadURL" alt="IMG" />

                        </ng-container>

                        

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
