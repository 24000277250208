import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';

import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";

import { IService } from 'src/app/models/service.model';
import { UserMappingService } from 'src/app/services/mapping/user-mapping.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { ServiceStore } from 'src/app/stores/service.store';
import { generateKeywords } from 'src/app/services/generators/generate-keywords.service';
import { IImage } from 'src/app/models/image.model';
import { modulesEditor } from 'src/app/data/editor';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss']
})
export class AddServiceComponent implements OnInit {

  form: any;
  arrImgs: Array<any> = [];
  serviceImges: Array<File> = [];
  filePath: Array<string> = [];
  task: AngularFireUploadTask;
  percentage: any = 0;

  uploadedImages : Array<IImage>=[];
  selectedImages : Array<File> = [];

  listUploadedImages: Array<IImage> = [];
  modules = modulesEditor;

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,

    private authStore: AuthStore,
    public serviceStore: ServiceStore,
    private userMappingService: UserMappingService,

    public dialogRef: MatDialogRef<AddServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.formTitle == 'Update Service') {
      this.form = this.formBuilder.group({
        title: new FormControl(data.serviceData.title, Validators.required),
        description: new FormControl(data.serviceData.description),
        descriptions: new FormControl(data.serviceData.descriptions),
        isHome: new FormControl(data.serviceData.isHomePage),
      })

      // for (let item of data.serviceData.images) {
      //   console.log(item)
      //   this.arrImgs.push(item);
      // }
      this.uploadedImages = data.serviceData.images;
    } else {
      this.form = this.formBuilder.group({
        title: new FormControl(null, Validators.required),
        description: new FormControl(null),
        descriptions: new FormControl(null),
        isHome: new FormControl(false),
      })
    }
  }

  ngOnInit(): void {

  }

  async formSubmit(formData: any) {
    if (this.form.valid) {
      if (this.data.formTitle == 'Add Service') {
        const { title, description, isHome,descriptions } = formData;
        const key = this.afs.createId();

        // const result = await Promise.all(this.serviceImges.map(async (item) => {
        //   await this.uploadFileToFirebase(item, 'service_images').then(uplodedFile => {
        //     this.listUploadedImages.push(uplodedFile);
        //   });
        //   return;
        // }));

        const result = await Promise.all(this.selectedImages.map(async (item) => {
          await this.uploadFileToFirebase(item, 'service_images').then(uplodedFile => {
            this.uploadedImages.push(uplodedFile);
          });
          return;
        }));

        const data: IService = {
          key,
          title: title,
          description: description,
          descriptions: descriptions,

          images: this.uploadedImages,

          created_at: new Date(),
          created_by: this.userMappingService.mapUser(this.authStore.User),
          updated_by: null,
          updated_at: null,
          page_key: this.pageKey(),
          keyword: generateKeywords([title]),

          status: 'Active',
          isDelete: false,
          isHomePage: isHome,
        }

        this.serviceStore.addService(data);
        this.dialogRef.close();
        this._snackBar.open("Add Service Successfully.", "Close",{ duration: 5000 });
      } else {
        const { title, description, isHome,descriptions } = formData;

        // for (let item of this.data.serviceData.images) {
        //   if (!this.arrImgs.find(e => e === item.fileName)) {
        //     try {
        //       const storageRef = firebase.storage().ref();
        //       storageRef.child(item.fileName).delete();
        //       this.data.serviceData.images = this.data.serviceData.images.filter((i: { fileName: any; }) => i.fileName !== item.fileName)
        //     } catch (e) {
        //       console.log(e);
        //     }
        //   }
        // }

        // const result = await Promise.all(this.serviceImges.map(async (item) => {
        //   await this.uploadFileToFirebase(item, 'service_images').then(uplodedFile => {
        //     this.data.serviceData.images.push(uplodedFile);
        //   });
        //   return;
        // }));

        for(let item of this.uploadedImages){
          if(!this.uploadedImages.find((jtem: IImage) => jtem.key === item.key)){
            try {
              const storageRef = firebase.storage().ref();
              storageRef.child(item.fileName).delete();
            } catch(e) {
              console.log(e);
            }
          }
        }

        const result = await Promise.all(this.selectedImages.map(async (item) => {
          await this.uploadFileToFirebase(item, 'service_images').then(uplodedFile => {
            this.uploadedImages.push(uplodedFile);
          });
          return;
        }));

        const data: IService = {
          ...this.data.serviceData,
          title: title,
          description: description,
          descriptions: descriptions,
          images: this.uploadedImages,
          updated_by: this.userMappingService.mapUser(this.authStore.User),
          updated_at: new Date(),
          isHomePage: isHome,
        }

        this.serviceStore.updateService(data);
        this.dialogRef.close();
        this._snackBar.open("Update Service Successfully.", "Close",{ duration: 5000 });
      }
    }
  }

  pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }

  // onSelectedImages(event: any) {
  //   const arrFile = event.target.files as FileList;

  //   for (let i = 0; i < arrFile.length; i++) {
  //     const file = arrFile.item(i);
  //     console.log(arrFile[i])
  //     if (file) {
  //       this.serviceImges.push(file)
  //       this.arrImgs.push(file)
  //     }
  //   }
  // }

  onSelectedImages(event: any) {
    const arrFile = event.target.files as FileList;

    for (let i = 0; i < arrFile.length; i++) {
      const file = arrFile.item(i);
      if (file){
        this.selectedImages.push(file);
      }
    }
  }

  async uploadFileToFirebase(item: File, basePath: string) {
    const filePath = `${basePath}/${Date.now()}_${item.name}`;
    const storageRef = this.storage.ref(filePath);

    this.task = this.storage.upload(filePath, item);
    this.task.percentageChanges().subscribe((percentage) => {
      percentage = percentage || 0;
      this.percentage += percentage / this.serviceImges.length;
    });
    return new Promise<IImage>((resolve, reject) => {
      this.task.then((f) => {
        f.ref.getDownloadURL().then((downloadURL) => {
          resolve({
            key: uuidv4(),
            downloadURL: downloadURL,
            fileName: filePath,
            fileSize: item.size,
            fileType: item.type,
            name: item.name,
            type: 'image',
          });
        }).catch(e => reject(e))
      });
    })
  }

  // deleteImage(name: string) {
  //   this.serviceImges = this.serviceImges.filter(e => e.name !== name)
  //   this.arrImgs = this.arrImgs.filter(e => e.name !== name)
  // }

  deleteSelectedImage(selectedImage: File) {
    this.selectedImages = this.selectedImages.filter(e => e.name !== selectedImage.name && e.size !== selectedImage.size)
  }

  deleteUploadedImage(selectedImage: IImage) {
    this.uploadedImages = this.uploadedImages.filter(e => e.key !== selectedImage.key);
  }
}
