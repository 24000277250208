import { Component} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './services/auth-service/auth.service';
import { AuthStore } from './stores/auth.store';
import { SettingStore } from './stores/setting.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'camwin-e-commerce';

  constructor(
    private router: Router,
    private authStore : AuthStore,
    private authService : AuthService,
    private settingStore : SettingStore,
  ) {

    this.settingStore.getFontSize().then(()=>{
      document.documentElement.style.setProperty(`--fontSize_topbar`, this.settingStore?.fontSize?.value?.top_bar+'px');
      document.documentElement.style.setProperty(`--fontSize_menu`, this.settingStore?.fontSize?.value?.menu+'px');
      document.documentElement.style.setProperty(`--fontSize_footerTitle`, this.settingStore?.fontSize?.value?.footer_title+'px');
      document.documentElement.style.setProperty(`--fontSize_footerText`, this.settingStore?.fontSize?.value?.footer_text+'px');
      document.documentElement.style.setProperty(`--fontSize_cardText`, this.settingStore?.fontSize?.value?.card_text+'px');
      document.documentElement.style.setProperty(`--fontSize_cardTitle`, this.settingStore?.fontSize?.value?.card_title+'px');
      document.documentElement.style.setProperty(`--fontSize_cardDetailTitle`, this.settingStore?.fontSize?.value?.card_detail_title+'px');
      document.documentElement.style.setProperty(`--fontSize_cardDetailText`, this.settingStore?.fontSize?.value?.card_detail_text+'px');
      document.documentElement.style.setProperty(`--fontSize_logoName`, this.settingStore?.fontSize?.value?.company_name+'px');
      document.documentElement.style.setProperty(`--fontSize_titleContent`, this.settingStore?.fontSize?.value?.title_content+'px');

    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });

    this.settingStore.getSetting('color_theme').then(()=>{
      if(!this.settingStore?.loading){
        document.documentElement.style.setProperty(`--primaryColor`, this.settingStore?.setting?.value);
      }
    });

    this.settingStore.getFont().then(()=>{
      if(!this.settingStore?.loading){
        document.documentElement.style.setProperty(`--appFont`, this.settingStore?.font?.value);
      }
    })

  }

  public doUnload(): void {
    if(!this.authStore.keepMeSignIn){
      // Clear localStorage
      this.authService.signout_admin();
    }
  }
}
