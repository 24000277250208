import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tabs } from 'src/app/data/tabs';
import { ITestimonial } from 'src/app/models/testimonial.model';
import { DeleteConfirmationComponent } from 'src/app/shared/backend/delete-confirmation/delete-confirmation.component';
import { SettingStore } from 'src/app/stores/setting.store';
import { TestimonialStore } from 'src/app/stores/testimonial.store';
import { AddTestimonialComponent } from './add-testimonial/add-testimonial.component';
import { TestimonialDetailComponent } from './testimonial-detail/testimonial-detail.component';

@Component({
  selector: 'app-testimonial-dashbord',
  templateUrl: './testimonial-dashbord.component.html',
  styleUrls: ['./testimonial-dashbord.component.scss']
})
export class TestimonialDashbordComponent implements OnInit {

  loading: boolean = false;
  TABS: Array<any> = tabs.aboutus;

  constructor(
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public testimonialStore : TestimonialStore,
    public settingStore : SettingStore,
  ) { }

  ngOnInit(): void {
    this.testimonialStore.getTestimonial();
    this.settingStore.getSetting('testimonial');
  }

  async create() {
    const dialogRef = this.dialog.open(AddTestimonialComponent, {
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Add Testimonial',
        formData: null
      }
    });

    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });
  }

  edit(data: ITestimonial) {
    const dialogRef = this.dialog.open(AddTestimonialComponent, {
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Edit Testimonial',
        formData: data
      }
    });

    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }

  delete(data: ITestimonial) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.testimonialStore.deleteTestimonial(data, (isSucess) => {
          if (isSucess) {
            this._snackBar.open('Testimonial is deleted successfully.', 'Done',{ duration: 5000 })
          }
        })
      }
    });
  }

  rowClick(data:ITestimonial){
    const dialogRef=this.dialog.open(TestimonialDetailComponent,{
      width:'760px',
      height:'96vh',
      data:{...data},
    })
    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }

  disable(data:ITestimonial){
    var v:ITestimonial;
    if(data.status==='Active'){
      v={
        ...data,
        status:'Disable'
      }
    }else{
      v={
        ...data,
        status:'Active'
      }
    }
    this.testimonialStore.updateTestimonialWithoutCallback(v);
  }

  toggleDisplay(data:any){
    this.settingStore.updateSetting('testimonial',data?.checked);
  }

}
