import { Component, OnInit } from '@angular/core';
import { AuthStore } from 'src/app/stores/auth.store';

@Component({
  selector: 'app-backend-layout',
  templateUrl: './backend-layout.component.html',
  styleUrls: ['./backend-layout.component.scss']
})
export class BackendLayoutComponent implements OnInit {

  constructor(
    private authStore: AuthStore
  ) { }

  ngOnInit(): void {
    this.authStore.getCurrentLoggedInUser();

  }

}
