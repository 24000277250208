import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceStore } from 'src/app/stores/service.store';

@Component({
  selector: 'app-service-detail-page',
  templateUrl: './service-detail-page.component.html',
  styleUrls: ['./service-detail-page.component.scss']
})
export class ServiceDetailPageComponent implements OnInit {

  constructor(
    public serviceStore : ServiceStore,
    private route : ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params=>{
      var id =params.get('id');
      this.serviceStore.getServiceByKey(id);
    })
  }

}
