<section class="bg-0 p-t-92 p-b-40">
    <div class="container">
        <!-- Title section -->
        <div class="flex-col-c-c p-b-50">
            <h3 class="t1-b-1 cl-3 txt-center m-b-11">
                Business Partners
            </h3>

            <div class="size-a-2 bg-3"></div>
        </div>

        <!--  -->
        <div class="row justify-content-center">
            <div class="col-sm-4 col-lg-2 flex-c-c p-b-60">
                <a href="#">
                    <img class="hov-img2 trans-02 max-s-full" src="./assets/customization-assets/images/icons/partner-01.png" alt="IMG">
                </a>
            </div>

            <div class="col-sm-4 col-lg-2 flex-c-c p-b-60">
                <a href="#">
                    <img class="hov-img2 trans-02 max-s-full" src="./assets/customization-assets/images/icons/partner-02.png" alt="IMG">
                </a>
            </div>

            <div class="col-sm-4 col-lg-2 flex-c-c p-b-60">
                <a href="#">
                    <img class="hov-img2 trans-02 max-s-full" src="./assets/customization-assets/images/icons/partner-03.png" alt="IMG">
                </a>
            </div>

            <div class="col-sm-4 col-lg-2 flex-c-c p-b-60">
                <a href="#">
                    <img class="hov-img2 trans-02 max-s-full" src="./assets/customization-assets/images/icons/partner-04.png" alt="IMG">
                </a>
            </div>

            <div class="col-sm-4 col-lg-2 flex-c-c p-b-60">
                <a href="#">
                    <img class="hov-img2 trans-02 max-s-full" src="./assets/customization-assets/images/icons/partner-05.png" alt="IMG">
                </a>
            </div>
        </div>
    </div>
</section>