import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { duration } from 'moment';
import { generateKeywords } from 'src/app/services/generators/generate-keywords.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { HeaderMenuStore } from 'src/app/stores/header-menu.store';

@Component({
  selector: 'app-add-header-menu-dashboard',
  templateUrl: './add-header-menu-dashboard.component.html',
  styleUrls: ['./add-header-menu-dashboard.component.scss']
})
export class AddHeaderMenuDashboardComponent implements OnInit {

  form : FormGroup
  percentage: any = 0;
  userMappingService: any;

  constructor(
    private fb : FormBuilder,
    private snackBar : MatSnackBar,
    private headerStore : HeaderMenuStore,
    private authStore : AuthStore,
    private afs: AngularFirestore,
    public dialogRef : MatDialogRef<AddHeaderMenuDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data :any
  ) { 

    if(this.data){
      this.form = this.fb.group({
        header_menu : new FormControl(this.data?.header_menu,Validators.required),
        header_url : new FormControl(this.data?.header_url,Validators.required),
        isHomepage: new FormControl(this.data?.isHomepage),

      })
    }
    else{
      this.form = this.fb.group({
        header_menu : new FormControl(null,Validators.required),
        header_url: new FormControl(null,Validators.required),
        isHomepage : new FormControl(false)
      })
    }
  }

  ngOnInit(): void {
  }

  async formSubmit(form: any){
    console.log(form,'Form Header')
    if(this.form.valid){
      const {header_menu, header_url,isHomepage} = form
      if(this.data){
        const data :any ={
          ...this.data,
          header_menu: header_menu,
          header_url : header_url,
          isHomepage: isHomepage,
        }
        this.headerStore.updateHeaderMenu(data);
        this.dialogRef.close();
        this.snackBar.open("Header Menu is update successfully.", "Done",{duration:2000});
      }
      else{
        const key = this.afs.createId()
        const data :any ={
          key: key,
          created_at: new Date(),
          page_key: this.pageKey(),
          keyword: generateKeywords([header_menu]),
  
          header_menu: header_menu,
          header_url: header_url,
          isHomepage: isHomepage,
          isDelete: false
        }
        this.headerStore.addHeaderMenu(data)
        this.dialogRef.close()
        this.snackBar.open("Header Menu is created successfully.", "Done",{duration:2000});

      }
    }
   
  }

  pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }
}
