import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NewsStore } from 'src/app/stores/news.store';
import { NewsCategoryStore } from 'src/app/stores/news_category.store';

@Component({
  selector: 'app-news-content-block',
  templateUrl: './news-content-block.component.html',
  styleUrls: ['./news-content-block.component.scss']
})
export class NewsContentBlockComponent implements OnInit {

  newsKey: string;

  constructor(
    public newsStore: NewsStore,
    public newsCategoryStore: NewsCategoryStore,

    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      const { id } = params;
      this.newsStore.getNewsByKey(id);
    })
  }

  ngOnInit(): void {
  }

  categoryClicked(categoryData) {
    if (categoryData)
      this.router.navigate(['/news'], { queryParams: { categoryKey: categoryData?.key } });
    else
      this.router.navigate(['/news']);
  }

}
