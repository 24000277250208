import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, of, Subscription } from "rxjs";
import { distinct, shareReplay, take } from 'rxjs/operators';

import { UserMappingService } from "../services/mapping/user-mapping.service";
import { AuthStore } from "./auth.store";
import { INews } from "../models/news.model";

@Injectable({providedIn:'root'})
export class NewsStore {

  @observable public isLoading: boolean = false;
  @observable public news: Array<INews>=[];
  @observable public newsNoDisable: Array<INews>=[];
  @observable public newsByCategory: Array<INews>=[];
  @observable public newsTop3: Array<INews>=[];
  @observable public newsByKey: INews;
  @observable public groupBy: any;

  constructor(
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private userMapping: UserMappingService,
    private authStore: AuthStore,
  ) { }

  @action
  getTop3News(){
    try{
      this,this.isLoading=true;
      this.afs.collection('news',ref=>ref.where('isDelete','==',false).where('status','==','Active').orderBy("created_at","desc").limit(3)).valueChanges().subscribe((data:any)=>{
        this.newsTop3=data;
        this.isLoading=false;
      })
    }catch(e){
      console.log(e);
    }
  }

  @action
  getNews(){
    try{
      this.isLoading=true;
      this.afs.collection('news',ref=>ref.where('isDelete','==',false).orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.news=data;
        this.isLoading=false;
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  getNewsNoDisable(){
    try{
      this.isLoading=true;
      this.afs.collection('news',ref=>ref.where('isDelete','==',false).where("status",'==','Active').orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.newsByCategory=data;
        this.groupBy = this.groupByType(data);
        this.isLoading=false;
      });
    }catch(e){
      console.log(e);
    }
  }
 @action
 groupByType(array){
  return array.reduce((r, a) => {
        r[a.category.name] = r[a.category.name] || [];
        r[a.category.name].push(a);
        return r;
    }, Object.create(null));
}
  @action
  getNewsByKey(key:string){
    try{
      this.isLoading=true;
      this.afs.collection('news').doc(key).valueChanges().subscribe((data:any)=>{
        this.newsByKey=data;
        this.isLoading=false;
      })
    } catch(e){
      console.log(e);
    }
  }

  @action
  getNewsByCategory(categoryKey:any){
    try{
      this.isLoading=true;
      this.afs.collection('news',ref=>ref.where('isDelete','==',false).where('category.key','==',categoryKey).where('status','==','Active').orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.newsByCategory = data;
        this.news = data;
        this.isLoading=false;
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  async addNews(news:INews, callback: (isSuccess: boolean) => void){
    try{
      this.isLoading = true;

      await this.afs.collection('news').doc(news.key).set(news).then(()=>{
        this.isLoading = false;
        callback(true);
      }).catch((e)=>{
        console.log(e);
        this.isLoading = false;
        callback(false);
      });
    }catch(e){
      console.log(e)
    }
  }

  @action
  async updateNews(news: INews, callback: (isSuccess: boolean) => void){
    try{
      this.isLoading = true;

      await this.afs.collection('news').doc(news.key).update({
        ...news,
      }).then(() => {
        this.isLoading = false;
        callback(true);
      }).catch((e) => {
        this.isLoading = false;
        callback(false);
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  async updateNewsWithoutCallback(news:INews){
    try{
      this.afs.collection('news').doc(news.key).update(news);
    }catch(e){
      console.log(e);
    }
  }

  @action
  async deleteNews(news: INews, callback: (isSuccess: boolean) => void){
    try {
      this.isLoading = true;

      await this.afs.collection('news').doc(news.key).update({
        ...news,
        isDelete: true,
        updated_at: new Date(),
        updated_by: this.userMapping.mapUser(this.authStore.User)
      });

      this.isLoading = false;
      callback(true);
    }catch(e){
      console.log(e);
      this.isLoading = false;
      callback(false);
    }
  }

  // ------------------------------------------------------------------------------------------------------------------------------------


  lastVisible: any = null;
  infinite: Observable<any[]>;

  @observable public orderBy = null;
  @observable public searchText = null;
  @observable public filter = null;

  @observable public loading: boolean = false;
  @observable public fetching: boolean = false;
  @observable public process: boolean = false;

  @observable data: any = [];
  @observable fetchListingRef: Subscription;

  infiniteData(data: any) {
    this.infinite = of(data).pipe(distinct((p:any) => p.key));
  }

  async fetchData(search: any, filter: any, orderBy: any, callback: (arg0: any) => void) {
    this.orderBy = orderBy;
    this.searchText = search;
    this.filter = filter;
    this.loading = true;
    this.fetching = false;
    this.lastVisible = null;

    const ref = this.lazyDataRef(this.lastVisible, search, filter, orderBy);

    this.fetchListingRef = ref.snapshotChanges().subscribe((response: any) => {
      this.data = [];

      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        callback(this.data)
        return false;
      }

      this.lastVisible = response[response.length - 1].payload.doc;

      for (let item of response) {
        this.data.push(item.payload.doc.data() as any);
      }

      this.data = this.data.map((f: any, index: number) => ({
        ...f,
        rowIndex: index + 1
      }))

      this.infiniteData(this.data);
      this.loading = false
      this.fetching = false;

      callback(this.data);
      return true;
    }, (error: any) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  @action
  async fetchDataMore(callback: (arg0: any) => void) {
    this.fetching = true;

    this.lazyDataRef(this.lastVisible, this.searchText, this.filter, this.orderBy).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }

      this.lastVisible = response.docs[response.docs.length - 1];

      for (let item of response.docs) {
        this.data.push(item.data() as any);
      }

      this.data = this.data.map((f: any, index: number) => ({
        ...f,
        rowIndex: index + 1
      }))

      this.fetching = false;
      this.infiniteData(this.data);

      callback(this.data);
      return;
    }, (error: any) => {
      this.fetching = false;
    });
  }

  lazyDataRef(lastVisible: any, search: any, filter: any, orderBy: any) {
    return this.afs.collection<any>("news", ref => {
      let keyword = filter ? filter.toUpperCase() : "~N/A~";
      let condition = ref.where("isDelete","==",false).orderBy('page_key')

      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }

      return condition;
    })

  }
  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }
}
