<app-animation-slideshow *ngIf="settingStore?.slide_show"></app-animation-slideshow>
<app-home-first-block *ngIf="settingStore?.our_service"></app-home-first-block>
<app-home-second-block *ngIf="settingStore?.why_chose_us"></app-home-second-block>
<app-home-third-block *ngIf="settingStore?.request_a_call_back"></app-home-third-block>
<app-home-fourth-block *ngIf="settingStore?.feature_projects"></app-home-fourth-block>
<app-home-fifth-block *ngIf="settingStore?.static"></app-home-fifth-block>
<app-home-sixth-block *ngIf="settingStore?.lastest_from_news"></app-home-sixth-block>
<app-home-nineth-block *ngIf="settingStore?.testimonial"></app-home-nineth-block>
<app-home-seventh-block *ngIf="settingStore?.business_partner"></app-home-seventh-block>
<!-- <app-home-tenth-block *ngIf="settingStore?.sing_up"></app-home-tenth-block> -->
