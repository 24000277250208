import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthStore } from 'src/app/stores/auth.store';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {
  form: FormGroup;
  processClose: boolean;
  hide = true;
  new = true;
  confirm = true;
  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public auth: AuthStore,
    private snackBar: MatSnackBar,
    private Auth: AngularFireAuth,
    private router: Router,
    private afs: AngularFirestore,

  ) { }
  ngOnInit() {    
    this.form = this.fb.group({
      oldPassword: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      confirm: [null, Validators.compose([Validators.required, Validators.minLength(8)])]
    })
  }

  onSave(f: any) {
    if (this.form.valid) {
      const { password, confirm,oldPassword } = f;
      if (password !== confirm) {
        alert('New Password and Confirm Password do not match');
        return;
      }
      this.form.disable();
      this.changePassword(oldPassword,password, (success, error) => {
        if (success) {
          this.afs.collection('users').doc(this.auth.User.user.uid).update({
            isFirstLog : true
          })
          this.snackBar.open("Password has been changed successfully.", "Done", { duration: 3000 })
          this.router.navigate(['/admin']);
          
        }
        else{
          this.router.navigate(['/admin/login']);
        }
        this.form.enable()
      })
      

    }
  }

  changePassword(oldPassword: any, newPassword: any, callback) {
    const user = firebase.auth().currentUser;
    this.Auth.signInWithEmailAndPassword(user.email, oldPassword)
      .then((account) => {
        account.user.updatePassword(newPassword)
          .then(() => {
            callback(true, null);
          })
          .catch((error) => {
            callback(false, error);
          });
      })
      .catch((error) => {
        callback(false, error);
      });
  }
}
