import { Component, OnInit } from '@angular/core';
import { ProjectStore } from 'src/app/stores/project.store';

@Component({
  selector: 'app-project-content-block',
  templateUrl: './project-content-block.component.html',
  styleUrls: ['./project-content-block.component.scss']
})
export class ProjectContentBlockComponent implements OnInit {

  constructor(
    public projectStore : ProjectStore,
  ) { }

  ngOnInit(): void {
    console.log(this.projectStore?.project)
    console.log(this.projectStore)
  }

}
