<form [formGroup]="form" class="dialog-content-wrapper" (ngSubmit)="formSubmit(form.value)">
    
  <div class="dialog-header">
    <h2 mat-dialog-title>{{this.data.formTitle}}</h2>
    <div class="space1"></div>
    <a mat-button mat-icon-button (click)="dialogRef.close()">
        <mat-icon class="mat-18">clear</mat-icon>
    </a>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="inputsDialogContainer">
      <p>
        <mat-form-field appearance="outline">
          <mat-label>Category Name</mat-label>
          <input matInput placeholder="enter category name" formControlName="name">
          <mat-error *ngIf="form.controls['name']?.touched && form.controls['name']?.errors?.required">Name is required.</mat-error>
        </mat-form-field>
      </p>

      <div class="textArea">
        <mat-form-field appearance="outline" >
          <mat-label>Category Description</mat-label>
          <textarea matInput rows="10" placeholder="enter category description" formControlName="description"></textarea>
          <mat-error *ngIf="form.controls['description']?.touched && form.controls['description']?.errors?.required">Description is required.</mat-error>
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btnCancel" mat-dialog-close>
      <span class="material-icons">close</span>
      <span style="margin-left: 10px;">Close</span>
    </button>

    <button type="submit" class="btnWithSpinner">
      <span class="material-icons" *ngIf="!this.newsCategoryStore.isLoading && this.data?.formData?.key">save</span>
      <span class="material-icons" *ngIf="!this.newsCategoryStore.isLoading && !this.data?.formData?.key">add</span>
      <mat-spinner class="mat-spinner-color" [diameter]="20" *ngIf="this.newsCategoryStore.isLoading"></mat-spinner>
      <span style="margin-left: 10px;" *ngIf="this.data?.formData?.key">Save</span>
      <span style="margin-left: 10px;" *ngIf="!this.data?.formData?.key">Add News Category</span>
    </button>
  </mat-dialog-actions>

</form>