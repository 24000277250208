import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from "@angular/material/snack-bar";
import moment from "moment";

import { UserMappingService } from "../services/mapping/user-mapping.service";
import { AuthStore } from "./auth.store";
import { INewsCategory } from "../models/news-category-model";
import { generateKeywords, GenerateKeywordsService } from "../services/generators/generate-keywords.service";

@Injectable({providedIn:'root'})
export class NewsCategoryStore {

  @observable public isLoading: boolean = false;
  @observable public newsCategories: Array<INewsCategory>=[];
  @observable public newsCategoriess: Array<INewsCategory>=[];
  @observable public newsCategoriesNoDisable: Array<INewsCategory>=[];

  constructor(
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private userMapping: UserMappingService,
    private authStore: AuthStore,
    private userMappingService: UserMappingService
  ) { }

  @action
  getNewsCategories(){
    try{
      this.isLoading=true;
      this.afs.collection('news-categories',ref=>ref.where('isDelete','==',false).where('status','==','Active').orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.newsCategoriess = data;
        this.isLoading=false;
      });
    }catch(e){
      console.log(e);
    }
  }
  action
  getsNewsCategories(){
    try{
      this.isLoading=true;
      this.afs.collection('news-categories',ref=>ref.where('isDelete','==',false).orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.newsCategories = data;
        this.isLoading=false;
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  getNewsCategoriesNoDisable(){
    try{
      this.isLoading=true;
      this.afs.collection('news-categories',ref=>ref.where('isDelete','==',false).where('status','==','Active').orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.newsCategoriesNoDisable = data;
        this.isLoading=false;
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  async addNewsCategory(newsCategory: INewsCategory, callback: (isSuccess: boolean) => void) {
    try {
      this.isLoading = true;
      const data = {
        key: newsCategory.key,
        name: newsCategory.name,
        description: newsCategory.description,
        created_at: new Date(),
        created_by: this.userMappingService.mapUser(this.authStore.User),
        updated_at: null,
        updated_by: null,
        page_key: this.pageKey(),
        keywords: generateKeywords([newsCategory.name]),
        status:  "Active",
        isDelete: false
      };

      await this.afs.collection('news-categories').doc(data.key).set(data).then(()=>{
        this.isLoading = false;
        callback(true);
      }).catch((e)=>{
        console.log(e);
        callback(false);
      });
    } catch(e) {
      console.log(e)
    }
  }

  @action
  async updateNewsCategory(newsCategory: INewsCategory, callback: (isSuccess: boolean) => void){
    try {
      this.isLoading = true;

      const data = {
        name: newsCategory.name,
        description: newsCategory.description,
        updated_at: new Date(),
        updated_by: this.userMappingService.mapUser(this.authStore.User),
        page_key: this.pageKey(),
        keywords: generateKeywords([newsCategory.name]),
        status: { key: 1, text: "Active" },
        isDelete: false
      };

      await this.afs.collection('news-categories').doc(newsCategory.key).update(data).then(()=>{
        this.isLoading = false;
        callback(true);
      }).catch((e)=>{
        console.log(e);
        callback(false);
      });
    } catch(e) {
      console.log(e)
    }
  }

  @action
  updateNewsCategoryWithOutCallback(newsCategory:INewsCategory){
    this.afs.collection('news-categories').doc(newsCategory.key).update(newsCategory)
  }

  @action
  async deleteNewsCategory(newsCategory: INewsCategory, callback: (isSuccess: boolean) => void){
    try {
      this.isLoading = true;
      await this.afs.collection('news-categories').doc(newsCategory.key).update({
        ...newsCategory,
        isDelete: true,
        updated_at: new Date(),
        updated_by: this.userMapping.mapUser(this.authStore.User)
      });
      this.isLoading = false;
      callback(true);
    }catch(e){
      console.log(e);
      this.isLoading = false;
      callback(false);
    }
  }
  pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }

}
