import { Component, OnInit } from '@angular/core';
import { ManagementTeamStore } from 'src/app/stores/management_team.store';

@Component({
  selector: 'app-about-fourth-block',
  templateUrl: './about-fourth-block.component.html',
  styleUrls: ['./about-fourth-block.component.scss']
})
export class AboutFourthBlockComponent implements OnInit {

  constructor(
    public managementTeamStore : ManagementTeamStore,
  ) { }

  ngOnInit(): void {
  }

}
