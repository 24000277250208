import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-admin-command-bar',
  templateUrl: './admin-command-bar.component.html',
  styleUrls: ['./admin-command-bar.component.scss']
})
export class AdminCommandBarComponent implements OnInit {

  @Input() name:any;
  @Input() buttonText:any;
  @Input() searchDialog:any;
  @Input() process: boolean = false;
  @Input() tabData: any[] = [];
  
  @Output() onPress = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

}
