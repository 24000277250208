<section class="bg-img1 kit-overlay1" >
    <div class="container size-h-3 p-tb-30 flex-col-c-c">
        <h2 class="t1-b-1 text-uppercase cl-0 txt-center m-b-25">
            {{ text }}
        </h2>

        <div class="flex-wr-c-c">
            <a href="index.html" class="breadcrumb-item">
                Home
            </a>

            <span class="breadcrumb-item">
                {{ text }}
            </span>
        </div>
    </div>
</section>
