import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NewsStore } from 'src/app/stores/news.store';
import { NewsCategoryStore } from 'src/app/stores/news_category.store';

@Component({
  selector: 'app-news-grid-page',
  templateUrl: './news-grid-page.component.html',
  styleUrls: ['./news-grid-page.component.scss']
})
export class NewsGridPageComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private newsCategoryStore: NewsCategoryStore,
    private newsStore: NewsStore
  ) { }

  ngOnInit(): void {
    this.newsCategoryStore.getNewsCategoriesNoDisable();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const { categoryKey } = params;
      if(categoryKey) this.newsStore.getNewsByCategory(categoryKey);
      else this.newsStore.getNewsNoDisable();
    })
  }

}
