<div style="width: 100%;" class="pageContainerForSpinner">
    <app-admin-command-bar [tabData]="TABS" [name]="'About us page'" [buttonText]="'Add Management Team'" (onPress)="create()">
    </app-admin-command-bar>

    <div style="padding: 20px !important;" *ngIf="managementTeamStore.isLoading">
      <ng-container>
        <p-skeleton styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton width="10rem" styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton width="5rem" styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton height="2rem" styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton width="10rem" height="4rem"></p-skeleton>
      </ng-container>
    </div>

    <div class="tableContainer" *ngIf="!managementTeamStore.isLoading">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col" width="1%">No</th>
              <th scope="col" width="5%">Picture</th>
              <th scope="col" width="25%">Name</th>
              <th scope="col" width="67%">Position</th>
              <!-- <th scope="col" width="35%">Speech</th> -->
              <th scope="col" width="1%">Status</th>
              <!-- <th scope="col">Created By</th>
              <th scope="col">Created Date</th>
              <th scope="col">Updated By</th>
              <th scope="col">Updated Date</th> -->
              <th scope="col" width="1%">Action</th>
            </tr>
          </thead>
          <tbody #container>
            <ng-container>
              <tr *ngFor="let item of managementTeamStore.managementTeam ;let i=index;">
                <th (click)=rowClick(item) scope="row">{{i + 1}}</th>
                <td (click)=rowClick(item) >
                  <div>
                    <img class="image--cover" [defaultImage]="'./assets/images/default-image.png'" [lazyLoad]="item?.image?.downloadURL" alt="{{ item?.image?.name }}">
                  </div>
                </td>
                <td (click)=rowClick(item)>{{item?.name}}</td>
                <td (click)=rowClick(item)>{{item?.position}}</td>
                <!-- <td>{{item?.speech}}</td> -->
                <!-- <td>{{item?.created_by?.name}}</td>
                <td>{{item?.created_at?.toDate() | date : 'fullDate'}}</td>
                <td>{{item?.updated_by?.name}}</td>
                <td>{{item?.updated_at?.toDate() | date : 'fullDate'}}</td> -->
                <td (click)=rowClick(item) [ngStyle]="{'color':(item?.status==='Active')?'blue':'red'}">{{item?.status}}</td>
                <td>
                  <div class="actionButtonsContainer container">
                    <div class="row">
                      <div style="margin-right: 10px;">
                        <mat-icon aria-label="disable" (click)="disable(item)">hide_source</mat-icon>
                      </div>
                      <div  style="margin-right: 10px;"  >
                        <!-- <i class="fas fa-pencil-alt" (click)="edit(item)"></i> -->
                        <mat-icon aria-label="edit" (click)=edit(item)>mode_edit_outline</mat-icon>

                      </div>
                      <div>
                        <!-- <i class="fas fa-trash-alt" (click)="delete(item)"></i> -->
                        <mat-icon aria-label="delete" (click)=delete(item)>delete_outline</mat-icon>
                      </div>
                    </div>
                    <!-- <i class="fas fa-pencil-alt" style="margin-right: 20px;" (click)="edit(item)"></i>
                    <i class="fas fa-trash-alt" (click)="delete(item)"></i> -->
                  </div>
                </td>
              </tr>

              <tr *ngIf="this.managementTeamStore.managementTeam?.length === 0">
                <td colspan="11"><div style="text-align: center;">No Management Team.</div></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
    </div>
</div>
