import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ConfigStore } from 'src/app/stores/config.store';
import { HeaderMenuStore } from 'src/app/stores/header-menu.store';
import { InformationStore } from 'src/app/stores/information.store';
import { SettingStore } from 'src/app/stores/setting.store';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('stickyMenu') menuElement: ElementRef;

  display:boolean = false;
  sticky: boolean = false;
  elementPosition: any;

  constructor(
    public informationStore : InformationStore,
    public settingStore : SettingStore,
    public headerStore : HeaderMenuStore,
    public configStore : ConfigStore
  ) { }

  ngOnInit(): void {
    this.informationStore.getInformation();
    this.settingStore.getCompanyLogo();
    this.settingStore.getFontSize();
    this.headerStore.fetchHeaderTitle();
    this.configStore.getConfigMenus();
  }

  ngAfterViewInit(){
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(){
    const windowScroll = window.pageYOffset;
    if(windowScroll >= this.elementPosition){
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  userClick(){
    this.display=!this.display;
  }

}
