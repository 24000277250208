import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tabs } from 'src/app/data/tabs';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { DeleteConfirmationComponent } from '../../../shared/backend/delete-confirmation/delete-confirmation.component';
import { UserStore } from 'src/app/stores/user.store'
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResetUserDialogComponent } from './reset-user-dialog/reset-user-dialog.component';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  TABS: Array<any> = tabs.user;

  constructor(
    private _snackBar:MatSnackBar,
    public userStore:UserStore,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.userStore.fetchData(null, null);
  }

  create() {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '760px',
      height: '96vh',
    });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });
  }
  editUser(userData:any){
    const dialogRef=this.dialog.open(EditUserDialogComponent,{
      width: '760px',
      height:'96vh',
      data: {...userData},
    })
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }
  deleteUser(userData:any){
    const dialogRef=this.dialog.open(DeleteConfirmationComponent,{
    })
    dialogRef.afterClosed().subscribe(res=>{
      if(res)
        this.userStore.deleteUser(userData.key)
    })
  }
  resetPassord(userData:any){
    const dialogRef=this.dialog.open(ResetUserDialogComponent,{
    })
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        this.userStore.resetPasswordUser(userData).then(()=>{
          this._snackBar.open("Update user information successfully","close",{ duration: 2000 })
    
          }).catch(err=> {
    
          })
      }
    })
  }

}
