import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IService } from 'src/app/models/service.model';
import { ServiceStore } from 'src/app/stores/service.store';

@Component({
  selector: 'app-service-first-block',
  templateUrl: './service-first-block.component.html',
  styleUrls: ['./service-first-block.component.scss']
})
export class ServiceFirstBlockComponent implements OnInit {

  constructor(
    public serviceStore:ServiceStore,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.serviceStore.getServiceNoDisable();
  }
  serviceClicked(ID:any){
    this.router.navigate(['/services/'+ID])
  }
}
