import { Component, OnInit } from '@angular/core';
import { tabs } from 'src/app/data/tabs';


@Component({
  selector: 'app-about-us-setting-dashboard',
  templateUrl: './about-us-setting-dashboard.component.html',
  styleUrls: ['./about-us-setting-dashboard.component.scss']
})
export class AboutUsSettingDashboardComponent implements OnInit {

  TABS : Array<any> = tabs.pagesettings;
  constructor() { }

  ngOnInit(): void {
  }

}
