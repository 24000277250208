<div style="width: 100%;">
  <app-admin-command-bar [tabData]="TABS" [name]="'News Category'" [buttonText]="'Add News Category'" (onPress)="create()"></app-admin-command-bar>

  <div class="tableContainer">
      <table class="table table-hover">
          <thead>
              <tr>
                <th scope="col" width="10%" >No</th>
                <th scope="col" width="88%">Name</th>
                <!-- <th scope="col" style="width: 300px;">Description</th> -->
                <!-- <th scope="col" >Created By</th>
                <th scope="col" >Created Date</th>
                <th scope="col" >Updated By</th>
                <th scope="col" >Updated Date</th> -->
                <th scope="col" width="1%">Status</th>
                <th scope="col" width="1%">Action</th>
              </tr>
          </thead>
          <tbody>
              <ng-container>
                  <tr *ngFor=" let item of this.newsCategoryStore.newsCategories;let i=index;">
                      <th (click)=rowClick(item) scope="row">{{i+1}}</th>
                      <td (click)=rowClick(item)>{{item?.name}}</td>
                      <!-- <td><p style="font-size: 12px; line-height: 22px;">{{item?.description}}</p></td> -->
                      <!-- <td>{{item?.created_by?.name}}</td>
                      <td>{{item?.created_at?.toDate() | date : 'fullDate'}}</td>
                      <td>{{item?.updated_by?.name}}</td>
                      <td>{{item?.updated_at?.toDate() | date : 'fullDate'}}</td> -->
                      <td (click)=rowClick(item) [ngStyle]="{'color':(item?.status==='Active')?'blue':'red'}">{{item?.status}}</td>
                      <td>
                        <!-- <div class="actionButtonsContainer">
                            <i class="fas fa-pencil-alt" style="margin-right: 20px;" (click)="editCategory(item)"></i>
                            <i class="fas fa-trash-alt" (click)="deleteCategory(item)"></i>
                        </div> -->
                        <div class="actionButtonsContainer container">
                          <div class="row">
                            <div style="margin-right: 10px;">
                              <mat-icon aria-label="disable" (click)="disable(item)">hide_source</mat-icon>
                            </div>
                            <div  style="margin-right: 10px;"  >
                              <i class="fas fa-pencil-alt" (click)="editCategory(item)"></i>
                              <mat-icon aria-label="edit" (click)="editCategory(item)">mode_edit_outline</mat-icon>
                            </div>
                            <div>
                              <!-- <i class="fas fa-trash-alt" (click)="deleteCategory(item)"></i> -->
                              <mat-icon aria-label="delete" (click)=deleteCategory(item)>delete_outline</mat-icon>

                            </div>
                          </div>
                        </div>
                      </td>
                  </tr>

                  <tr *ngIf="this.newsCategoryStore.newsCategories?.length === 0">
                    <td colspan="5" style="text-align: center;">No Categories.</td>
                  </tr>
              </ng-container>
          </tbody>
      </table>
  </div>
</div>
