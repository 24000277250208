import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { action, observable } from "mobx";
import { Observable, of, Subscription } from "rxjs";
import { distinct } from "rxjs/operators";
import { ICallbackInbox } from  "../models/callbackInbox.model";


@Injectable({providedIn:'root'})
export class InboxStore {

  @observable public isLoading: boolean = false;
  @observable public arrayInbox:Array<ICallbackInbox>;
  @observable public unreadMssg : boolean=false;

  @observable fetching: boolean = false;
  @observable loading: boolean = false;
  @observable currentData: any = null;

  data: Array<any>;
  lastVisible: any = null;
  infinite: Observable<any[]>;

  @observable public orderBy = null;
  @observable public searchText = null;
  @observable public filter = null;
  @observable fetchingRef: Subscription;


  constructor(
    private afs: AngularFirestore,
  ) { }


  infiniteData(data: any) {
    this.infinite = of(data).pipe(distinct((p:any) => p.key));
  }

  @action
  async fetchData(search: any, filter: any, orderBy: any) {
    this.orderBy = orderBy;
    this.searchText = search;
    this.filter = filter;
    this.loading = true;
    this.fetching = false;
    this.lastVisible = null;
    this.data = [];

    const ref = this.lazyDataRef(this.lastVisible, search, filter, orderBy);

    this.fetchingRef = ref.snapshotChanges().subscribe((response: any) => {
      this.data = [];

      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        this.infiniteData(this.data);
        return false;
      }

      this.lastVisible = response[response.length - 1].payload.doc;

      for (let item of response) {
        this.data.push(item.payload.doc.data() as any);
      }

      this.data = this.data.map((f: any, index: number) => ({
        ...f,
        rowIndex: index + 1
      }))

      this.infiniteData(this.data);
      this.loading = false
      this.fetching = false;

      return true;
    }, (error: any) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  @action
  async fetchDataMore() {
    this.fetching = true;

    this.lazyDataRef(this.lastVisible, this.searchText, this.filter, this.orderBy).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }
      this.lastVisible = response.docs[response.docs.length - 1];
      for (let item of response.docs) {
        this.data.push(item.data() as any);
      }
      this.data = this.data.map((f: any, index: number) => ({
        ...f,
        rowIndex: index + 1
      }))
      this.fetching = false;
      this.infiniteData(this.data);

      return;
    }, (error: any) => {
      this.fetching = false;
    });
  }

  lazyDataRef(lastVisible: any, search: any, filter: any, orderBy: any) {
    return this.afs.collection<any>("inbox", ref => {
      // let keyword = search ? search.toUpperCase() : "~N/A~";

      let condition = ref.orderBy('status').orderBy('created_at', 'desc').limit(20);

      if (lastVisible) {
          condition = condition.startAfter(lastVisible)
      }
      return condition;
    })
  }

  @action
  getInbox(){
    try{
      this.isLoading=true;
      this.afs.collection('inbox',ref=>ref.where('isDelete','==',false).orderBy('status').orderBy("created_at","desc")).valueChanges().subscribe((data:any)=>{
        this.arrayInbox=data;
        this.isLoading=false;
        this.unreadMssg=this.arrayInbox.some((ele)=>{return ele.status==='new';})
      })
    }catch(e){
      this.isLoading=false;
      console.log(e);
    }
  }

  @action
  async addInbox(inbox:ICallbackInbox, callback: (isSuccess: boolean) => void){
    try{
      this.isLoading=true;
      await this.afs.collection('inbox').doc(inbox.key).set(inbox).then(()=>{
        this.isLoading=false;
        callback(true);
      }).catch((e)=>{
        this.isLoading = false;
        callback(false);
      })
    }catch(e){
      this.isLoading=false;
      console.log()
    }
  }

  @action
  async updateInbox(message:ICallbackInbox,callback: (isSuccess: boolean) => void){
    try{
      this.isLoading=true;
      await this.afs.collection('inbox').doc(message.key).update({
        ...message,
      }).then(()=>{
        this.isLoading=false;
        callback(true);
      }).catch((e)=>{
        this.isLoading=false;
        callback(false);
      })
    }catch(e){
      console.log(e);
    }
  }

  @action
  async delete(message:ICallbackInbox,callback: (isSuccess: boolean) => void){
    try{
      this.isLoading=true;
      await this.afs.collection('inbox').doc(message.key).delete()
    }catch(e){
      console.log(e);
      callback(false);
    }
  }
}
