import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { INews } from 'src/app/models/news.model';
import { NewsStore } from 'src/app/stores/news.store';

@Component({
  selector: 'app-home-sixth-block',
  templateUrl: './home-sixth-block.component.html',
  styleUrls: ['./home-sixth-block.component.scss']
})
export class HomeSixthBlockComponent implements OnInit {

  constructor(
    public newsStore : NewsStore,
    private router : Router,
  ) { }

  ngOnInit(): void {
  }
  newsClicked(data:INews){
    this.router.navigate(['news/'+data.key])
  }
}
