import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsStore } from 'src/app/stores/news.store';
import { NewsCategoryStore } from 'src/app/stores/news_category.store';

@Component({
  selector: 'app-news-detail-page',
  templateUrl: './news-detail-page.component.html',
  styleUrls: ['./news-detail-page.component.scss']
})
export class NewsDetailPageComponent implements OnInit {

  constructor(
    private route : ActivatedRoute,
    private newsStore : NewsStore,
    private newsCategoryStore : NewsCategoryStore,
  ) { }

  ngOnInit(): void {
    this.newsCategoryStore.getNewsCategories();
    this.route.paramMap.subscribe(params => {
      var id = params.get('id');
      this.newsStore.getNewsByKey(id);
    });
  }

}
