import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tabs } from 'src/app/data/tabs';
import { ISlideShow } from 'src/app/models/slideshow.model';
import { DeleteConfirmationComponent } from 'src/app/shared/backend/delete-confirmation/delete-confirmation.component';
import { SettingStore } from 'src/app/stores/setting.store';
import { SlideShowStore } from 'src/app/stores/slideshow.store';
import { AddSlideshowComponent } from './add-slideshow/add-slideshow.component';
import { SlideshowDetailComponent } from './slideshow-detail/slideshow-detail.component';

@Component({
  selector: 'app-slideshow-dashboard',
  templateUrl: './slideshow-dashboard.component.html',
  styleUrls: ['./slideshow-dashboard.component.scss']
})
export class SlideshowDashboardComponent implements OnInit {

  loading: boolean = false;
  TABS: Array<any> = tabs.homepage;
  // isChecked : boolean = true;

  constructor(
    private dialog : MatDialog,
    private _snackBar : MatSnackBar,
    public slideShowStore : SlideShowStore,
  ) { }

  ngOnInit(): void {
    this.slideShowStore.getSlideShows();
    // this.settingStore.getSetting('slideshow');
  }

  async create() {
    const dialogRef = this.dialog.open(AddSlideshowComponent, {
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Add SlideShow',
        formData: null
      }
    });

    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });
  }

  edit(data: ISlideShow) {
    const dialogRef = this.dialog.open(AddSlideshowComponent, {
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Edit SlideShow',
        formData: data,
      }
    });

    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });
  }

  delete(data: ISlideShow) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.slideShowStore.deleteSlideShow(data, (isSucess) => {
          if (isSucess) {
            this.slideShowStore.reorder()

            this._snackBar.open('SlideShow is deleted successfully.', 'Done',{ duration: 5000 })
          }
        })
      }
    });
  }

  rowClick(data:any){
    const dialogRef=this.dialog.open(SlideshowDetailComponent,{
      width:'760px',
      height:'96vh',
      data:{...data},
    });
    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });
  }

  disable(data:ISlideShow){
    var v:ISlideShow;
    if(data.status==='Active'){
      v={
        ...data,
        status:'Disable'
      }
    }else{
      v={
        ...data,
        status:'Active'
      }
    }
    this.slideShowStore.updateSlideShowWithoutCallback(v);
  }
}
