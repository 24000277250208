import { Component, OnInit } from '@angular/core';
import { ProjectCategoryStore } from 'src/app/stores/project-category.store';
import { ProjectStore } from 'src/app/stores/project.store';

@Component({
  selector: 'app-project-grid-page',
  templateUrl: './project-grid-page.component.html',
  styleUrls: ['./project-grid-page.component.scss']
})
export class ProjectGridPageComponent implements OnInit {

  constructor(
    private projectStore : ProjectStore,
    private projectCategoryStore : ProjectCategoryStore
  ) {
    this.projectStore.getProjectNoDisable();
    this.projectCategoryStore.getCategoryNoDisable();
  }

  ngOnInit(): void {
  }

}
