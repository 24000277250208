<div class="bg-0 p-t-100 p-b-50">
    <div class="container servicePage-container">
        <div class="row justify-content-center">
          <ng-container *ngFor="let item of this.serviceStore.ServicesNoDisable">
            <div class="col-sm-6 col-md-4 p-b-42" (click)=serviceClicked(item.key)>
                <div>
                    <!-- <a class="hov-img0 of-hidden">
                        <div class="imageContainer">
                            <img *ngIf="item?.images[0]?.downloadURL; else defaultImage" src={{item?.images[0]?.downloadURL}} alt="IMG">
                            <ng-template #defaultImage>
                              <img #defaultImage src="../../../../../assets/images/default-image.png" alt="IMG">
                            </ng-template>
                        </div>
                    </a> -->
                    <div class="wrapper-card shadow high-card">
                        <div class="p-t-26 flex-justify">
                            <div>
                                <a class="d-inline-block t1-m-1 cl-1 hov-link2 trans-02 m-b-7 cardTitle">
                                    {{item.title}}
                                </a>
                                <!-- <p class="t1-s-2 cl-6 m-b-17 cardText">
                                    {{item.description | shorten : 150 : '...'}}
                                </p> -->
                                <p class="t1-s-2 cl-2 m-b-17 cardText" [innerHTML]="item.descriptions | shorten : 100 : '...'">
                                    <!-- {{item.description | shorten : 150 : '...'}} -->
                                </p>
                                <!-- <a class="t1-s-5 cl-11 text-uppercase hov-link1 trans-02 btnViewService">
                                    <i class="fa fa-long-arrow-right m-r-6"></i>
                                    View Services
                                </a> -->
                            </div>
                            <div style="margin-top: 20px;">
                                <a style="color:white;cursor:pointer;" class=" bg-11 d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-6 bg-0 hov-btnCustom trans-02">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
          </ng-container>
        </div>
    </div>
</div>
