import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { action, observable } from "mobx";
import { UserMappingService } from "../services/mapping/user-mapping.service";
import { AuthStore } from "./auth.store";

@Injectable({providedIn:'root'})
export class HeaderMenuStore {

  @observable public isLoading: boolean = false;
  @observable public headerMenuData: Array<any> = [];
  @observable public headerTitle: Array<any> = [];


  constructor(
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private userMapping: UserMappingService,
    private authStore: AuthStore,
  ) { }

  @action
  async addHeaderMenu(data: any){
        this.isLoading = true
        await this.afs.collection('header-menu').doc(data.key).set(data).then(() =>{
            this.isLoading = false
        }).catch((e)=>{
          console.log(e,"Error")
          this.isLoading =false
        })
    
  }
  @action
  async updateHeaderMenu (data:any){
    this.isLoading = true
    await this.afs.collection('header-menu').doc(data.key).update({
        ...data,
    }).then(()=>{
        this.isLoading = false;
    }).catch((e)=>{
        console.log(e,"Error")
        this.isLoading =false;
    })
  }

  @action
  async deleteHeaderMenu (data:any){
    this.isLoading = true
    await this.afs.collection('header-menu').doc(data.key).update({
        ...data,
        isDelete: true,
        updated_at : new Date(),
    }).then(()=>{
        this.isLoading = false;
    }).catch((e)=>{
        console.log(e,"Error")
        this.isLoading =false;
    })
  }
  @action
  fetchHeaderMenu(){
    this.isLoading = true
    this.afs.collection('header-menu',(ref => ref.where('isDelete','==',false).orderBy('page_key','desc'))).valueChanges().subscribe((data)=>{
      this.headerMenuData = data
      console.log('Data Header',this.headerMenuData)
      this.isLoading = false
    })
  }

  @action
  fetchHeaderTitle(){
    this.isLoading = true
    this.afs.collection('header-menu',(ref => ref.where('isDelete','==',false).where('isHomepage','==',true))).valueChanges().subscribe((data)=>{
      this.headerTitle = data
      console.log('Data headerTitle',this.headerTitle)

    })
  }
}