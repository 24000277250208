import { Component, OnInit } from '@angular/core';
import { tabs } from 'src/app/data/tabs';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { SettingStore } from 'src/app/stores/setting.store';
import { v4 as uuidv4 } from 'uuid';

import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import { FormBuilder, FormControl } from '@angular/forms';
import { IImage } from 'src/app/models/image.model';


@Component({
  selector: 'app-page-setting-dashboard',
  templateUrl: './page-setting-dashboard.component.html',
  styleUrls: ['./page-setting-dashboard.component.scss']
})
export class PageSettingDashboardComponent implements OnInit {

  form: any;
  task: AngularFireUploadTask;
  TABS : Array<any> = tabs.pagesettings;
  percentage: any = 0;
  company_logo : File = null;
  constructor(
    public settingStore : SettingStore,
    private storage: AngularFireStorage,
    private formBuilder : FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      color: new FormControl(''),
    })
  }


  ngOnInit(): void {
    this.settingStore.getSettings();
  }

  formSubmit(formData:any){
    alert("asd")
      const {color} =formData;
      document.documentElement.style.setProperty(`--primaryColor`, color);
  }

  async onSelectedImages(event: any){

    const file =event.target.files[0]

    this.company_logo=file;
    await this.uploadFileToFirebase(file,'company_logo').then(uplodedFile=>{
      this.settingStore.updateSetting('company_logo',uplodedFile)
    })
  }

  deleteImage(data:any){
    for(let i of this.settingStore.settings){
      if(i.type==="company_logo"){
        const storageRef = firebase.storage().ref();
        storageRef.child(i.value.fileName).delete();
      }
    }
    this.company_logo = null;
  }

  async uploadFileToFirebase(item: File, basePath: string) {
    const filePath = `${basePath}/${Date.now()}_${item.name}`;
    const storageRef = this.storage.ref(filePath);

    this.task = this.storage.upload(filePath, item);
    this.task.percentageChanges().subscribe((percentage) => {
      this.percentage = percentage;
    });

    return new Promise<IImage | any>((resolve, reject) => {
      this.task.then((f) => {
        f.ref.getDownloadURL().then((downloadURL) => {
          resolve({
            key: uuidv4(),
            downloadURL: downloadURL,
            fileName: filePath,
            fileSize: item.size,
            fileType: item.type,
            name: item.name,
            type: 'image',
          });
        }).catch(e => reject(e))
      });
    })
  }
}
