import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tabs } from 'src/app/data/tabs';
import { IProject } from 'src/app/models/project.model';
import { DeleteConfirmationComponent } from 'src/app/shared/backend/delete-confirmation/delete-confirmation.component';
import { ProjectStore } from 'src/app/stores/project.store';
import { AddProjectComponent } from './add-project/add-project.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';

@Component({
  selector: 'app-project-dashbord',
  templateUrl: './project-dashbord.component.html',
  styleUrls: ['./project-dashbord.component.scss']
})
export class ProjectDashbordComponent implements OnInit {
  TABS: Array<any> = tabs.project;
  isLoading: boolean = false;

  constructor(
    private dialog: MatDialog,
    public projectStore : ProjectStore
  ) { }

  ngOnInit(): void {
    this.projectStore.fetchData(null,null,null,(res)=>{
      this.isLoading = false;
    })
  }

  create(){
    const dialogRef = this.dialog.open(AddProjectComponent, {
      width: '760px',
      height: '96vh',
    });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });
  }

  deleteProject(data:any){
    const dialogRef = this.dialog.open(DeleteConfirmationComponent)
    dialogRef.afterClosed().subscribe(res=>{
      if(res)
        this.projectStore.deleteProject(data)
    })
  }

  edit(data:any){
    const dialogRef = this.dialog.open(AddProjectComponent, {
      width: '760px',
      height: '96vh',
      data:data,
    });
    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }

  disable(data:IProject){
    var v:IProject;
    if(data.status==='Active'){
      v={
        ...data,
        status:'Disable'
      }
    }else{
      v={
        ...data,
        status:'Active'
      }
    }
    this.projectStore.updateProject(v);
  }

  rowClick(data:any){
    const dialogRef=this.dialog.open(ProjectDetailComponent,{
      width:'760px',
      height:'96vh',
      data:{...data},
    });
    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh'});
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }

  async scrollHandler(e, size: number) {
    if (size < 20) return;
    if (e.endIndex === size - 1) {
      await this.projectStore.fetchDataMore(res => {
        this.isLoading = false;
      })
    }
  }

  trackByIdx(i: any) {
    return i;
  }
}
