<div style="width: 100%;" class="pageContainerForSpinner">
    <app-admin-command-bar [tabData]="TABS" [name]="'Page Settings'" [buttonText]="'Add Header Menu'"
      (onPress)="create()">
    </app-admin-command-bar>
    <div style="padding: 20px !important;" *ngIf="store.isLoading">
        <ng-container>
          <p-skeleton styleClass="p-mb-2"></p-skeleton> <br>
          <p-skeleton width="10rem" styleClass="p-mb-2"></p-skeleton> <br>
          <p-skeleton width="5rem" styleClass="p-mb-2"></p-skeleton> <br>
          <p-skeleton height="2rem" styleClass="p-mb-2"></p-skeleton> <br>
          <p-skeleton width="10rem" height="4rem"></p-skeleton>
        </ng-container>
      </div>
    <div class="tableContainer" *ngIf="!store.isLoading">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col" width="1%">No</th>
              <th scope="col" width="5%">Title Header Menu</th>
              <th scope="col" width="25%">Header Menu Url</th>
              <th scope="col" width="1%">Action</th>
            </tr>
          </thead>
          <tbody #container>
            <ng-container>
              <tr *ngFor="let item of store.headerMenuData ;let i=index;">
                <th  scope="row">{{i + 1}}</th>
                <td >{{item?.header_menu}}</td>
                <td >{{item?.header_url}}</td>
                <td>
                  <div class="actionButtonsContainer container">
                    <div class="row">
                      <!-- <div style="margin-right: 10px;">
                        <mat-icon aria-label="disable" (click)="disable()">hide_source</mat-icon>
                      </div> -->
                      <div  style="margin-right: 10px;"  >
                        <mat-icon aria-label="edit" (click)="edit(item)">mode_edit_outline</mat-icon>

                      </div>
                      <div>
                        <mat-icon aria-label="delete" (click)="delete(item)">delete_outline</mat-icon>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr  *ngIf="this.store.headerMenuData?.length === 0">
                <td colspan="11"><div style="text-align: center;">No Data Header Menu.</div></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
    </div>
</div>
