<div class="FormDetailContainer">
  <div class="dialog-header">
    <h2 mat-dialog-title>Management Team Detail</h2>
    <div class="space1"></div>
    <a mat-button mat-icon-button (click)="dialogRef.close()">
        <mat-icon class="mat-18">clear</mat-icon>
    </a>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-9">
        <table>
          <tbody>
            <tr>
              <td class="meta" style="width: 140px;">
                <h3>Name </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.name}}</h3>
              </td>
            </tr>
            <tr>
              <td class="meta">
                <h3>Position </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.position}}</h3>
              </td>
            </tr>
            <tr>
              <td class="meta">
                <h3>Created by </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.created_by?.name}}</h3>
              </td>
            </tr>
            <tr>
              <td class="meta">
                <h3>Created at </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.created_at?.seconds*1000|date:'medium'}}</h3>
              </td>
            </tr>
            <tr>
              <td class="meta">
                <h3>Updated by </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.updated_by?.name}}</h3>
              </td>
            </tr>
            <tr>
              <td class="meta">
                <h3>Updated at </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.updated_at?.seconds*1000|date:'medium'}}</h3>
              </td>
            </tr>

            <tr>
              <td class="meta">
                <h3>Status </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.status}}</h3>
              </td>
            </tr>

            <!-- <tr>
              <td class="meta">
                <h3>Speech :</h3>
              </td>
              <td class="data">
              </td>
            </tr>

            <tr style="width:200%">
              <td colspan="10">
                <p>{{this.data?.speech}}</p>
              </td>
            </tr> -->

            <tr *ngIf="this.data?.socialMedia?.facebook">
              <td class="meta">
                <h3>Facebook </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.socialMedia?.facebook}}</h3>
              </td>
            </tr>

            <tr *ngIf="this.data?.socialMedia?.email">
              <td class="meta">
                <h3>Email </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.socialMedia?.email}}</h3>
              </td>
            </tr>

            <tr *ngIf="this.data?.socialMedia?.instagram">
              <td class="meta">
                <h3>Instagram </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.socialMedia?.instagram}}</h3>
              </td>
            </tr>

            <tr *ngIf="this.data?.socialMedia?.twitter">
              <td class="meta">
                <h3>Twitter </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.socialMedia?.twitter}}</h3>
              </td>
            </tr>

            <tr *ngIf="this.data?.socialMedia?.linkedin">
              <td class="meta">
                <h3>Linkedin </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.socialMedia?.linkedin}}</h3>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-3" style="display: flex; flex-direction: row-reverse;">
        <div></div>
        <div style="margin-left:auto;">
          <img class="image--cover" [defaultImage]="'./assets/images/circle-loading.gif'" [lazyLoad]="data?.image?.downloadURL" alt="{{ data?.image?.name }}">
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <h3>Speech :</h3>
    <div>
      <p>{{this.data?.speech}}</p>
    </div>
  </div>
  <div mat-dialog-actions style="top:95vh">
    <button mat-flat-button color="warn" mat-dialog-close>Close</button>
  </div>
</div>
