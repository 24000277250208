import { Component, OnInit } from '@angular/core';
import { BusinessPartnerStore } from 'src/app/stores/business_partner.store';

@Component({
  selector: 'app-home-seventh-block',
  templateUrl: './home-seventh-block.component.html',
  styleUrls: ['./home-seventh-block.component.scss']
})
export class HomeSeventhBlockComponent implements OnInit {

  constructor(
    public businessPartnerStore : BusinessPartnerStore,
  ) { }

  ngOnInit(): void {
  }

}
