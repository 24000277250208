// export class IUser {
//   key: string;
//   full_name: string;
//   last_name: string;
//   first_name: string;
//   keywords: any;
//   email: string;
//   role: object;
//   dob: Date;
//   gender: string;
//   phone: string;
//   profileImageUrl: string;
//   profileImagePath: string;
//   occupation: string;
//   address: string;
//   workExperiences: Array<any>;
//   bio: string;
//   instructor_info: any;

//   create_date?: any;
//   create_by?: object;
//   update_date?: any;
//   update_by?: object;
//   status?: any;
//   isDeactivated?: boolean;
// }

export class IUser{
  key: string;
  name: string;
  email: string;
  dob: Date;
  isFirstLog: boolean;
}