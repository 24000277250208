import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";

import { INewsCategory } from 'src/app/models/news-category-model';
import { INews } from 'src/app/models/news.model';
import { generateKeywords } from 'src/app/services/generators/generate-keywords.service';
import { NewsCategoryMappingService } from 'src/app/services/mapping/news-category-mapping.service';
import { UserMappingService } from 'src/app/services/mapping/user-mapping.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { NewsStore } from 'src/app/stores/news.store';
import { NewsCategoryStore } from 'src/app/stores/news_category.store';
import { IImage } from 'src/app/models/image.model';
import { modulesEditor } from 'src/app/data/editor';

interface DialogData {
  formTitle: string;
  formData: INews;
}


@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit {

  form: any;
  uploadedImages : Array<IImage>=[];
  selectedImages : Array<File> = [];
  filePath : Array<string>=[];
  task : AngularFireUploadTask;
  percentage : any = 0;
  modules = modulesEditor;

  constructor(
    public newsStore: NewsStore,
    private authStore: AuthStore,
    public newsCategoryStore: NewsCategoryStore,
    private userMappingService: UserMappingService,
    private newsCategoryMappingService: NewsCategoryMappingService,

    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddNewsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.form = formBuilder.group({
      title: new FormControl(data?.formData?.title || '', Validators.required),
      category: new FormControl(data?.formData?.category || null, Validators.required),
      description: new FormControl(data?.formData?.description || ''),
      descriptions: new FormControl(data?.formData?.descriptions || ''),

    });

    if (data.formData) {
      this.uploadedImages = data?.formData?.images;
    }
  }

  ngOnInit(): void {
    this.newsCategoryStore.getNewsCategoriesNoDisable();
  }

  compareFn(obj1: INewsCategory, obj2: INewsCategory) {
    return obj1 && obj2 ? obj1.key === obj2.key : obj1 === obj2;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async formSubmit(formData: any) {
    const { title, description, category,descriptions } = formData;

    if (this.form.valid) {
      if(!this.data?.formData?.key) {
        const key = this.afs.createId();

        const result = await Promise.all(this.selectedImages.map(async (item) => {
          await this.uploadFileToFirebase(item, 'news_images').then(uplodedFile => {
            this.uploadedImages.push(uplodedFile);
          });
          return;
        }));

        const data: INews = {
          key,
          title: title,
          description: description,
          descriptions: descriptions,
          images: this.uploadedImages,
          category: this.newsCategoryMappingService.map(category),

          created_at: new Date(),
          created_by: this.userMappingService.mapUser(this.authStore.User),
          updated_by: null,
          updated_at: null,
          page_key: this.pageKey(),
          keyword: generateKeywords([title]),

          status:'Active',
          isDelete: false,
          isPublished: false,
        }

        this.newsStore.addNews(data, (isSuccess) => {
          this.dialogRef.close();
          this._snackBar.open("News is added successfully.", "Done",{ duration: 5000 });
        });
      } else {
        for(let item of this.data.formData?.images){
          if(!this.uploadedImages.find((e: IImage) => e.key === item.key)){
            try {
              const storageRef = firebase.storage().ref();
              await storageRef.child(item.fileName).delete();
            } catch(e) {
              console.log(e);
            }
          }
        }

        const result = await Promise.all(this.selectedImages.map(async (item) => {
          await this.uploadFileToFirebase(item, 'news_images').then(uplodedFile => {
            this.uploadedImages.push(uplodedFile);
          });
          return;
        }));

        const data: INews = {
          ...this.data.formData,
          title: title,
          description: description,
          descriptions: descriptions,
          category: this.newsCategoryMappingService.map(category),
          images : this.uploadedImages,
          updated_by: this.userMappingService.mapUser(this.authStore.User),
          updated_at: new Date(),
        }

        this.newsStore.updateNews(data, (isSuccess) => {
          this.dialogRef.close();
          this._snackBar.open("News is updated successfully.", "Done",{ duration: 5000 });
        });
      }
    }
  }
  onSelectedImages(event: any) {
    const arrFile = event.target.files as FileList;

    for (let i = 0; i < arrFile.length; i++) {
      const file = arrFile.item(i);
      if (file){
        this.selectedImages.push(file);
      }
    }
  }

  async uploadFileToFirebase(item: File, basePath: string) {
    const filePath = `${basePath}/${Date.now()}_${item.name}`;
    const storageRef = this.storage.ref(filePath);

    this.task = this.storage.upload(filePath, item);
    this.task.percentageChanges().subscribe((percentage) => {
      percentage = percentage || 0;
      this.percentage += percentage / this.selectedImages.length;
    });

    return new Promise<IImage | any>((resolve, reject) => {
      this.task.then((f) => {
        f.ref.getDownloadURL().then((downloadURL) => {
          resolve({
            key: uuidv4(),
            downloadURL: downloadURL,
            fileName: filePath,
            fileSize: item.size,
            fileType: item.type,
            name: item.name,
            type: 'image',
          });
        }).catch(e => reject(e))
      });
    })
  }

  deleteSelectedImage(selectedImage: File) {
    this.selectedImages = this.selectedImages.filter(e => e.name !== selectedImage.name && e.size !== selectedImage.size)
  }

  deleteUploadedImage(selectedImage: IImage) {
    this.uploadedImages = this.uploadedImages.filter(e => e.key !== selectedImage.key);
  }

  pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }

  debug(data: any) {
    console.log(data)
  }

}
