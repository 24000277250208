<header>
    <!-- Header desktop -->
    <nav class="container-header-desktop"  style="height: 70px; z-index: 99;">
        <!-- <div class="top-bar">
            <div class="content-topbar container flex-sb-c h-full">
                <div class="size-w-0 flex-wr-s-c">
                    <div class="t1-s-1 top-bar-text cl-13 m-r-50">
                        <span class=" m-r-6">
                            <i class="fa fa-home" aria-hidden="true"></i>
                        </span>
                        <span>{{informationStore?.arrInformation[0]?.company_address}}</span>
                    </div>

                    <div class="t1-s-1 top-bar-text cl-13 m-r-50">
                        <span class=" m-r-6">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                        </span>
                        <span>{{informationStore?.arrInformation[0]?.company_phone_number}}</span>
                    </div>

                    <div class="t1-s-1 top-bar-text cl-13 m-r-50">
                        <span class=" m-r-6">
                            <i class="fa fa-clock-o" aria-hidden="true"></i>
                        </span>
                        <span>{{informationStore?.arrInformation[0]?.company_working_hours}}</span>
                    </div>
                </div>

                <div class="text-nowrap">
                    <a href={{informationStore?.arrInformation[0]?.company_facebook}} class="fs-24 cl-13 hov-link2 trans-02 m-l-15" target="new">
                        <i class="fa fa-facebook-official"></i>
                    </a>

                    <a href="#" class="fs-16 cl-13 hov-link2 trans-02 m-l-15">
                        <i class="fa fa-twitter"></i>
                    </a>

                    <a href="#" class="fs-16 cl-13 hov-link2 trans-02 m-l-15">
                        <i class="fa fa-google-plus"></i>
                    </a>

                    <a href="#" class="fs-16 cl-13 hov-link2 trans-02 m-l-15">
                        <i class="fa fa-instagram"></i>
                    </a>

                    <a href="#" class="fs-16 cl-13 hov-link2 trans-02 m-l-15">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </div>
            </div>
        </div> -->

        <div class="wrap-menu-desktop" style="box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);"
            #stickyMenu
            [class.sticky] = "sticky"
            >
            <div class="limiter-menu-desktop container">
                <!-- Logo desktop -->
                <div class="logo-image" [routerLink]="['/home']" routerLinkActive="router-link-active" >
                    <!-- <a *ngIf="settingStore?.company_logo?.value?.downloadURL" href="index.html"> <img src={{settingStore?.company_logo?.value?.downloadURL}} alt="LOGO"></a> -->
                   <!-- <img src={{settingStore?.company_logo?.value?.downloadURL}} alt="LOGO"> -->
                   <h3>{{settingStore?.fontSize?.value?.company_name}}</h3>
                </div>
                <div class="flex-1"></div>
                <!-- Menu desktop -->
                <div class="menu-desktop">
                    <ul class="main-menu respon-sub-menu">
                        <li >
                            <a [routerLink]="['/home']" routerLinkActive="Active">Home</a>
                        </li>

                        <li *ngIf="configStore?.about_us">
                            <a [routerLink]="['/about']" routerLinkActive="Active">About Us</a>
                        </li>

                        <li *ngIf="configStore?.services">
                            <a [routerLink]="['/services']" routerLinkActive="Active">Services</a>
                        </li>

                        <li *ngIf="configStore?.news">
                            <a [routerLink]="['/news']" routerLinkActive="Active">News</a>
                        </li>

                        <li *ngIf="configStore?.projects">
                            <a [routerLink]="['/projects']" routerLinkActive="Active">Projects</a>
                        </li>

                        <li *ngIf="configStore?.contact_us">
                            <a [routerLink]="['/contact']" routerLinkActive="Active">Contact us</a>
                        </li>
                        <li *ngIf="configStore?.library">
                            <a href="https://library.camwin.com.kh/" target="_blank">Library</a>
                        </li>
                        <li *ngIf="headerStore?.headerTitle.length > 0">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <!-- <mat-icon>more_vert</mat-icon> -->
                                <span>More</span>
                            </button>
                            <mat-menu #menu="matMenu">
                                <ng-container *ngFor="let item of headerStore?.headerTitle" >
                                    <a [href]="item?.header_url" target="_blank">

                                    <button mat-menu-item >
                                        <span>
                                            {{item?.header_menu}}
                                        </span>
                                    </button>
                                    </a>
                                </ng-container>
                              </mat-menu>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>


    <!-- Header Mobile -->
    <nav class="container-header-mobile" style="box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);">
        <div class="wrap-header-mobile">
            <!-- Logo moblie -->
            <div class="logo-mobile">
                <!-- <a href="index.html"><img src="./assets/images/CamVin logo.png" alt="LOGO"></a> -->
                <h3>{{settingStore?.fontSize?.value?.company_name}}</h3>
            </div>
            <!-- Button show menu -->
            <div class="btn-show-menu-mobile hamburger hamburger--squeeze" (click)="display=!display;">
              <div id="nav-icon1" *ngIf="!display">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div id="nav-icon2" *ngIf="display">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
        </div>
        <div class="flex-dirextion">
          <div class="menu-sidebar" (click)="userClick()" [ngStyle]="{'width':display? '100vw':'0px' }">
            <div class="menu-container" [ngStyle]="{'width':display? '100px':'0px' }">
                  <div [routerLink]="['/']" class="icon">
                    <i class="material-icons md-35">home</i>
                    <a>Home</a>
                  </div>
                  <div *ngIf="configStore?.about_us" [routerLink]="['/about']" class="icon">
                    <i class="material-icons md-35">groups</i>
                    <a>About Us</a>
                  </div>
                  <div *ngIf="configStore?.services" [routerLink]="['/services']" class="icon">
                    <i class="material-icons md-35">supervisor_account</i>
                    <a>Services</a>
                  </div>
                  <div *ngIf="configStore?.news" [routerLink]="['/news']" class="icon">
                    <i class="material-icons md-35">article</i>
                    <a>News</a>
                  </div>
                  <div *ngIf="configStore?.projects" [routerLink]="['/projects']" class="icon">
                    <i class="material-icons md-35">dashboard</i>
                    <a>Projects</a>
                  </div>
                  <div  *ngIf="configStore?.contact_us" [routerLink]="['/contact']" class="icon">
                    <i class="material-icons md-35">phone</i>
                    <a>Contact us</a>
                  </div>
                  <a *ngIf="configStore?.library" href="https://library.camwin.com.kh/" target="_blank">
                    <div   class="icon">
                        <i class="material-icons md-35">book</i>
                        <a>Library</a>
                      </div>
                  </a>
                  <ng-container *ngFor="let item of headerStore?.headerTitle">
                    <a [href]="item?.header_url" target="_blank">
                        <div   class="icon">
                            <i class="material-icons md-35">link</i>
                            <a>{{item?.header_menu}}</a>
                          </div>
                      </a>
                  </ng-container>
                  
            </div>
          </div>
        </div>
    </nav>
</header>
