import { Injectable } from '@angular/core';
import { INewsCategory } from 'src/app/models/news-category-model';

@Injectable({
  providedIn: 'root'
})
export class NewsCategoryMappingService {

  constructor() { }

  map(newsCategory: INewsCategory) {
    if(newsCategory == null) {
      return newsCategory;
    }
    else {
      return {
        key: newsCategory.key,
        name: newsCategory.name,
      }
    }
  }
}