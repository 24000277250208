import { Component, OnInit } from '@angular/core';
import { InformationStore } from 'src/app/stores/information.store';

@Component({
  selector: 'app-about-seventh-block',
  templateUrl: './about-seventh-block.component.html',
  styleUrls: ['./about-seventh-block.component.scss']
})
export class AboutSeventhBlockComponent implements OnInit {

  constructor(
    public informationStore : InformationStore,
  ) { }

  ngOnInit(): void {
  }

}
