import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { INews } from 'src/app/models/news.model';
import { NewsStore } from 'src/app/stores/news.store';
import { NewsCategoryStore } from 'src/app/stores/news_category.store';

@Component({
  selector: 'app-news-grid-block',
  templateUrl: './news-grid-block.component.html',
  styleUrls: ['./news-grid-block.component.scss']
})
export class NewsGridBlockComponent implements OnInit {

  lottieConfig;
  anim;
  Active : string = 'allCategory'

  dummyArray = ['', '', '', '', '', ''];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,

    public newsCategoryStore: NewsCategoryStore,
    public newsStore: NewsStore
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const { categoryKey } = params;
      if(categoryKey) this.Active="";
    })
    this.lottieConfig = {
      path: 'assets/files/54637-shopcash-empty.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true,
    };
  }

  categoryClicked(categoryData) {
    if (categoryData){
      this.Active=categoryData.name;
      // this.router.navigate(['/news'], { queryParams: { categoryKey: categoryData?.key } });
    }
    else{
      this.Active="allCategory";
      // this.router.navigate(['/news']);
    }
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  animationCreated(animationItem: AnimationItem): void {
  }

}
