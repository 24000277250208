import { Component, OnInit } from '@angular/core';
import { InformationStore } from 'src/app/stores/information.store';

@Component({
  selector: 'app-about-first-block',
  templateUrl: './about-first-block.component.html',
  styleUrls: ['./about-first-block.component.scss']
})
export class AboutFirstBlockComponent implements OnInit {

  constructor(
    public informationStore : InformationStore,

  ) { }

  ngOnInit(): void {
  }

}
