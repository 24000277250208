import { Component, OnInit } from '@angular/core';
import { NguCarousel, NguCarouselStore } from '@ngu/carousel';
import { SlideShowStore } from 'src/app/stores/slideshow.store';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-animation-slideshow',
  templateUrl: './animation-slideshow.component.html',
  styleUrls: ['./animation-slideshow.component.scss']
})
export class AnimationSlideshowComponent implements OnInit {

  arrImgs: Array<string> = ['assets/images/slider1.jpg', 'assets/images/slider2.jpg', 'assets/images/slider3.jpg'];

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: true,
    autoplay:true
  };

  constructor(
    public slideShowStore: SlideShowStore
  ) { }

  ngOnInit(): void {
    this.slideShowStore.getSlideShowsNoDisable();
  }
}
