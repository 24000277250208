<form [formGroup]="form" class="form-wrapper-change" *mobxAutorun>
    <div class="dialog-header">
        <div class="title" style="font-weight: 500;
        font-size: 20px;color: black;">
          Change Password
        </div>
        <div class="flex1"></div>
        <!-- <a mat-dialog-close class="close-dialog" (click)="dialogRef.close('no')">
            <i class="material-icons">&#xE14C;</i>
        </a> -->
    </div>
    <div class="dialog-content" style="padding: 16px;">
        <div class="form-wrapper mat-dialog-container-bg">
            <div class="form-group ">
                <mat-form-field appearance="outline">
                    <mat-label>Current Password</mat-label>
                    <input [type]="hide ? 'password' : 'text'" #focusInput placeholder="បញ្ចូលពាក្យសម្ងាត់បច្ចុប្បន្នរបស់អ្នក។" matInput
                        formControlName="oldPassword">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </button>
                    <mat-error *ngIf="form.controls['oldPassword'].hasError('required')">
                        Fill Info
                    </mat-error>
                    <mat-error *ngIf="form.controls['oldPassword'].hasError('minlength')">
                        Password of at least 8 characters,one capital letter and one number
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group ">
                <mat-form-field appearance="outline">
                    <mat-label>New Password</mat-label>
                    <input  [type]="new ? 'password' : 'text'" placeholder="Enter min 8 char" matInput formControlName="password">
                    <button mat-icon-button matSuffix (click)="new = !new" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{new ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                    <mat-error *ngIf="form.controls['password'].hasError('required')">
                        Fill Info
                    </mat-error>
                    <mat-error *ngIf="form.controls['password'].hasError('minlength')">
                        Password of at least 8 characters,one capital letter and one number
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group ">
                <mat-form-field appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <input [type]="confirm ? 'password' : 'text'" placeholder="Enter new password again" matInput formControlName="confirm">
                    <button mat-icon-button matSuffix (click)="confirm = !confirm" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{confirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                    <mat-error *ngIf="form.controls['confirm'].hasError('required')">
                        Fill Info
                       
                    </mat-error>
                    <mat-error *ngIf="form.controls['confirm'].hasError('minlength')">
                        Password of at least 8 characters,one capital letter and one number
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <div class="flex-1"></div>
        <!-- <a mat-button class="mat-puc cancel" (click)="dialogRef.close('no')"
            style="min-height: 40px;align-items: center;"><span>cancel</span></a> -->
        <app-primary-button (onPress)="onSave(form.value)" [disable]="!form.valid" [process]="processClose"
            label="Confirm">
        </app-primary-button>
    </div>
</form>
