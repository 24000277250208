import { Component, OnInit } from '@angular/core';
import { ManagementTeamStore } from 'src/app/stores/management_team.store';
import { SettingStore } from 'src/app/stores/setting.store';
import { TestimonialStore } from 'src/app/stores/testimonial.store';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit {

  constructor(
    private managementTeamStore : ManagementTeamStore,
    private testimonialStore : TestimonialStore,
    public settingStore : SettingStore,
  ) {
    this.managementTeamStore.getManagementTeamNoDisable();
    this.testimonialStore.getTestimonialNoDisable();
    this.settingStore.getAboutSettings();
  }

  ngOnInit(): void {
  }

}
