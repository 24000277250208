import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, of, Subscription } from "rxjs";
import { distinct } from "rxjs/operators";

import { UserMappingService } from "../services/mapping/user-mapping.service";
import { AuthStore } from "./auth.store";
import { IProjectCategory } from "../models/project-category.model";

@Injectable({providedIn:'root'})
export class ProjectCategoryStore {

  @observable public isLoading: boolean = false;

  @observable public categorys:Array<IProjectCategory>;
  @observable public categorysNoDisable:Array<IProjectCategory>;
  @observable public category:IProjectCategory;

  constructor(
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private userMapping: UserMappingService,
    private authStore: AuthStore,
  ) { }

  @action
  getCategory(){
    try{
      this.isLoading=true;
      this.afs.collection('project-category',ref=>ref.where('isDelete','==',false).orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.categorys=data;
        this.isLoading=false;
      });
    }catch(e){
      console.log(e)
    }
  }

  @action
  getCategoryNoDisable(){
    try{
      this.isLoading=true;
      this.afs.collection('project-category',ref=>ref.where('isDelete','==',false).where('status','==','Active').orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.categorys=data;
        this.isLoading=false;
      });
    }catch(e){
      console.log(e)
    }
  }

  @action
  async addCategory(data:IProjectCategory){
    try{
      this.isLoading=true;
      await this.afs.collection('project-category').doc(data.key).set(data).then(()=>{
        this.isLoading=false
      })
    }catch(e){
      console.log(e);
    }
  }

  @action
  async updateCategory(data:IProjectCategory){
    try{
      this.isLoading=true;
      await this.afs.collection('project-category').doc(data.key).update({
        ...data,
      }).then(()=>{
        this.isLoading=false;
      })
    }catch(e){
      console.log(e);
    }
  }

  @action
  updateCategoryStatus(data:IProjectCategory){
    this.afs.collection('project-category').doc(data.key).update(data);
  }

  @action
  async deleteCategory(data:IProjectCategory){
    try{
      await this.afs.collection('project-category').doc(data.key).update({
        ...data,
        isDelete:true,
        updated_at : new Date(),
        updated_by : this.userMapping.mapUser(this.authStore.User)
      })
    }catch(e){
      console.log(e);
    }
  }


  lastVisible: any = null;
  infinite: Observable<any[]>;

  @observable public orderBy = null;
  @observable public searchText = null;
  @observable public filter = null;

  @observable public loading: boolean = false;
  @observable public fetching: boolean = false;
  @observable public process: boolean = false;

  @observable data: any = [];
  @observable fetchListingRef: Subscription;

  infiniteData(data: any) {
    this.infinite = of(data).pipe(distinct((p:any) => p.key));
  }

  async fetchData(search: any, filter: any, orderBy: any, callback: (arg0: any) => void) {
    this.orderBy = orderBy;
    this.searchText = search;
    this.filter = filter;
    this.loading = true;
    this.fetching = false;
    this.lastVisible = null;

    const ref = this.lazyDataRef(this.lastVisible, search, filter, orderBy);

    this.fetchListingRef = ref.snapshotChanges().subscribe((response: any) => {
      this.data = [];

      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        callback(this.data)
        return false;
      }

      this.lastVisible = response[response.length - 1].payload.doc;

      for (let item of response) {
        this.data.push(item.payload.doc.data() as any);
      }

      this.data = this.data.map((f: any, index: number) => ({
        ...f,
        rowIndex: index + 1
      }))

      this.infiniteData(this.data);
      this.loading = false
      this.fetching = false;
      callback(this.data);
      return true;
    }, (error: any) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  @action
  async fetchDataMore(callback: (arg0: any) => void) {
    this.fetching = true;

    this.lazyDataRef(this.lastVisible, this.searchText, this.filter, this.orderBy).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }

      this.lastVisible = response.docs[response.docs.length - 1];

      for (let item of response.docs) {
        this.data.push(item.data() as any);
      }

      this.data = this.data.map((f: any, index: number) => ({
        ...f,
        rowIndex: index + 1
      }))

      this.fetching = false;
      this.infiniteData(this.data);

      callback(this.data);
      return;
    }, (error: any) => {
      this.fetching = false;
    });
  }

  lazyDataRef(lastVisible: any, search: any, filter: any, orderBy: any) {
    return this.afs.collection<any>("project-category", ref => {

      let condition = ref.where('isDelete','==',false).orderBy('page_key').limit(20)

      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }

      return condition;
    })

  }
}
