<form [formGroup]="form" (ngSubmit)="formSubmit(form.value)">
    <mat-dialog-content class="mat-typography">
  
      <div class="dialog-header">
        <!-- <h2 mat-dialog-title>{{this.data.formTitle}}</h2> -->
        <h2 mat-dialog-title *ngIf="this.data; else Edit">Update Header Menu</h2>
        <ng-template #Edit>
          <h2 mat-dialog-title >Add Header Menu</h2>
        </ng-template>
        <div class="space1"></div>
        <a mat-button mat-icon-button (click)="dialogRef.close()">
            <mat-icon class="mat-18">clear</mat-icon>
        </a>
      </div>
      <div class="inputsDialogContainer">
  
        <mat-form-field appearance="outline" class="matInputForm">
          <mat-label>Title Header Menu</mat-label>
          <input matInput placeholder="Enter Title Header Menu" formControlName="header_menu" >
          <mat-error *ngIf="form.controls['header_menu']?.touched && form.controls['header_menu']?.errors?.required">Field is required.</mat-error>
        </mat-form-field>
  
        <mat-form-field appearance="outline" class="matInputForm">
          <mat-label>Link Url Header Menu https://</mat-label>
          <input matInput placeholder="Enter Link Url Header Menu https://" formControlName="header_url" >
        </mat-form-field>
        <mat-slide-toggle formControlName="isHomepage">Display in Header Menu?</mat-slide-toggle>
  
        <ng-container *ngIf="this.percentage">
          <mat-progress-bar mode="determinate" [value]="this.percentage"></mat-progress-bar>
        </ng-container>
  
      </div>
    </mat-dialog-content>
  
    <mat-dialog-actions align="end">
      <button type="submit" class="btnWithSpinner">
        <span class="material-icons" *ngIf="this.data">save</span>
        <span class="material-icons" *ngIf="!this.data">add</span>
        <span style="margin-left: 10px;" *ngIf="this.data">Save</span>
        <span style="margin-left: 10px;" *ngIf="!this.data">Add</span>
      </button>
  
    </mat-dialog-actions>
    </form>
  