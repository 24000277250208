import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { InformationStore } from 'src/app/stores/information.store';
import { SMTPClient } from 'emailjs';

@Component({
  selector: 'app-contact-first-block',
  templateUrl: './contact-first-block.component.html',
  styleUrls: ['./contact-first-block.component.scss']
})
export class ContactFirstBlockComponent implements OnInit {
  form : any;
  constructor(
    public informationStore : InformationStore,

    private formBuilder : FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      name : new FormControl(null, Validators.required),
      email : new FormControl(null,[Validators.required,Validators.email]),
      phoneNumber : new FormControl(null,Validators.required),
      message : new FormControl(null,Validators.required),
    })
  }

  ngOnInit(): void {
  }

}
