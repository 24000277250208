import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-fifth-block',
  templateUrl: './home-fifth-block.component.html',
  styleUrls: ['./home-fifth-block.component.scss']
})
export class HomeFifthBlockComponent implements OnInit {

  projectCounter:number=0;
  advisorCounter:number=0;
  satifactionRatCounter:number=0;


  projectCountStop:any = setInterval(()=>{
    this.projectCounter++;
    //we need to stop this at  particular point; will use if condition
    if(this.projectCounter === 450)
    {
      //clearinterval will stop tha function
      clearInterval(this.projectCountStop);
    }
  },1)

  advisorCountStop:any = setInterval(()=>{
    this.advisorCounter++;
    //we need to stop this at  particular point; will use if condition
    if(this.advisorCounter === 205)
    {
      //clearinterval will stop tha function
      clearInterval(this.advisorCountStop);
    }
  },9)

  satifactionRatCountStop:any = setInterval(()=>{
    this.satifactionRatCounter++;
    //we need to stop this at  particular point; will use if condition
    if(this.satifactionRatCounter === 98)
    {
      //clearinterval will stop tha function
      clearInterval(this.satifactionRatCountStop);
    }
  },20)

  constructor() { }

  ngOnInit(): void {

  }

}
