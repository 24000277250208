import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { MatSnackBar } from "@angular/material/snack-bar";

import { UserMappingService } from "../services/mapping/user-mapping.service";
import { AuthStore } from "./auth.store";
import { IManagementTeam } from "../models/management-team.model";

@Injectable({providedIn:'root'})
export class ManagementTeamStore {

  @observable public isLoading: boolean = false;
  @observable public managementTeam : Array<IManagementTeam> = [];
  @observable public managementTeamNoDisable : Array<IManagementTeam> = [];

  constructor(
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private userMapping: UserMappingService,
    private authStore: AuthStore,
  ) { }

  @action
  getManagementTeam() {
    try{
      this.isLoading=true;
      this.afs.collection('management-team', ref => ref.where('isDelete','==',false).orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.managementTeam = data;
        this.isLoading = false;
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  getManagementTeamNoDisable() {
    try{
      this.isLoading=true;
      this.afs.collection('management-team', ref => ref.where('isDelete','==',false).where('status','==','Active').orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.managementTeamNoDisable = data;
        this.isLoading = false;
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  async addManagementTeam(managementTeam: IManagementTeam, callback: (isSuccess: boolean) => void){
    try{
      this.isLoading = true;

      await this.afs.collection('management-team').doc(managementTeam.key).set(managementTeam).then(()=>{
        this.isLoading = false;
        callback(true);
      }).catch((e)=>{
        console.log(e);
        this.isLoading = false;
        callback(false);
      });
    }catch(e){
      console.log(e)
    }
  }

  @action
  async updateManagementTeam(managementTeam: IManagementTeam, callback: (isSuccess: boolean) => void){
    try{
      this.isLoading = true;

      await this.afs.collection('management-team').doc(managementTeam.key).update({
        ...managementTeam,
      }).then(() => {
        this.isLoading = false;
        callback(true);
      }).catch((e) => {
        this.isLoading = false;
        callback(false);
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  async updateManagementTeamWithoutCallback(managementTeam: IManagementTeam){
    this.afs.collection('management-team').doc(managementTeam.key).update(managementTeam);
  }

  @action
  async deleteManagementTeam(managementTeam: IManagementTeam, callback: (isSuccess: boolean) => void){
    try {
      this.isLoading = true;

      await this.afs.collection('management-team').doc(managementTeam.key).update({
        ...managementTeam,
        isDelete: true,
        updated_at: new Date(),
        updated_by: this.userMapping.mapUser(this.authStore.User)
      });

      this.isLoading = false;
      callback(true);
    }catch(e){
      console.log(e);
      this.isLoading = false;
      callback(false);
    }
  }
}
