import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { INewsCategory } from 'src/app/models/news-category-model';
import { NewsStore } from 'src/app/stores/news.store';
import { NewsCategoryStore } from 'src/app/stores/news_category.store';

interface DialogData {
  formTitle: string;
  formData: INewsCategory;
}

@Component({
  selector: 'app-add-news-category',
  templateUrl: './add-news-category.component.html',
  styleUrls: ['./add-news-category.component.scss']
})
export class AddNewsCategoryComponent implements OnInit {

  form: any;

  constructor(
    public newsCategoryStore: NewsCategoryStore,
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddNewsCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.form = formBuilder.group({
      name: new FormControl(data?.formData?.name || '', Validators.required),
      description: new FormControl(data?.formData?.description || '', Validators.required),
    })
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  formSubmit(formData: any) {
    if (this.form.valid) {
      if (this.data?.formData?.key) {
        this.form.disable();
        this.newsCategoryStore.updateNewsCategory({
          ...this.data?.formData,
          ...formData
        }, (isSucess) => {
          if (isSucess) {
            this.form.enable();
            this._snackBar.open('News Category is updated successfully!', 'Done',{ duration: 5000 });
          }
        });
      } else {
        this.form.disable();
        const id = this.afs.createId();
        this.newsCategoryStore.addNewsCategory({
          key: id,
          ...formData
        }, (isSucess) => {
          if (isSucess) {
            this.form.reset();
            this.form.enable();
            this._snackBar.open('News Category is added successfully!', 'Done',{ duration: 5000 });
          }
        });
      }
    }
  }
}
