import { Component, OnInit } from '@angular/core';
import { BusinessPartnerStore } from 'src/app/stores/business_partner.store';
import { TestimonialStore } from 'src/app/stores/testimonial.store';

@Component({
  selector: 'app-service-third-block',
  templateUrl: './service-third-block.component.html',
  styleUrls: ['./service-third-block.component.scss']
})
export class ServiceThirdBlockComponent implements OnInit {

  constructor(
    public testimonialStore : TestimonialStore,
    public businessPartnerStore : BusinessPartnerStore,
  ) { }

  ngOnInit(): void {
  }

}
