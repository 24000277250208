import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IService } from 'src/app/models/service.model';
import { ServiceStore } from 'src/app/stores/service.store';
import { TestimonialStore } from 'src/app/stores/testimonial.store';

@Component({
  selector: 'app-service-content-block',
  templateUrl: './service-content-block.component.html',
  styleUrls: ['./service-content-block.component.scss']
})
export class ServiceContentBlockComponent implements OnInit {
  constructor(
    public serviceStore:ServiceStore,
    private router: Router,
  ) {
    this.serviceStore.getServiceNoDisable();
  }

  ngOnInit(): void {
  }
  sidebarServiceClicked(serviceData:IService){
    if(serviceData.key===this.serviceStore.Service.key){
    }else{
      this.router.navigate(['/services/'+serviceData.key])
    }
  }
}
