<form [formGroup]="form">
    <div style="width: 100%;" class="pageContainerForSpinner">
      <app-admin-command-bar [tabData]="TABS" [name]="'About us page'" [buttonText]="'Save'"
        (onPress)="formSubmit(form.value)">
      </app-admin-command-bar>
  
      <div style="padding: 20px !important;" *ngIf="informationStore.isLoading">
        <ng-container>
          <p-skeleton styleClass="p-mb-2"></p-skeleton> <br>
          <p-skeleton width="10rem" styleClass="p-mb-2"></p-skeleton> <br>
          <p-skeleton width="5rem" styleClass="p-mb-2"></p-skeleton> <br>
          <p-skeleton height="2rem" styleClass="p-mb-2"></p-skeleton> <br>
          <p-skeleton width="10rem" height="4rem"></p-skeleton>
        </ng-container>
      </div>
  
      <!-- *ngIf="!informationStore.isLoading" -->
  
      <div class="formContainer" *ngIf="!informationStore.isLoading">
        <div style="background-color: #fff;padding:20px">
            <div class="inputContainer">
                <label class="label">Company History</label>
                <quill-editor [modules]="modules" [styles]="{height: '200px'}"
                  formControlName="company_history"></quill-editor>
              </div>
        </div>
      </div>
    </div>
  </form>