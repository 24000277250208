import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tabs } from 'src/app/data/tabs';
import { IInformation } from 'src/app/models/information.model';
import { UserMappingService } from 'src/app/services/mapping/user-mapping.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { InformationStore } from 'src/app/stores/information.store';
import { SettingStore } from 'src/app/stores/setting.store';
import { v4 as uuidv4 } from 'uuid';
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import { modulesEditor } from 'src/app/data/editor';



@Component({
  selector: 'app-information-dashboard',
  templateUrl: './information-dashboard.component.html',
  styleUrls: ['./information-dashboard.component.scss']
})
export class InformationDashboardComponent implements OnInit {

  loading: boolean = false;
  task: AngularFireUploadTask;
  TABS: Array<any> = tabs.pagesettings;
  key:string=null;
  company_logo:any;
  imgURL:any;
  color_theme: string;
  color_theme1: string;

  form:any;
  modules = modulesEditor;
  constructor(
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private formBuilder: FormBuilder,
    private userMappingService: UserMappingService,
    private authStore: AuthStore,
    private storage: AngularFireStorage,

    public informationStore: InformationStore,
    public settingStore : SettingStore,

  ) {
    this.form = this.formBuilder.group({
      // color_theme : new FormControl('',Validators.required),
      company_description: new FormControl('', Validators.required),
      company_contact_description: new FormControl('', Validators.required),
      company_email: new FormControl('', Validators.required),
      company_facebook: new FormControl(''),
      company_phone_number: new FormControl('', Validators.required),
      company_address: new FormControl('', Validators.required),
      company_working_hours: new FormControl('', Validators.required),
      company_google_map: new FormControl('', Validators.required),
      company_info_kh: new FormControl(''),
      company_info_en: new FormControl(''),
      company_mission_vision: new FormControl('', Validators.required),
      company_history: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {

    this.settingStore.getSetting('company_logo').then(()=>{
      this.imgURL=this.settingStore?.setting?.value?.downloadURL
    })
    this.settingStore.getSetting('color_theme').then(()=>{
      this.color_theme=this.settingStore?.setting?.value;
      this.color_theme1=this.color_theme;
    });
    this.informationStore.getInformation2((data: IInformation) => {
      this.key=data[0].key;
      this.form.patchValue({
        company_description: data[0].company_description,
        company_contact_description: data[0].company_contact_description,
        company_email: data[0].company_email,
        company_facebook: data[0].company_facebook,
        company_phone_number: data[0].company_phone_number,
        company_address: data[0].company_address,
        company_working_hours: data[0].company_working_hours,
        company_google_map: data[0].company_google_map,
        company_mission_vision: data[0].company_mission_vision,
        company_history: data[0].company_history,
        company_info_kh: data[0].company_info_kh,
        company_info_en: data[0].company_info_en,

      });
    });
  }
  formSubmit(formData) {
    if (this.form.valid) {
      if(!this.key){
        const data: IInformation = {
          key: this.afs.createId(),
          ...formData,

          created_at: new Date(),
          created_by: this.userMappingService.mapUser(this.authStore.User),
          updated_by: this.userMappingService.mapUser(this.authStore.User),
          updated_at: new Date(),
        }
        this.informationStore.addInformation(data, (isSuccess) => {
          if(isSuccess) this._snackBar.open("Information is inserted sucessfully.", "Done",{ duration: 5000 });
        })
      }else{
        const data: IInformation = {
          key: this.key,
          ...formData,

          updated_by: this.userMappingService.mapUser(this.authStore.User),
          updated_at: new Date(),
        }
        this.informationStore.updateInformation(data, async (isSuccess) => {
          // if(this.company_logo){
          //   await this.uploadFileToFirebase(this.company_logo,'company_logo').then(uplodedFile=>{
          //     this.settingStore.getSetting('company_logo').then(()=>{
          //       const storageRef = firebase.storage().ref();
          //       storageRef.child(this.settingStore?.setting?.value?.fileName).delete();
          //       this.settingStore.updateSetting('company_logo',uplodedFile);
          //       this.settingStore.updateSetting('color_theme',this.color_theme);
          //     });
          //   })
          // }
          // this.settingStore.updateSetting("color_theme",this.color_theme);
          if(isSuccess) this._snackBar.open("Information is updated sucessfully.", "Done",{ duration: 5000 });
        })
      }
    }
  }

  colorPreview(event:any){
    this.color_theme1=event;
  }

  colorChange(){
    this.color_theme=this.color_theme1;
  }

  onSelectedImages(event:any){
    this.company_logo =event.target.files[0]
    this.imgURL=(window.URL ? URL : webkitURL).createObjectURL(event.target.files[0])
  }
}
