import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { SettingStore } from 'src/app/stores/setting.store';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {

  disabled: boolean = true;
  toggleSidebar: boolean = true;

  expansions = {
    one: true,
    two: false,
  };

  constructor(
    public authStore: AuthStore,
    public authService: AuthService,
    public router: Router,

    public settingStore : SettingStore,
    // public inboxStore:InboxStore,

    protected localStorages: LocalStorage,
  ) {}

  async ngOnInit(): Promise<void> {
    // this.inboxStore.getInbox();
    this.authStore.getCurrentLoggedInUser();
    this.settingStore.getCompanyLogo();
    const expansions = JSON.parse(localStorage?.getItem("expansions") || "");
    if (expansions) {
      this.expansions = expansions
    }
    this.localStorages.getItem("toggleSidebar").subscribe(toggleSidebar => {
      this.disabled = this.toggleSidebar;
      if (!this.toggleSidebar) {
        let body = document.getElementsByClassName("page-wrapper")[0];
        body.classList.toggle("toggled-sidebar");
      }
    });
  }

  onLogOut() {
    this.authService.signout_admin();
  }

  onOpened(i: any) {
    const isopen = true;
    switch (i) {
      case 1:
        this.expansions.one = isopen;
        break;
      case 2:
        this.expansions.two = isopen;
        break;
      default:
        break;
    }

    localStorage.setItem('expansions', JSON.stringify(this.expansions));
  }

  onClosed(i: any) {
    const isopen = false;
    switch (i) {
      case 1:
        this.expansions.one = isopen;
        break;
      case 2:
        this.expansions.two = isopen;
        break;
      default:
        break;
    }
    localStorage.setItem('expansions', JSON.stringify(this.expansions));
  }

  togglesidebar() {
    let body = document.getElementsByClassName("page-wrapper")[0];
    body.classList.toggle("toggled-sidebar");
    this.toggleSidebar = !this.toggleSidebar;
    this.localStorages
      .setItem("toggleSidebar", this.toggleSidebar)
      .subscribe(() => { });
  }

}
