<form [formGroup]="form" (ngSubmit)="formSubmit(form.value)">
  <mat-dialog-content class="mat-typography">

    <div class="dialog-header">
      <!-- <h2 mat-dialog-title>{{this.data.formTitle}}</h2> -->
      <h2 mat-dialog-title *ngIf="this.data; else Edit">Update project</h2>
      <ng-template #Edit>
        <h2 mat-dialog-title >Add Project</h2>
      </ng-template>
      <div class="space1"></div>
      <a mat-button mat-icon-button (click)="dialogRef.close()">
          <mat-icon class="mat-18">clear</mat-icon>
      </a>
    </div>
    <div class="inputsDialogContainer">

      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Project name</mat-label>
        <input matInput placeholder="Enter project name" formControlName="name" >
        <mat-error *ngIf="form.controls['name']?.touched && form.controls['name']?.errors?.required">Field is required.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Partner name</mat-label>
        <input matInput placeholder="Enter partner name" formControlName="partnerName" >
      </mat-form-field>

      <!-- <div class="textArea">
        <mat-form-field appearance="outline" >
          <mat-label>Description</mat-label>
          <textarea matInput rows="10" placeholder="Enter description" formControlName="description"></textarea>
        </mat-form-field>
      </div> -->
      <div class="inputContainer">
        <p>Description</p>
        <quill-editor [modules]="modules" [styles]="{height: '200px'}"
          formControlName="descriptions"></quill-editor>
      </div>

      <mat-slide-toggle formControlName="isHomepage">Display in Homepage?</mat-slide-toggle>

      <div class="rows" style="margin: 12px 0 12px 3px;">
        <!-- <button mat-raised-button color="primary" (click)="imageFiles.click()" type="button">
          Select Project Images
        </button> -->

        <button type="button" class="btnWithSpinner1" (click)="imageFiles.click()">
          <span style="margin-left: 5px;">Select Images</span>
        </button>
      </div>

      <div class="groupButtonSelectedImages">
        <!-- <div class="buttonSelectedImage" *ngFor="let item of this.arrImgs; let i=index;">
          <button mat-raised-button type="button">
            {{ item | shorten : 50 : '...' }}
          </button>
          <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteImage(item)">
            Delete
          </button>
        </div> -->
        <div class="buttonSelectedImage" *ngIf="uploadedImage">
          <p>{{ this.uploadedImage.name }}</p>
          <!-- <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteUploadedImage(this.uploadedImage.name)">Delete</button> -->
        </div>

        <div class="buttonSelectedImage" *ngIf="selectedImage">
            <p>{{ selectedImage?.name }}</p>
            <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteSelectedImage(selectedImage?.name)">Delete</button>
        </div>
      </div>

      <input #imageFiles style="display: none;" type="file" id="imageFiles" accept='image/*' multiple
      (change)="onSelectedImages($event)" />

      <br/>
      <h3>Client Information:</h3>

      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Client name</mat-label>
        <input matInput placeholder="Enter client name" formControlName="clientName" >
      </mat-form-field>

      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Website</mat-label>
        <input matInput placeholder="Enter client website" formControlName="website" >
      </mat-form-field>

      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Location</mat-label>
        <input matInput placeholder="Enter client Location" formControlName="location" >
      </mat-form-field>

      <h5>Client social media:</h5>

      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Facebook</mat-label>
        <input matInput placeholder="Optional" formControlName="facebook" >
      </mat-form-field>

      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Twitter</mat-label>
        <input matInput placeholder="Optional" formControlName="twitter" >
      </mat-form-field>

      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Instagram</mat-label>
        <input matInput placeholder="Optional" formControlName="instagram" >
      </mat-form-field>

      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Optional" formControlName="email" >
      </mat-form-field>

      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Linkedin</mat-label>
        <input matInput placeholder="Optional" formControlName="linkin" >
      </mat-form-field>

      <ng-container *ngIf="this.percentage">
        <mat-progress-bar mode="determinate" [value]="this.percentage"></mat-progress-bar>
      </ng-container>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <!-- <button mat-raised-button color="warn" mat-dialog-close>Cancel</button> -->
    <!-- <button *ngIf="!this.data" mat-raised-button color="primary" cdkFocusInitial type="submit">Add</button>
    <button *ngIf="this.data" mat-raised-button color="primary" cdkFocusInitial type="submit">Save</button> -->

    <button type="submit" class="btnWithSpinner">
      <span class="material-icons" *ngIf="this.data">save</span>
      <span class="material-icons" *ngIf="!this.data">add</span>
      <span style="margin-left: 10px;" *ngIf="this.data">Save</span>
      <span style="margin-left: 10px;" *ngIf="!this.data">Add</span>
    </button>

  </mat-dialog-actions>
  </form>
