<section class="bg-0 p-t-60 pad-t">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-10 col-md-10 p-b-35">
                <!-- Title section -->
                <div class="flex-col-c-c p-b-50">
                    <h3 class="t1-b-1 cl-3 m-b-11 txt-center">
                        Mission & Vision
                    </h3>

                    <div class="size-a-2 bg-3"></div>
                </div>

                <div class="p-r-0-sr767">
                    <p class="t1-s-2 cl-1 m-b-10 cardText" [innerHTML]="informationStore?.arrInformation[0]?.company_mission_vision | safe:'html'" style="word-break: break-word;">
                        <!-- <span class="t1-s-5 cl-3">At present:</span> Leave your information and email address. We will call back and advise you. Duis aute irure dolor in reprehenderit in voluptate. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere offis possimus -->
                        
                    
                    </p>

                    <!-- <p class="t1-s-2 cl-6 m-b-10">
                        <span class="t1-s-5 cl-3">In the future:</span> Leave your information and email address. We will call back and advise you. Duis aute irure dolor in reprehenderit in voluptate. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus.
                    </p> -->
                </div>
            </div>

            <!-- <div class="col-sm-10 col-md-6 p-b-35">
                <div class="flex-col-c-s p-b-8">
                    <h3 class="t1-b-1 cl-3 m-b-11">
                        Our Stats
                    </h3>

                    <div class="size-a-2 bg-3"></div>
                </div>
                <div class="wrap-progress size-w-6 w-full-sr767">
                    <span class="dis-block t1-s-4 cl-3 p-b-8 p-t-26">
                        Financial Analyst
                    </span>

                    <div class="progress-item">
                        <div class="progress-inner txt-s-113 cl0 txt-right p-r-10" data-percent="93%"></div>
                    </div>

                    <span class="dis-block t1-s-4 cl-3 p-b-8 p-t-26">
                        Business Suports
                    </span>

                    <div class="progress-item">
                        <div class="progress-inner txt-s-113 cl0 txt-right p-r-10" data-percent="60%"></div>
                    </div>

                    <span class="dis-block t1-s-4 cl-3 p-b-8 p-t-26">
                        Inverstments
                    </span>

                    <div class="progress-item">
                        <div class="progress-inner txt-s-113 cl0 txt-right p-r-10" data-percent="80%"></div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
