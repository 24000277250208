import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-third-block',
  templateUrl: './about-third-block.component.html',
  styleUrls: ['./about-third-block.component.scss']
})
export class AboutThirdBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
