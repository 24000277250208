import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IProject } from 'src/app/models/project.model';
import { ProjectStore } from 'src/app/stores/project.store';

@Component({
  selector: 'app-home-fourth-block',
  templateUrl: './home-fourth-block.component.html',
  styleUrls: ['./home-fourth-block.component.scss']
})
export class HomeFourthBlockComponent implements OnInit {

  constructor(
    public projectStore : ProjectStore,
    private router : Router,
  ) { }

  ngOnInit(): void {
  }
  projectClick(data:IProject){
      this.router.navigate(['projects/'+data.key])
  }

}
