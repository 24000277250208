import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MobxAngularModule } from 'mobx-angular';
import { NgPipesModule } from 'ngx-pipes';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { SkeletonModule } from 'primeng/skeleton';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { MatCarouselModule } from 'ng-mat-carousel';
import { NguCarouselModule } from '@ngu/carousel';
import { QuillModule } from 'ngx-quill'
import { SwiperConfigInterface,SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomePageComponent } from './pages/home-page/home-page.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { NewsDetailPageComponent } from './pages/news-detail-page/news-detail-page.component';
import { NewsGridPageComponent } from './pages/news-grid-page/news-grid-page.component';
import { ProjectGridPageComponent } from './pages/project-grid-page/project-grid-page.component';
import { ProjectDetailPageComponent } from './pages/project-detail-page/project-detail-page.component';
import { ServiceGridPageComponent } from './pages/service-grid-page/service-grid-page.component';
import { ServiceDetailPageComponent } from './pages/service-detail-page/service-detail-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';

import { HeaderComponent } from './shared/frontend/header/header.component';
import { FooterComponent } from './shared/frontend/footer/footer.component';
import { AdminCommandBarComponent } from './shared/backend/admin-command-bar/admin-command-bar.component';
import { AdminFooterComponent } from './shared/backend/admin-footer/admin-footer.component';
import { AdminHeaderComponent } from './shared/backend/admin-header/admin-header.component';
import { AdminSidebarComponent } from './shared/backend/admin-sidebar/admin-sidebar.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { TabLayoutComponent } from './layouts/tab-layout/tab-layout.component';
import { BackendLayoutComponent } from './layouts/backend-layout/backend-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

import { PrimaryButtonComponent } from './components/backend/primary-button/primary-button.component';
import { AnimatedSlideshowComponent } from './components/frontend/animated-slideshow/animated-slideshow.component';
import { IntroductionPageBlockComponent } from './components/frontend/introduction-page-block/introduction-page-block.component';

import { HomeFirstBlockComponent } from './components/frontend/home-components/home-first-block/home-first-block.component';
import { HomeSecondBlockComponent } from './components/frontend/home-components/home-second-block/home-second-block.component';
import { HomeThirdBlockComponent } from './components/frontend/home-components/home-third-block/home-third-block.component';
import { HomeFourthBlockComponent } from './components/frontend/home-components/home-fourth-block/home-fourth-block.component';
import { HomeFifthBlockComponent } from './components/frontend/home-components/home-fifth-block/home-fifth-block.component';
import { HomeSixthBlockComponent } from './components/frontend/home-components/home-sixth-block/home-sixth-block.component';
import { HomeSeventhBlockComponent } from './components/frontend/home-components/home-seventh-block/home-seventh-block.component';
import { HomeEighthBlockComponent } from './components/frontend/home-components/home-eighth-block/home-eighth-block.component';
import { HomeNinethBlockComponent } from './components/frontend/home-components/home-nineth-block/home-nineth-block.component';
import { HomeTenthBlockComponent } from './components/frontend/home-components/home-tenth-block/home-tenth-block.component';
import { AboutFirstBlockComponent } from './components/frontend/about-components/about-first-block/about-first-block.component';
import { AboutSecondBlockComponent } from './components/frontend/about-components/about-second-block/about-second-block.component';
import { AboutThirdBlockComponent } from './components/frontend/about-components/about-third-block/about-third-block.component';
import { AboutFourthBlockComponent } from './components/frontend/about-components/about-fourth-block/about-fourth-block.component';
import { AboutFifthBlockComponent } from './components/frontend/about-components/about-fifth-block/about-fifth-block.component';
import { AboutSixthBlockComponent } from './components/frontend/about-components/about-sixth-block/about-sixth-block.component';
import { ContactFirstBlockComponent } from './components/frontend/contact-components/contact-first-block/contact-first-block.component';
import { ContactSecondBlockComponent } from './components/frontend/contact-components/contact-second-block/contact-second-block.component';
import { NewsGridBlockComponent } from './components/frontend/news-components/news-grid-block/news-grid-block.component';
import { NewsCardComponent } from './components/frontend/news-components/news-card/news-card.component';
import { NewsContentBlockComponent } from './components/frontend/news-components/news-content-block/news-content-block.component';
import { ProjectGridBlockComponent } from './components/frontend/project-components/project-grid-block/project-grid-block.component';
import { ProjectContentBlockComponent } from './components/frontend/project-components/project-content-block/project-content-block.component';
import { ServiceFirstBlockComponent } from './components/frontend/service-components/service-first-block/service-first-block.component';
import { ServiceSecondBlockComponent } from './components/frontend/service-components/service-second-block/service-second-block.component';
import { ServiceThirdBlockComponent } from './components/frontend/service-components/service-third-block/service-third-block.component';
import { ServiceContentBlockComponent } from './components/frontend/service-components/service-content-block/service-content-block.component';
import { AdminDashboardComponent } from './pages/admin/admin-dashboard/admin-dashboard.component';
import { AdminLoginComponent } from './pages/auth/admin-login/admin-login.component';
import { UserDashboardComponent } from './pages/admin/user-dashboard/user-dashboard.component';
import { AddUserDialogComponent } from './pages/admin/user-dashboard/add-user-dialog/add-user-dialog.component';
import { EditUserDialogComponent } from './pages/admin/user-dashboard/edit-user-dialog/edit-user-dialog.component';
import { DeleteConfirmationComponent } from './shared/backend/delete-confirmation/delete-confirmation.component';
import { ThreeButtonsHomePageComponent } from './pages/three-buttons-home-page/three-buttons-home-page.component';
import { ServiceCardComponent } from './components/frontend/service-components/service-card/service-card.component';
import { ServiceDashboardComponent } from './pages/admin/service-dashboard/service-dashboard.component';
import { AddServiceComponent } from './pages/admin/service-dashboard/add-service/add-service.component';
import { ServiceDetailComponent } from './pages/admin/service-detail/service-detail.component';
import { NewsDashbordComponent } from './pages/admin/news-dashbord/news-dashbord.component';
import { NewsCategoryComponent } from './pages/admin/news-dashbord/news-category/news-category.component';
import { ProjectDashbordComponent } from './pages/admin/project-dashbord/project-dashbord.component';
import { AddProjectComponent } from './pages/admin/project-dashbord/add-project/add-project.component';
import { ProjectDetailComponent } from './pages/admin/project-dashbord/project-detail/project-detail.component';
import { ProjectCategoryComponent } from './pages/admin/project-dashbord/project-category/project-category.component';
import { AddCategoryComponent } from './pages/admin/project-dashbord/project-category/add-category/add-category.component';
import { AddNewsCategoryComponent } from './pages/admin/news-dashbord/news-category/add-news-category/add-news-category.component';
import { AddNewsComponent } from './pages/admin/news-dashbord/add-news/add-news.component';
import { AnimationSlideshowComponent } from './components/frontend/home-components/animation-slideshow/animation-slideshow.component';
import { SlideshowDashboardComponent } from './pages/admin/slideshow-dashboard/slideshow-dashboard.component';
import { AddSlideshowComponent } from './pages/admin/slideshow-dashboard/add-slideshow/add-slideshow.component';
import { InformationDashboardComponent } from './pages/admin/information-dashboard/information-dashboard.component';
import { BusinessPartnerDashboardComponent } from './pages/admin/business-partner-dashboard/business-partner-dashboard.component';
import { AddBusinessPartnerComponent } from './pages/admin/business-partner-dashboard/add-business-partner/add-business-partner.component';
import { ManagementTeamDashboardComponent } from './pages/admin/management-team-dashboard/management-team-dashboard.component';
import { AddManagementTeamComponent } from './pages/admin/management-team-dashboard/add-management-team/add-management-team.component';
import { TestimonialDashbordComponent } from './pages/admin/testimonial-dashbord/testimonial-dashbord.component';
import { AddTestimonialComponent } from './pages/admin/testimonial-dashbord/add-testimonial/add-testimonial.component';
import { SafePipe } from './pipes/safe.pipe';
import { NewsDetailComponent } from './pages/admin/news-dashbord/news-detail/news-detail.component';
import { NewsCategoryDetailComponent } from './pages/admin/news-dashbord/news-category/news-category-detail/news-category-detail.component';
import { ManagementTeamDetailComponent } from './pages/admin/management-team-dashboard/management-team-detail/management-team-detail.component';
import { TestimonialDetailComponent } from './pages/admin/testimonial-dashbord/testimonial-detail/testimonial-detail.component';
import { ProjectCategoryDetailComponent } from './pages/admin/project-dashbord/project-category/project-category-detail/project-category-detail.component';
import { BusinessPartnerDetailComponent } from './pages/admin/business-partner-dashboard/business-partner-detail/business-partner-detail.component';
import { SlideshowDetailComponent } from './pages/admin/slideshow-dashboard/slideshow-detail/slideshow-detail.component';
import { PageSettingDashboardComponent } from './pages/admin/page-setting-dashboard/page-setting-dashboard.component';
import { HomepageSettingDashboardComponent } from './pages/admin/page-setting-dashboard/homepage-setting-dashboard/homepage-setting-dashboard.component';
import { AboutUsSettingDashboardComponent } from './pages/admin/page-setting-dashboard/about-us-setting-dashboard/about-us-setting-dashboard.component';
import { CallbackInboxComponent } from './pages/admin/callback-inbox/callback-inbox.component';
import { ResetUserDialogComponent } from './pages/admin/user-dashboard/reset-user-dialog/reset-user-dialog.component';
import { AboutSeventhBlockComponent } from './components/frontend/about-components/about-seventh-block/about-seventh-block.component';
import { MissionVisionComponent } from './pages/admin/mission-vision/mission-vision.component';
import { CompanyHistoryComponent } from './pages/admin/company-history/company-history.component';
import { HeaderMenuDashboardComponent } from './pages/admin/header-menu-dashboard/header-menu-dashboard.component';
import { AddHeaderMenuDashboardComponent } from './pages/admin/header-menu-dashboard/add-header-menu-dashboard/add-header-menu-dashboard.component';
import { ChangePasswordFormComponent } from './pages/admin/change-password-form/change-password-form.component';

export function playerFactory() {
  return import('lottie-web');
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AboutUsPageComponent,
    ContactUsPageComponent,
    NotFoundPageComponent,

    HeaderComponent,
    FooterComponent,

    MainLayoutComponent,

    AnimatedSlideshowComponent,
    IntroductionPageBlockComponent,

    HomeThirdBlockComponent,
    HomeFourthBlockComponent,
    HomeFifthBlockComponent,
    HomeSixthBlockComponent,
    HomeFirstBlockComponent,
    HomeSecondBlockComponent,
    HomeSeventhBlockComponent,
    HomeEighthBlockComponent,
    HomeNinethBlockComponent,
    HomeTenthBlockComponent,

    AboutFirstBlockComponent,
    AboutSecondBlockComponent,
    AboutThirdBlockComponent,
    AboutFourthBlockComponent,
    AboutFifthBlockComponent,
    AboutSixthBlockComponent,
    ContactFirstBlockComponent,
    ContactSecondBlockComponent,
    NewsDetailPageComponent,
    NewsGridPageComponent,
    ProjectGridPageComponent,
    ProjectDetailPageComponent,
    NewsGridBlockComponent,
    NewsCardComponent,
    NewsContentBlockComponent,
    ProjectGridBlockComponent,
    ProjectContentBlockComponent,
    ServiceGridPageComponent,
    ServiceDetailPageComponent,
    ServiceFirstBlockComponent,
    ServiceSecondBlockComponent,
    ServiceThirdBlockComponent,
    ServiceContentBlockComponent,
    AdminCommandBarComponent,
    AdminFooterComponent,
    AdminHeaderComponent,
    AdminSidebarComponent,
    AdminLayoutComponent,
    TabLayoutComponent,
    BackendLayoutComponent,
    PrimaryButtonComponent,
    AdminDashboardComponent,
    AdminLoginComponent,
    UserDashboardComponent,
    AddUserDialogComponent,
    EditUserDialogComponent,
    DeleteConfirmationComponent,
    ThreeButtonsHomePageComponent,
    ServiceCardComponent,
    ServiceDashboardComponent,
    AddServiceComponent,
    ServiceDetailComponent,
    NewsDashbordComponent,
    NewsCategoryComponent,
    ProjectDashbordComponent,
    AddProjectComponent,
    ProjectDetailComponent,
    ProjectCategoryComponent,
    AddCategoryComponent,
    AddNewsCategoryComponent,
    AddNewsComponent,
    AnimationSlideshowComponent,
    SlideshowDashboardComponent,
    AddSlideshowComponent,
    InformationDashboardComponent,
    BusinessPartnerDashboardComponent,
    AddBusinessPartnerComponent,
    ManagementTeamDashboardComponent,
    AddManagementTeamComponent,
    TestimonialDashbordComponent,
    AddTestimonialComponent,
    SafePipe,
    NewsDetailComponent,
    NewsCategoryDetailComponent,
    ManagementTeamDetailComponent,
    TestimonialDetailComponent,
    ProjectCategoryDetailComponent,
    BusinessPartnerDetailComponent,
    SlideshowDetailComponent,
    PageSettingDashboardComponent,
    HomepageSettingDashboardComponent,
    AboutUsSettingDashboardComponent,
    CallbackInboxComponent,
    ResetUserDialogComponent,
    AboutSeventhBlockComponent,
    MissionVisionComponent,
    CompanyHistoryComponent,
    HeaderMenuDashboardComponent,
    AddHeaderMenuDashboardComponent,
    ChangePasswordFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MobxAngularModule,
    NgPipesModule,
    VirtualScrollerModule,
    SkeletonModule,
    LottieModule.forRoot({ player: playerFactory }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    LazyLoadImageModule,
    NguCarouselModule,
    MatCarouselModule.forRoot(),
    SwiperModule,
    QuillModule.forRoot()
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
