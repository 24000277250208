import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tabs } from 'src/app/data/tabs';
import { INewsCategory } from 'src/app/models/news-category-model';
import { DeleteConfirmationComponent } from 'src/app/shared/backend/delete-confirmation/delete-confirmation.component';
import { NewsCategoryStore } from 'src/app/stores/news_category.store';
import { AddNewsCategoryComponent } from './add-news-category/add-news-category.component';
import { NewsCategoryDetailComponent } from './news-category-detail/news-category-detail.component';

@Component({
  selector: 'app-news-category',
  templateUrl: './news-category.component.html',
  styleUrls: ['./news-category.component.scss']
})
export class NewsCategoryComponent implements OnInit {
  TABS: Array<any> = tabs.news;

  constructor(
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public newsCategoryStore: NewsCategoryStore
  ) { }

  ngOnInit(): void {
    this.newsCategoryStore.getsNewsCategories();
  }

  create(){
    const dialogRef = this.dialog.open(AddNewsCategoryComponent, {
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Add News Category',
        formData: null
      }
    });

    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });
  }

  editCategory(data: INewsCategory) {
    const dialogRef = this.dialog.open(AddNewsCategoryComponent, {
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Edit News Category',
        formData: data
      }
    });

    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }

  deleteCategory(data: INewsCategory) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.newsCategoryStore.deleteNewsCategory(data, (isSucess) => {
          if (isSucess) {
            this._snackBar.open('News Category is deleted successfully.', 'Done',{ duration: 5000 })
          }
        })
      }
    });
  }

  rowClick(data: INewsCategory){
    const dialogRef = this.dialog.open(NewsCategoryDetailComponent,{
      width:'760px',
      height:'96vh',
      data:{...data},
    })
    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }

  disable(data:INewsCategory){

    var v : INewsCategory;
    if(data.status==='Active'){
      v={
        ...data,
        status:'Disable'
      }
    }else{
      v={
        ...data,
        status:'Active'
      }
    }
    this.newsCategoryStore.updateNewsCategoryWithOutCallback(v);
  }
}
