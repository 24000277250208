<div class="parallax100 kit-overlay1 p-tb-38" >
    <div class="container">
        <div class="row">
            <div class="col-md-4 p-tb-30">
                <div class="flex-col-c-c h-full">
                    <span class="t1-b-2 cl-0 txt-center p-b-5 counter">
                        450
                    </span>

                    <span class="t1-m-1 cl-13 txt-center text-uppercase">
                        Projects
                    </span>
                </div>
            </div>

            <div class="col-md-4 p-tb-30">
                <div class="flex-col-c-c h-full">
                    <span class="t1-b-2 cl-0 txt-center p-b-5 counter">
                        205
                    </span>

                    <span class="t1-m-1 cl-13 txt-center text-uppercase">
                        advisor
                    </span>
                </div>
            </div>

            <div class="col-md-4 p-tb-30">
                <div class="flex-col-c-c h-full">
                    <span class="t1-b-2 cl-0 txt-center p-b-5">
                        <span class="counter">95</span>%
                    </span>

                    <span class="t1-m-1 cl-13 txt-center text-uppercase">
                        satisfaction rate
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
