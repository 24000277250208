<button [disabled]="process || disable"  mat-button class="mat-puc" type="submit" [ngClass]="class" (click)="_onPress()">
    <div class="puc-primary">
        <mat-spinner *ngIf="process"></mat-spinner>

        <span class="material-icons" style="margin-right: 10px;" *ngIf="label !== 'Save'">
            add
        </span>

        <span class="material-icons" style="margin-right: 10px;" *ngIf="label === 'Save'">
            save
        </span>

        <span>{{label}}{{process?'...':''}}</span>
    </div>
</button>