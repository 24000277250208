import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { IProject } from 'src/app/models/project.model';
import { UserMappingService } from 'src/app/services/mapping/user-mapping.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { ProjectStore } from 'src/app/stores/project.store';
import { generateKeywords } from 'src/app/services/generators/generate-keywords.service';
import { v4 as uuidv4 } from 'uuid';

import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";

// import { ProjectCategoryStore } from 'src/app/stores/project-category.store';
import { IImage } from 'src/app/models/image.model';
import { modulesEditor } from 'src/app/data/editor';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {
  form: any;
  projectImgs: Array<File> = [];
  arrImgs: Array<string> = [];
  task: AngularFireUploadTask;
  percentage: any = 0;

  uploadedImage: any;
  selectedImage: File;
  modules = modulesEditor;

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,

    private authStore: AuthStore,
    private projectStore: ProjectStore,
    // public projectCategoryStore: ProjectCategoryStore,

    private userMappingService: UserMappingService,

    public dialogRef: MatDialogRef<AddProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (this.data) {
      this.form = this.formBuilder.group({
        name: new FormControl(this.data?.name, Validators.required),
        description: new FormControl(this.data?.description),
        descriptions: new FormControl(this.data?.descriptions),
        isHomepage: new FormControl(this.data?.isHomepage),
        partnerName: new FormControl(this.data?.partners),
        clientName: new FormControl(this.data?.client?.name),
        website: new FormControl(this.data?.client?.website),
        location: new FormControl(this.data?.client?.location),
        facebook: new FormControl(this.data?.client?.socialMedia?.facebook),
        twitter: new FormControl(this.data?.client?.socialMedia?.twitter),
        instagram: new FormControl(this.data?.client?.socialMedia?.instagram),
        email: new FormControl(this.data?.client?.socialMedia?.email),
        linkin: new FormControl(this.data?.client?.socialMedia?.linkin),
      })
      this.uploadedImage = data.images;
    } else {
      this.form = this.formBuilder.group({
        name: new FormControl(null, Validators.required),
        description: new FormControl(null),
        descriptions: new FormControl(null),
        isHomepage: new FormControl(false),
        partnerName: new FormControl(null),
        clientName: new FormControl(null),
        website: new FormControl(null),
        location: new FormControl(null),
        facebook: new FormControl(null),
        twitter: new FormControl(null),
        instagram: new FormControl(null),
        email: new FormControl(null),
        linkin: new FormControl(null),
      })
    }
  }

  ngOnInit(): void {
    // this.projectCategoryStore.getCategory();
  }
  async formSubmit(formData: any) {
    if (this.form.valid) {
      const { name, description,descriptions, isHomepage, partnerName, clientName, website, location, facebook, twitter, instagram, email, linkin } = formData;
      if (!this.selectedImage && !this.uploadedImage) {
        alert("Please select any image.")
      } else{

        if (this.data) {

          if (this.selectedImage) {
            try {
              const storageRef = firebase.storage().ref();
              storageRef.child(this.data.images.fileName);
            } catch (e) {
              console.log(e);
            }
            await this.uploadFileToFirebase(this.selectedImage, 'project_images').then(uplodedFile => {
              this.uploadedImage = uplodedFile;
            });
          }

          const data: IProject = {
            ...this.data,
            name: name,
            description: description,
            descriptions:descriptions,
            isHomepage: isHomepage,
            partners: partnerName,
            client: { name: clientName, website: website, location: location, socialMedia: { facebook: facebook, twitter: twitter, instagram: instagram, email: email, linkin: linkin } },
            images: this.uploadedImage,
          }
          this.projectStore.updateProject(data);
          this.dialogRef.close();
          this.openSnackBar("Project is update successfully.", "Close");
        }
        else {
          console.log('no data')
          const key = this.afs.createId();
          await this.uploadFileToFirebase(this.selectedImage, 'project_images').then(uplodedFile => {
            this.uploadedImage = uplodedFile;
          });
          const data: IProject = {
            key,
            name: name,
            // category: category,
            description: description,
            descriptions:descriptions,
            isHomepage: isHomepage,
            partners: partnerName,
            client: {
              name: clientName,
              website: website,
              location: location,
              socialMedia: {
                facebook: facebook,
                twitter: twitter,
                instagram: instagram,
                email: email,
                linkin: linkin
              }
            },
            images: this.uploadedImage,
            created_at: new Date(),
            created_by: this.userMappingService.mapUser(this.authStore.User),
            page_key: this.pageKey(),
            keyword: generateKeywords([name]),

            status: 'Active',
            isDelete: false,

          }
          this.projectStore.addProject(data);
          this.dialogRef.close();
          this.openSnackBar("Project is create successfully.", "Close");
        }
      }
    }
  }
  onSelectedImages(event: any) {
    if (event.target.files) {
      this.selectedImage = event.target.files[0];
      this.uploadedImage = null;
    }
  }

  deleteSelectedImage(selectedImage: File) {
    this.selectedImage = undefined;
  }

  deleteUploadedImage(selectedImage: IImage) {
    this.uploadedImage = undefined;
  }

  async uploadFileToFirebase(item: File, basePath: string) {
    const filePath = `${basePath}/${Date.now()}_${item.name}`;
    const storageRef = this.storage.ref(filePath);

    this.task = this.storage.upload(filePath, item);
    this.task.percentageChanges().subscribe((percentage) => {
      percentage = percentage || 0;
      this.percentage += percentage / this.projectImgs.length;
    });
    return new Promise<IImage>((resolve, reject) => {
      this.task.then((f) => {
        f.ref.getDownloadURL().then((downloadURL) => {
          resolve({
            key: uuidv4(),
            downloadURL: downloadURL,
            fileName: filePath,
            fileSize: item.size,
            fileType: item.type,
            name: item.name,
            type: 'image',
          });
        }).catch(e => reject(e))
      });
    })
  }

  pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}


