<div class="FormDetailContainer">
  <div class="dialog-header">
    <h2 mat-dialog-title>Slideshow Detail</h2>
    <div class="space1"></div>
    <a mat-button mat-icon-button (click)="dialogRef.close()">
        <mat-icon class="mat-18">clear</mat-icon>
    </a>
  </div>
  <table>
    <tbody>
      <tr>
        <td class="meta">
          <h3>Created by </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.created_by?.name}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Created at </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.created_at?.seconds*1000|date:'medium'}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Status </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.status}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Image Preview :</h3>
        </td>
      </tr>
    </tbody>
  </table>
  <div>
    <img class="image--cover" [defaultImage]="'./assets/images/circle-loading.gif'" [lazyLoad]="data?.image?.downloadURL" alt="{{ data?.image?.name }}">
  </div>
  <div mat-dialog-actions style="top:95vh">
    <button mat-flat-button color="warn" mat-dialog-close>Close</button>
  </div>
</div>
