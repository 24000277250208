import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormControl, Validators,} from '@angular/forms';
import { Router } from '@angular/router';
import { IUser } from 'src/app/models/user.model';
import { UserStore } from 'src/app/stores/user.store';
import { Location } from '@angular/common';
import { AuthService } from '../../../services/auth-service/auth.service';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-admin-login',
  templateUrl:'./admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  loginForm;

  // lottie: AnimationOptions = {
  //   path: '/assets/animation/96732-hyperconfetti.json',
  // };

  constructor(
    public authService: AuthService,
    public userStore: UserStore,
    public formBuilder: FormBuilder,
  ) {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      check: new FormControl(false,)
    })
  }

  ngOnInit(): void {

  }

  onSubmit(formData:any) {
    const { email, password,check } = formData;
    this.authService.signin_admin(email, password,check);
  }

}
