import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";

import { INewsCategory } from 'src/app/models/news-category-model';
import { INews } from 'src/app/models/news.model';
import { generateKeywords } from 'src/app/services/generators/generate-keywords.service';
import { NewsCategoryMappingService } from 'src/app/services/mapping/news-category-mapping.service';
import { UserMappingService } from 'src/app/services/mapping/user-mapping.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { NewsStore } from 'src/app/stores/news.store';
import { NewsCategoryStore } from 'src/app/stores/news_category.store';
import { SlideShowStore } from 'src/app/stores/slideshow.store';
import { ISlideShow } from 'src/app/models/slideshow.model';
import { IImage } from 'src/app/models/image.model';

interface DialogData {
  formTitle: string;
  formData: ISlideShow;
}


@Component({
  selector: 'app-add-slideshow',
  templateUrl: './add-slideshow.component.html',
  styleUrls: ['./add-slideshow.component.scss']
})
export class AddSlideshowComponent implements OnInit {

  uploadedImage : IImage;
  selectedImage : File;
  task : AngularFireUploadTask;
  percentage : any = 0;
  selectVal : string;

  constructor(
    private authStore : AuthStore,
    private userMappingService : UserMappingService,

    public newsStore : NewsStore,
    public newsCategoryStore : NewsCategoryStore,
    public slideShowStore : SlideShowStore,

    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddSlideshowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  async formSubmit() {
    if(this.data?.formData?.key){
      if(this.selectVal){
        this.slideShowStore.changeOrder(this.data?.formData?.order,parseInt(this.selectVal),(res)=>{
          if(res){
            this.dialogRef.close();
            this._snackBar.open("Slideshow is updated sucessfully", "Done",{ duration: 5000 });
          }
        })
      }
    }else{
      if (!this.selectedImage) {
        alert("Please select any image to upload.")
      } else {
        await this.uploadFileToFirebase(this.selectedImage, 'slideshow').then((result) => {
          const key = this.afs.createId();

          const data: ISlideShow = {
            key,
            image:result,

            created_at: new Date(),
            created_by: this.userMappingService.mapUser(this.authStore.User),
            updated_by: null,
            updated_at: null,
            page_key: this.pageKey(),
            keyword: generateKeywords([result.name]),
            order: this.slideShowStore.slideShows.length+1,

            status: 'Active',
            isDelete: false,
            isPublished: false,
          }

          this.slideShowStore.addSlideShow(data, (isSucess) => {
            this.dialogRef.close();
            this._snackBar.open("Slideshow is uploaded sucessfully", "Done",{ duration: 5000 });
          })
        })
      }
    }

  }

  onSelectedImage(event) {
    if (event.target.files) {
      this.selectedImage = event.target.files[0];
    }
  }

  deleteSelectedImage() {
    this.selectedImage = undefined;
  }

  async uploadFileToFirebase(item: File, basePath: string) {
    const filePath = `${basePath}/${Date.now()}_${item.name}`;
    const storageRef = this.storage.ref(filePath);

    this.task = this.storage.upload(filePath, item);
    this.task.percentageChanges().subscribe((percentage) => {
      this.percentage = percentage;
    });

    return new Promise<IImage | any>((resolve, reject) => {
      this.task.then((f) => {
        f.ref.getDownloadURL().then((downloadURL) => {
          resolve({
            key: uuidv4(),
            downloadURL: downloadURL,
            fileName: filePath,
            fileSize: item.size,
            fileType: item.type,
            name: item.name,
            type: 'image',
          });
        }).catch(e => reject(e))
      });
    })
  }

  pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }

}
