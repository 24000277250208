import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserMappingService {

  constructor() { }

  mapUser(user: any) {
    if(user == null) {
      return user;
    }
    else {
      return {
        key: user.key,
        name: user.name,
        email: user.email,
      }
    }
  }
}