import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tabs } from 'src/app/data/tabs';
import { IProjectCategory } from 'src/app/models/project-category.model';
import { DeleteConfirmationComponent } from 'src/app/shared/backend/delete-confirmation/delete-confirmation.component';
import { ProjectCategoryStore } from 'src/app/stores/project-category.store';
import { AddCategoryComponent } from './add-category/add-category.component';
import { ProjectCategoryDetailComponent } from './project-category-detail/project-category-detail.component';

@Component({
  selector: 'app-project-category',
  templateUrl: './project-category.component.html',
  styleUrls: ['./project-category.component.scss']
})
export class ProjectCategoryComponent implements OnInit {
  TABS: Array<any> = tabs.project;
  loading : boolean =false;
  constructor(
    private dialog: MatDialog,
    public projectCategoryStore : ProjectCategoryStore,

  ) { }

  ngOnInit(): void {
    this.projectCategoryStore.fetchData(null,null,null,res=>{
      this.loading=false;
    });
  }

  create(){
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      width: '600px',
      height: '96vh',
    });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
  }
  edit(data:any){
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      width: '600px',
      height: '96vh',
      data:data,
    });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
  }
  deleteService(data:any){
    const dialogRef = this.dialog.open(DeleteConfirmationComponent)
    dialogRef.afterClosed().subscribe(res=>{
      if(res)
        this.projectCategoryStore.deleteCategory(data)
    })

  }

  disable(data:any){
    var v: IProjectCategory;
    if(data.status==='Active'){
      v={
        ...data,
        status:'Disable'
      }
    }else{
      v={
        ...data,
        status:'Active'
      }
    }
    this.projectCategoryStore.updateCategoryStatus(v);
  }

  rowClick(data:any){
    const dialogRef=this.dialog.open(ProjectCategoryDetailComponent,{
      width:'600px',
      height:'96vh',
      data:{...data},
    })
    dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
  }
  async scrollHandler(e, size: number) {
    if (size < 20) return;
    if (e.endIndex === size - 1) {
      await this.projectCategoryStore.fetchDataMore(res => {
        this.loading = false;
      })
    }
  }

  trackByIdx(i: any) {
    return i;
  }
}

