export const tabs = {
  homepage: [
    // { path: '/admin/homepage/information', label: 'Information' },
    { path: '/admin/homepage/slideshow', label: 'Slideshow' },
    // { path: '/admin/homepage/business-partner', label: 'Business Partners' },
    // { path: '/admin/homepage/testimonials', label: 'Testimonial' },
  ],
  aboutus: [
    { path: '/admin/aboutus/management-team', label: 'Management Teams' },
    { path: '/admin/aboutus/mission-vision', label: 'Mission & Vision'},
    { path: '/admin/aboutus/company-history', label: 'Company History'},
    { path: '/admin/aboutus/testimonials', label: 'Testimonial' },

  ],
  user: [
    { path: '/admin/users', label: 'Users' },
  ],
  service:[
    { path: '/admin/services/service', label: 'Services' },
    { path: '/admin/services/business-partner', label: 'Business Partners' },

  ],
  news:[
    { path: '/admin/news/data', label: 'News' },
    { path: '/admin/news/category', label:'Categorys'}
  ],
  project:[
    { path: '/admin/projects/data', label: 'Projects' },
    // { path: '/admin/projects/category', label: 'Categorys' },
  ],
  inbox:[
    { path: '/admin/inbox', label: 'Inbox' },
  ],
  // slideshow:[
  //   { path: '/admin/slideshow', label: 'Slideshow' },
  // ],
  // information:[
  //   { path: '/admin/information', label: 'Information' },
  // ],
  pagesettings:[
    { path: '/admin/pagesettings/information', label: 'Informations' },
    // { path: '/admin/pagesettings/settings',label:'Setting'},
    { path: '/admin/pagesettings/settings',label:'Settings'},
    { path:'/admin/pagesettings/menu' ,label : 'Menu Header' }
    // { path: '/admin/pagesettings/about-us',label:'About us'}
  ]
  // businessPartner:[
  //   { path: '/admin/business-partner', label: 'Business Partner' },
  // ],
  // managementTeam:[
  //   { path: '/admin/management-team', label: 'Management Team' },
  // ],
  // testimonial:[
  //   { path: '/admin/testimonial',label:'Testimonial'},
  // ]
}
