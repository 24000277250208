<!-- <div>
  <div class="invisibleContainer"></div>
  <div [style.height]="'auto'">
    <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="2000" color="accent" maxWidth="auto"
      proportion="30" slides="5" [loop]="true" [hideArrows]="true" [hideIndicators]="true" [useKeyboard]="true"
      [useMouseWheel]="false" orientation="ltr">
      <mat-carousel-slide #matCarouselSlide *ngFor="let slide of this.arrImgs; let i = index" [image]="slide"
        [hideOverlay]="true">
        <div
          style="width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center">
          <h1>slide {{ i+1 }}</h1>
          <p>toggle : {{ matCarouselSlide.disabled }}</p>
          <button mat-flat-button (click)="matCarouselSlide.disabled = !matCarouselSlide.disabled">
            Click me!
          </button>
        </div>
      </mat-carousel-slide>
    </mat-carousel>
  </div>
</div> -->

<div class="swiperContainer">
  <swiper [config]="config">
    <div class="imageContainer" *ngFor="let item of this.slideShowStore.slideShowsNoDisable">
      <img [defaultImage]="'./assets/images/circle-loading.gif'" [lazyLoad]="item.image.downloadURL" alt="IMG">
    </div>
  </swiper>
</div>
