import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUser } from 'src/app/models/user.model'
import { UserStore } from "src/app/stores/user.store"
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {

  addUserForm:any;
  minDate: Date;
  maxDate: Date=new Date();

  constructor(
    private formBuilder:FormBuilder,
    private userStore:UserStore,
    private _snackBar: MatSnackBar,


    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ){
      this.addUserForm=this.formBuilder.group({
        name:new FormControl('', [
              Validators.required,
              Validators.minLength(4)
            ]),
        email:new FormControl('',[
              Validators.required,
              Validators.email
              // Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
            ]),
        pw:new FormControl('',[
              Validators.required,
              Validators.minLength(8)
            ]),
        conpw:new FormControl('',[
              Validators.required,
            ]),
        dob:['',Validators.required],
        isFirstLog : new FormControl(false)
      },{
        validator: ConfirmedValidator('pw', 'conpw')
      });
    }

  ngOnInit(): void {
    this.minDate=new Date("1-1-1950");
  }

  onSubmit(formData:any):void{
    let user = new IUser;
    const {name,email,pw,dob,isFirstLog} = formData;
    user={
      ...user,
      name,
      email,
      dob,
      isFirstLog
    }
    this.userStore.registerUser(user,pw);
    this._snackBar.open("Add new user successfully.","Close",{ duration: 5000 })
  }
}
export function ConfirmedValidator(controlName: string, matchingControlName: string){
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
