import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tabs } from 'src/app/data/tabs';
import { INews } from 'src/app/models/news.model';
import { DeleteConfirmationComponent } from 'src/app/shared/backend/delete-confirmation/delete-confirmation.component';
import { NewsStore } from 'src/app/stores/news.store';
import { AddNewsComponent } from './add-news/add-news.component';
import { AddNewsCategoryComponent } from './news-category/add-news-category/add-news-category.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';


@Component({
  selector: 'app-news-dashbord',
  templateUrl: './news-dashbord.component.html',
  styleUrls: ['./news-dashbord.component.scss']
})
export class NewsDashbordComponent implements OnInit {

  loading: boolean = false;
  TABS: Array<any> = tabs.news;

  constructor(
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public newsStore: NewsStore
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.newsStore.fetchData(null, null, null, (result) => {
      this.loading = false;
    });
  }

  async create() {
    const dialogRef = this.dialog.open(AddNewsComponent, {
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Add News',
        formData: null
      }
    });

    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });
  }

  edit(data: INews) {
    const dialogRef = this.dialog.open(AddNewsComponent, {
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Update News',
        formData: data
      }
    });

    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }

  delete(data: INews) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.newsStore.deleteNews(data, (isSucess) => {
          if (isSucess) {
            this._snackBar.open('News is deleted successfully.', 'Done',{ duration: 5000 })
          }
        })
      }
    });
  }
  rowClick(data:INews){
    const dialogRef=this.dialog.open(NewsDetailComponent,{
      width:'760px',
      height:'96vh',
      data:{...data},
    });
    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }
  disable(data : INews){
    var v : INews;
    if(data.status==='Active'){
      v={
        ...data,
        status:'Disable'
      }
    }else{
      v={
        ...data,
        status:'Active'
      }
    }
    this.newsStore.updateNewsWithoutCallback(v);
  }
  async scrollHandler(e, size: number) {
    if (size < 10) return;
    if (e.endIndex === size - 1) {
      await this.newsStore.fetchDataMore(res => {
        this.loading = false;
      })
    }
  }
  trackByIdx(i: any) {
    return i;
  }

}
