import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from 'src/app/services/component-service/sidenav.service';
import { AuthStore } from 'src/app/stores/auth.store';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  @ViewChild('sidebar', { static: true }) public sidebar: MatSidenav;

  constructor(
    private sidenavService: SidenavService,
  ) { }

  ngOnInit(): void {
    this.sidenavService.setSidenav(this.sidebar);
  }

}
