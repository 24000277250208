<form [formGroup]="form" (ngSubmit)="formSubmit(form.value)">
  <mat-dialog-content class="mat-typography">
    <div class="dialog-header">
      <h2 mat-dialog-title *ngIf="this.data; else Edit">Update Category</h2>
      <ng-template #Edit>
        <h2 mat-dialog-title >Add Project-Category</h2>
      </ng-template>
      <div class="space1"></div>
      <a mat-button mat-icon-button (click)="dialogRef.close()">
          <mat-icon class="mat-18">clear</mat-icon>
      </a>
    </div>
    <div class="inputsDialogContainer">

      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Category name</mat-label>
        <input matInput placeholder="Enter project name" formControlName="name" >
      </mat-form-field>

      <!-- <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Description</mat-label>
        <input matInput placeholder="Enter project name" formControlName="description" >
      </mat-form-field> -->

      <div class="textArea">
        <mat-form-field appearance="outline" >
          <mat-label>Description</mat-label>
          <textarea matInput rows="10" placeholder="enter news description" formControlName="description"></textarea>
          <!-- <mat-error *ngIf="form.controls['description']?.touched && form.controls['description']?.errors?.required">Description is required.</mat-error> -->
        </mat-form-field>
      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="warn" [mat-dialog-close]="true" cdkFocusInitial type="submit">Submit</button>
  </mat-dialog-actions>
  </form>
