import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BackendLayoutComponent } from './layouts/backend-layout/backend-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { AdminDashboardComponent } from './pages/admin/admin-dashboard/admin-dashboard.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ThreeButtonsHomePageComponent } from './pages/three-buttons-home-page/three-buttons-home-page.component';
import { NewsDetailPageComponent } from './pages/news-detail-page/news-detail-page.component';
import { NewsGridPageComponent } from './pages/news-grid-page/news-grid-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ProjectDetailPageComponent } from './pages/project-detail-page/project-detail-page.component';
import { ProjectGridPageComponent } from './pages/project-grid-page/project-grid-page.component';
import { ServiceDetailPageComponent } from './pages/service-detail-page/service-detail-page.component';
import { ServiceGridPageComponent } from './pages/service-grid-page/service-grid-page.component';
import { AdminLoginComponent } from './pages/auth/admin-login/admin-login.component'
import { AdminGuardGuard } from './guards/admin-guard.guard'
import { UserDashboardComponent } from './pages/admin/user-dashboard/user-dashboard.component';
import { ServiceDashboardComponent } from './pages/admin/service-dashboard/service-dashboard.component';
import { NewsDashbordComponent } from './pages/admin/news-dashbord/news-dashbord.component';
import { NewsCategoryComponent } from './pages/admin/news-dashbord/news-category/news-category.component';
import { ProjectDashbordComponent } from './pages/admin/project-dashbord/project-dashbord.component';
import { ProjectCategoryComponent } from './pages/admin/project-dashbord/project-category/project-category.component';
import { SlideshowDashboardComponent } from './pages/admin/slideshow-dashboard/slideshow-dashboard.component';
import { InformationDashboardComponent } from './pages/admin/information-dashboard/information-dashboard.component';
import { BusinessPartnerDashboardComponent } from './pages/admin/business-partner-dashboard/business-partner-dashboard.component';
import { ManagementTeamDashboardComponent } from './pages/admin/management-team-dashboard/management-team-dashboard.component';
import { TestimonialDashbordComponent } from './pages/admin/testimonial-dashbord/testimonial-dashbord.component';
import { PageSettingDashboardComponent } from './pages/admin/page-setting-dashboard/page-setting-dashboard.component';
import { HomepageSettingDashboardComponent } from './pages/admin/page-setting-dashboard/homepage-setting-dashboard/homepage-setting-dashboard.component';
import { AboutUsSettingDashboardComponent } from './pages/admin/page-setting-dashboard/about-us-setting-dashboard/about-us-setting-dashboard.component';
import { CallbackInboxComponent } from './pages/admin/callback-inbox/callback-inbox.component';
import { MissionVisionComponent } from './pages/admin/mission-vision/mission-vision.component';
import { CompanyHistoryComponent } from './pages/admin/company-history/company-history.component';
import { HeaderMenuDashboardComponent } from './pages/admin/header-menu-dashboard/header-menu-dashboard.component';
import { ChangePasswordFormComponent } from './pages/admin/change-password-form/change-password-form.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'home',
        component: HomePageComponent
      },
      {
        path: '',
        redirectTo:'home',
        pathMatch: 'full'
      },
      // {
      //   path:'login',
      //   component:ThreeButtonsHomePageComponent
      // },
      {
        path: 'about',
        component: AboutUsPageComponent
      },
      {
        path: 'contact',
        component: ContactUsPageComponent
      },
      {
        path: 'news',
        component: NewsGridPageComponent
      },
      {
        path: 'news/:id',
        component: NewsDetailPageComponent
      },
      {
        path: 'projects',
        component: ProjectGridPageComponent
      },
      {
        path: 'projects/:id',
        component: ProjectDetailPageComponent
      },
      // {
      //   path: 'project',
      //   component: ProjectDetailPageComponent
      // },
      {
        path: 'services',
        component: ServiceGridPageComponent
      },
      {
        path: 'services/:id',
        component: ServiceDetailPageComponent
      },
      // {
      //   path: 'service',
      //   component: ServiceDetailPageComponent
      // },
    ]
  },
  {
    path: 'admin/login',
    component: AdminLoginComponent
  },
  {
    path:'admin/change-password',component: ChangePasswordFormComponent
  },
  {
    path: 'admin',
    component: BackendLayoutComponent,
    canActivate: [AdminGuardGuard],
    children: [
      
      {
        path:'homepage/slideshow',
        component:SlideshowDashboardComponent
      },
      {
        path: '',
        redirectTo:'homepage/slideshow',
        pathMatch: 'full'
      },
      {
        path: 'users',
        component: UserDashboardComponent
      },
      {
        path:'services/service',
        component: ServiceDashboardComponent
      },
      {
        path:'services/business-partner',
        component:BusinessPartnerDashboardComponent
      },
      {
        path: 'services',
        redirectTo:'services/service',
        pathMatch: 'full'
      },
      // {
      //   path:'homepage/information',
      //   component:InformationDashboardComponent
      // },

      {
        path: 'homepage',
        redirectTo:'homepage/slideshow',
        pathMatch: 'full'
      },
      // {
      //   path:'homepage/business-partner',
      //   component:BusinessPartnerDashboardComponent
      // },
      // {
      //   path:'homepage/testimonials',
      //   component:TestimonialDashbordComponent
      // },
      {
        path:'aboutus/management-team',
        component:ManagementTeamDashboardComponent
      },
      {
        path:'aboutus/mission-vision',
        component:MissionVisionComponent
      },
      {
        path:'aboutus/company-history',
        component:CompanyHistoryComponent
      },
      {
        path:'aboutus/testimonials',
        component:TestimonialDashbordComponent
      },

      {
        path: 'aboutus',
        redirectTo:'aboutus/management-team',
        pathMatch: 'full'
      },

      {
        path:'aboutus/information',
        component:InformationDashboardComponent
      },
      {
        path:'news/data',
        component: NewsDashbordComponent,
      },
      {
        path: 'news',
        redirectTo:'news/data',
        pathMatch: 'full'
      },
      {
        path:'news/category',
        component:NewsCategoryComponent,
      },
      {
        path:'projects/data',
        component:ProjectDashbordComponent,
      },
      {
        path: 'projects',
        redirectTo:'projects/data',
        pathMatch: 'full'
      },
      {
        path:'projects/category',
        component:ProjectCategoryComponent,
      },
      // {
      //   path:'slideshow',
      //   component:SlideshowDashboardComponent,
      // },
      // {
      //   path:'information',
      //   component:InformationDashboardComponent,
      // },
      // {
      //   path:'business-partner',
      //   component:BusinessPartnerDashboardComponent,
      // },
      // {
      //   path:'management-team',
      //   component:ManagementTeamDashboardComponent,
      // },
      // {
      //   path:'testimonials',
      //   component:TestimonialDashbordComponent,
      // }
      {
        path:'inbox',
        component:CallbackInboxComponent,
      },
      {
        path:'pagesettings/information',
        component:InformationDashboardComponent,
      },
      {
        path: 'pagesettings',
        redirectTo:'pagesettings/information',
        pathMatch: 'full'
      },
      // {
      //   path:'pagesettings/settings',
      //   component:PageSettingDashboardComponent,
      // },

      {
        path:'pagesettings/settings',
        component:HomepageSettingDashboardComponent,
      },
      {
        path:'pagesettings/menu',
        component:HeaderMenuDashboardComponent,
      },
      {
        path:'pagesettings/about-us',
        component:AboutUsSettingDashboardComponent,
      },
    ]
  },
  {
    path: '**',
    component: NotFoundPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
