import { Component, OnInit } from '@angular/core';
import { SlideShowStore } from 'src/app/stores/slideshow.store';

@Component({
  selector: 'app-animated-slideshow',
  templateUrl: './animated-slideshow.component.html',
  styleUrls: ['./animated-slideshow.component.scss']
})
export class AnimatedSlideshowComponent implements OnInit {

  constructor(
    public slideShowStore: SlideShowStore
  ) { }

  ngOnInit(): void {
    // this.slideShowStore.getSlideShows();
  }

}
