import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { action, observable } from "mobx";
import { pushToObject } from "../services/random_functions/random-function.service"
import { log } from "firebase-functions/logger";

@Injectable({providedIn:'root'})
export class ConfigStore{
  @observable public loading:boolean=true;
  @observable public menuConig:Array<any>;

  @observable public about_us:any;
  @observable public services:any;
  @observable public projects:any;
  @observable public news:any;
  @observable public contact_us:any;
  @observable public library:any;


    constructor(
    private afs: AngularFirestore,

    ){

    }
    @action
    updateConfigMenu(id:string,value:any){
      try{
        this.afs.collection('config-menu').doc(id).update({'value':value})
      }catch(e){
        console.log(e);
      }
    }
    @action
    async getConfigMenus(){
      try{
        this.loading=true;
        await this.afs.collection('config-menu',ref=>ref.orderBy('order',"asc")).valueChanges().subscribe((data:any)=>{
          this.menuConig=data;
          console.log('menuConig',this.menuConig)
          for(let item of this.menuConig){
            if(item.key==='about-us-menu'){
              this.about_us=item.value;
            }
            if(item.key==='services-menu'){
              this.services=item.value;
            }
            if(item.key==='projects-menu'){
              this.projects=item.value;
            }
            if(item.key==='news-menu'){
              this.news=item.value
            }
            if(item.key==='contact-us-menu'){
              this.contact_us=item.value;
            }
            if(item.key==='library-menu'){
              this.library=item.value;
            }
          }
          this.loading=false;
        })
      }catch(e){
      }
    }
    // @action
    // fetchConfigMenu(){
    //     this.afs.collection('config-menu',ref=>ref.orderBy('order',"asc")).valueChanges().subscribe((data:any)=>{
    //         this.menuConig = data
    //         console.log('this.menuConig ',this.menuConig );
    //         for(let item of this.menuConig){
    //             if(item.key==='about_us'){
    //               this.about_us=item.value;
    //             }
    //             if(item.key==='services'){
    //               this.services=item.value;
    //             }
    //             if(item.key==='projects'){
    //               this.projects=item.value;
    //             }
    //             if(item.key==='news'){
    //               this.news=item.value
    //             }
    //             if(item.key==='contact_us'){
    //               this.contact_us=item.value;
    //             }
    //             if(item.key==='library'){
    //               this.library=item.value;
    //             }
    //           }
    //     })
    // }
}