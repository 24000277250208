
  <form [formGroup]="form" (ngSubmit)="formSubmit(form.value)">

    <div class="dialog-header">
      <h2 mat-dialog-title>{{this.data.formTitle}}</h2>
      <div class="space1"></div>
      <a mat-button mat-icon-button (click)="dialogRef.close()">
          <mat-icon class="mat-18">clear</mat-icon>
      </a>
    </div>

    <mat-dialog-content class="mat-typography">

      <div class="inputsDialogContainer">

        <p>
          <mat-form-field appearance="outline">
            <mat-label>Service Title</mat-label>
            <input matInput placeholder="enter service title" formControlName="title">
          </mat-form-field>
        </p>

        <!-- <div class="textArea">
          <mat-form-field appearance="outline" >
            <mat-label>Service description</mat-label>
            <textarea matInput rows="10" placeholder="enter service description" formControlName="description"></textarea>
          </mat-form-field>
        </div> -->
        <div class="inputContainer">
          <quill-editor [modules]="modules" [styles]="{height: '200px'}"
            formControlName="descriptions"></quill-editor>
        </div>
        <p>
          <mat-slide-toggle formControlName="isHome">Display in Homepage?</mat-slide-toggle>
        </p>

        <!-- <div class="rows" style="margin: 12px 0 12px 3px;">
          <button mat-raised-button (click)="imageFiles.click()" type="button">
            Select Service Images
          </button>
        </div> -->

        <div class="uploadFile">
          <p>Upload Images</p>
          <button type="button" class="btnWithSpinner1" (click)="imageFiles.click()">
            <span style="margin-left: 5px;">Select Images</span>
          </button>
        </div>

        <div class="groupButtonSelectedImages">

          <!-- <div class="buttonSelectedImage" *ngFor="let item of arrImgs; let i=index;">
            <button mat-raised-button type="button">
              {{ item | shorten : 30 : '...' }}
            </button>
            <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteImage(item)">
              Delete
            </button>
          </div> -->

          <div class="buttonSelectedImage" *ngFor="let item of uploadedImages; let i=index;">
            <p>{{ item?.name }}</p>
            <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteUploadedImage(item)">
              Delete
            </button>
          </div>

          <div class="buttonSelectedImage" *ngFor="let item of selectedImages; let i=index;">
            <p>{{ item.name }}</p>
            <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteSelectedImage(item)">
              Delete
            </button>
          </div>

        </div>

        <input #imageFiles style="display: none;" type="file" id="imageFiles" accept='image/*' multiple
            (change)="onSelectedImages($event)" />

        <ng-container *ngIf="this.percentage">
          <mat-progress-bar mode="determinate" [value]="this.percentage"></mat-progress-bar>
        </ng-container>

      </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <!-- <button mat-raised-button color="warn" mat-dialog-close>Cancel</button> -->
      <!-- <button *ngIf="!this.data" mat-raised-button color="primary" cdkFocusInitial type="submit">Add</button>
      <button *ngIf="this.data" mat-raised-button color="primary" cdkFocusInitial type="submit">Save</button> -->
      <button type="submit" class="btnWithSpinner">
        <span class="material-icons" *ngIf="!this.serviceStore.isLoading && this.data?.serviceData?.key">save</span>
        <span class="material-icons" *ngIf="!this.serviceStore.isLoading && !this.data?.serviceData?.key">add</span>
        <mat-spinner class="mat-spinner-color" [diameter]="20" *ngIf="this.serviceStore.isLoading"></mat-spinner>
        <span style="margin-left: 10px;" *ngIf="this.data?.serviceData?.key">Save</span>
        <span style="margin-left: 10px;" *ngIf="!this.data?.serviceData?.key">Add</span>
      </button>
    </mat-dialog-actions>
  </form>

<!-- <ng-container *ngIf="this.data.formTitle=='Add Service'">
  <form [formGroup]="form" (ngSubmit)="formSubmit(form.value)">

    <div class="dialog-header">
      <h2 mat-dialog-title>{{this.data.formTitle}}</h2>
      <div class="space1"></div>
      <a mat-button mat-icon-button (click)="dialogRef.close()">
          <mat-icon class="mat-18">clear</mat-icon>
      </a>
    </div>

    <mat-dialog-content class="mat-typography">
      <div class="inputsDialogContainer">
        <p>
          <mat-form-field appearance="outline">
            <mat-label>Service Title</mat-label>
            <input matInput placeholder="enter service title" formControlName="title">
          </mat-form-field>
        </p>

        <div class="textArea">
          <mat-form-field appearance="outline" >
            <mat-label>Service description</mat-label>
            <textarea matInput rows="10" placeholder="enter service description" formControlName="description"></textarea>
          </mat-form-field>
        </div>

        <p>
          <mat-slide-toggle formControlName="isHome">Display in Homepage?</mat-slide-toggle>
        </p>
        <div class="rows" style="margin: 12px 0 12px 3px;">
          <button mat-raised-button color="primary" (click)="imageFiles.click()" type="button">
            Select Service Images
          </button>
        </div>

        <div class="groupButtonSelectedImages">
          <div class="buttonSelectedImage" *ngFor="let item of serviceImges; let i=index;">
            <button mat-raised-button type="button">
              {{ item.name | shorten : 50 : '...' }}
            </button>
            <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteImage(item.name)">
              Delete
            </button>
          </div>
        </div>

        <input #imageFiles style="display: none;" type="file" id="imageFiles" accept='image/*' multiple
            (change)="onSelectedImages($event)" />
        <ng-container *ngIf="this.percentage">
          <mat-progress-bar mode="determinate" [value]="this.percentage"></mat-progress-bar>
        </ng-container>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button color="primary" cdkFocusInitial type="submit">Add</button>
    </mat-dialog-actions>
  </form>
</ng-container> -->
