import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectStore } from 'src/app/stores/project.store';

@Component({
  selector: 'app-project-detail-page',
  templateUrl: './project-detail-page.component.html',
  styleUrls: ['./project-detail-page.component.scss']
})
export class ProjectDetailPageComponent implements OnInit {

  constructor(
    private projectStore : ProjectStore,
    private route : ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params=>{
      var id =params.get('id');
      this.projectStore.getProjectByKey(id);
    })
  }
}
