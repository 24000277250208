import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class RandomFunctionService {

  constructor(
  ) { }

}
export function pushToObject(doc: firebase.firestore.DocumentSnapshot<any>) {
  if (!doc.exists) return null;
  return { ...doc.data(), key: doc.id }
}

export function pushToArray(snapshot: firebase.firestore.QuerySnapshot<any>): any {
  if (snapshot.empty) return [];
  return snapshot.docs.map(m => ({ ...m.data() }));
}
