import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { IProjectCategory } from 'src/app/models/project-category.model';
import { IProject } from 'src/app/models/project.model';
import { ProjectCategoryStore } from 'src/app/stores/project-category.store';
import { ProjectStore } from 'src/app/stores/project.store';

@Component({
  selector: 'app-project-grid-block',
  templateUrl: './project-grid-block.component.html',
  styleUrls: ['./project-grid-block.component.scss']
})
export class ProjectGridBlockComponent implements OnInit {

  lottieConfig;
  anim;
  projectList : Array<IProject>=[];
  Active:string ='allProject';

  constructor(
    public projectStore : ProjectStore,
    public projectCategoryStore : ProjectCategoryStore,
    private router : Router,
  ) {
    this.projectList=this.projectStore.projects;
  }

  ngOnInit(): void {
    this.lottieConfig = {
      path: 'assets/files/54637-shopcash-empty.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true,
    };
  }
  projectClick(data:IProject){
    this.router.navigate(['projects/'+data.key])
    // this.router.navigate(['login'])
  }
  allCategoryClick(){
    this.projectList=this.projectStore.projectsNoDisable;
    this.Active="allProject";
  }
  // categoryClick(data:IProjectCategory){
  //   this.projectList = this.projectStore.projectsNoDisable.filter(e=>e.category===data.name)
  //   this.Active=data.name;
  // }
  handleAnimation(anim: any) {
    this.anim = anim;
  }

  animationCreated(animationItem: AnimationItem): void {
  }
}
