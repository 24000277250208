<section class="bg-0 p-t-92 p-b-60">
    <div class="container">
        <!-- Title section -->
        <div class="flex-col-c-c p-b-50">
            <h3 class="t1-b-1 cl-3 txt-center m-b-11">
                Latest From News
            </h3>

            <div class="size-a-2 bg-3"></div>
        </div>

        <div class="row justify-content-center">
            <div class="col-sm-10 col-md-8 col-lg-4 p-b-40" *ngFor="let i of this.newsStore.newsTop3" (click)=newsClicked(i)>
                <div class="bg-0 h-full display-column shadow" >

                    <!-- <div class="imageThumbnailContainer">
                        <img [defaultImage]="'./assets/images/circle-loading.gif'" [lazyLoad]="i?.images[0]?.downloadURL" alt="IMG" class="hov-img0 of-hidden" />
                    </div> -->

                    <div class="bg-0 p-rl-28 p-t-26 p-b-35">
                        <h4 class="p-b-12 cardTitle">
                          {{i?.title}}
                        </h4>

                        <div class="flex-wr-s-c p-b-9">
                            <div class="p-r-20">
                                <i class="fs-14 cl-7 fa fa-calendar m-r-2 cardText"></i>

                                <span class="t1-s-2 cl-7 cardText">
                                    {{i?.created_at?.seconds * 1000|date:'medium'}}
                                </span>
                            </div>

                            <div class="p-l-20 bo-l-1 bcl-12">
                                <i class="fs-14 cl-7 fa fa-user m-r-2 cardText"></i>

                                <a class="t1-s-2 cl-11 hov-link3 cardText">
                                    {{i?.created_by?.name}}
                                </a>
                            </div>
                        </div>

                        <!-- <p class="t1-s-2 cl-6 p-b-20 cardText">
                            {{i?.description| shorten : 300 : '...'}}
                        </p> -->
                        <p class="t1-s-2 cl-1 p-b-20 cardText" [innerHTML]="i?.descriptions| shorten : 300 : '...'">
                            <!-- {{i?.description| shorten : 300 : '...'}} -->
                        </p>

                        
                    </div>
                    <div style="padding: 20px;">
                        <a class="d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-0 bg-11 hov-btnCustom trans-02" style="color: white;cursor: pointer;">
                            Read More
                        </a>
                    </div>
                </div>
            </div>
<!--
            <div class="col-sm-10 col-md-8 col-lg-4 p-b-40">
                <div class="bg-0 h-full">
                    <a href="news-detail.html" class="hov-img0 of-hidden">
                        <img src="./assets/customization-assets/images/news-02.jpg" alt="IMG">
                    </a>

                    <div class="bg-0 p-rl-28 p-t-26 p-b-35">
                        <h4 class="p-b-12">
                            <a href="news-detail.html" class="t1-m-1 cl-3 hov-link2 trans-02">
                                Construction Investment Plan
                            </a>
                        </h4>

                        <div class="flex-wr-s-c p-b-9">
                            <div class="p-r-20">
                                <i class="fs-14 cl-7 fa fa-calendar m-r-2"></i>

                                <span class="t1-s-2 cl-7">
                                    Nov 22 , 2017
                                </span>
                            </div>

                            <div class="p-l-20 bo-l-1 bcl-12">
                                <i class="fs-14 cl-7 fa fa-user m-r-2"></i>

                                <a href="#" class="t1-s-2 cl-11 hov-link3">
                                    Admin
                                </a>
                            </div>
                        </div>

                        <p class="t1-s-2 cl-6 p-b-20">
                            Lorem ipsum dolor sit amet, consectetur adipis ing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
                        </p>

                        <a href="news-detail.html" class="d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-0 bg-11 hov-btn1 trans-02">
                            Read More
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-sm-10 col-md-8 col-lg-4 p-b-40">
                <div class="bg-0 h-full">
                    <a href="news-detail.html" class="hov-img0 of-hidden">
                        <img src="./assets/customization-assets/images/news-03.jpg" alt="IMG">
                    </a>

                    <div class="bg-0 p-rl-28 p-t-26 p-b-35">
                        <h4 class="p-b-12">
                            <a href="news-detail.html" class="t1-m-1 cl-3 hov-link2 trans-02">
                                System Security Upgrade
                            </a>
                        </h4>

                        <div class="flex-wr-s-c p-b-9">
                            <div class="p-r-20">
                                <i class="fs-14 cl-7 fa fa-calendar m-r-2"></i>

                                <span class="t1-s-2 cl-7">
                                    Nov 30 , 2017
                                </span>
                            </div>

                            <div class="p-l-20 bo-l-1 bcl-12">
                                <i class="fs-14 cl-7 fa fa-user m-r-2"></i>

                                <a href="#" class="t1-s-2 cl-11 hov-link3">
                                    Admin
                                </a>
                            </div>
                        </div>

                        <p class="t1-s-2 cl-6 p-b-20">
                            Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus...
                        </p>

                        <a href="news-detail.html" class="d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-0 bg-11 hov-btn1 trans-02">
                            Read More
                        </a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
