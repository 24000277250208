import { Component, OnInit } from '@angular/core';
import { tabs } from 'src/app/data/tabs';
import { AddHeaderMenuDashboardComponent } from './add-header-menu-dashboard/add-header-menu-dashboard.component';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationComponent } from 'src/app/shared/backend/delete-confirmation/delete-confirmation.component';
import { HeaderMenuStore } from 'src/app/stores/header-menu.store';

@Component({
  selector: 'app-header-menu-dashboard',
  templateUrl: './header-menu-dashboard.component.html',
  styleUrls: ['./header-menu-dashboard.component.scss']
})
export class HeaderMenuDashboardComponent implements OnInit {
  TABS : Array<any> = tabs.pagesettings;
  constructor(
    private dialog : MatDialog,
    public store : HeaderMenuStore
  ) { }

  ngOnInit(): void {
    this.store.fetchHeaderMenu()
  }
  async create() {
    const dialogRef = this.dialog.open(AddHeaderMenuDashboardComponent, {
      width:'760px',
      height:'96vh',
    });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }
  disable(){

  }
  edit(data:any){
    const dialogRef = this.dialog.open(AddHeaderMenuDashboardComponent,{
      width:'760px',
      height:'96vh',
      data:data
    })
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }
  delete(data:any){
    const dialogRef = this.dialog.open(DeleteConfirmationComponent)
    dialogRef.afterClosed().subscribe(res=>{
      if(res)
        this.store.deleteHeaderMenu(data)
    })
  }
}
