<aside class="sidebar">
    <div class="ac-ln-background"></div>
    <div class="sidebar-brand" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">
        <div class="sbar-brand-name">
            <!-- <img src={{settingStore?.company_logo?.value?.downloadURL}} alt="LOGO"> -->
            <img src="../../../../assets/images/CamVin logo.png" alt="LOGO">

        </div>
    </div>
    <div class="sidebar-list-wrapper">

        <div class="list-content">
            <div class="list-item">

                <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/homepage']">
                  <div class="icon">
                      <i class="material-icons">home</i>
                  </div>
                  <div class="item-name">
                      Homepage
                  </div>
                </div>

                <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/aboutus']">
                  <div class="icon">
                      <i class="material-icons">groups</i>
                  </div>
                  <div class="item-name">
                      About us page
                  </div>
                </div>

                <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/services']">
                    <div class="icon">
                        <i class="material-icons">manage_accounts</i>
                    </div>
                    <div class="item-name">
                        Service
                    </div>
                </div>

                <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/news']">
                    <div class="icon">
                        <i class="material-icons">article</i>
                    </div>
                    <div class="item-name">
                        News
                    </div>
                </div>

                <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/projects']">
                    <div class="icon">
                        <i class="material-icons">dashboard</i>
                    </div>
                    <div class="item-name">
                        Project
                    </div>
                </div>

                <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/inbox']">
                  <div class="icon">
                      <i class="material-icons">mail</i>
                  </div>
                  <div class="item-name">
                      Inbox
                  </div>
                  <!-- <div style="margin-left: auto;"><div [ngClass]="{'active-new':inboxStore?.unreadMssg}"></div></div> -->
                </div>

                <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/pagesettings']">
                  <div class="icon">
                      <i class="material-icons">settings</i>
                  </div>
                  <div class="item-name">
                      Page Setting
                  </div>
                </div>

                <!-- <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/slideshow']">
                    <div class="icon">
                        <i class="material-icons">image</i>
                    </div>
                    <div class="item-name">
                        SlideShow
                    </div>
                </div> -->

                <!-- <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/information']">
                    <div class="icon">
                        <i class="material-icons">help_center</i>
                    </div>
                    <div class="item-name">
                        Information
                    </div>
                </div> -->

                <!-- <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/business-partner']">
                    <div class="icon">
                        <i class="material-icons">contact_mail</i>
                    </div>
                    <div class="item-name">
                        Business Partner
                    </div>
                </div>

                <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/testimonials']">
                    <div class="icon">
                        <i class="material-icons">question_answer</i>
                    </div>
                    <div class="item-name">
                        Testimonials
                    </div>
                </div> -->

                <!-- <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/management-team']">
                    <div class="icon">
                        <i class="material-icons">people</i>
                    </div>
                    <div class="item-name">
                        Management Team
                    </div>
                </div> -->
            </div>
        </div>

        <mat-accordion [multi]="true">
            <mat-expansion-panel class="mat-file-accordion"
                [expanded]="expansions.two" (closed)="onClosed(2)" (opened)="onOpened(2)">
                <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px"
                    [class.active]="expansions?.two">
                    <mat-panel-title>
                        User Manangement
                    </mat-panel-title>
                    <mat-panel-description *ngIf="!expansions?.two">
                        Create new users
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="list-content">
                    <div class="list-item">
                        <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/users']">
                            <div class="icon">
                                <i class="material-icons">account_circle</i>
                            </div>
                            <div class="item-name">
                                Users
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>

        </mat-accordion>
    </div>

    <div class="sidebar-footer">
        <div class="signout" (click)="onLogOut()">
            <div class="icon">
                <i class="material-icons"> lock_open </i>
            </div>
            <div class="item-name">
                Sign Out
            </div>
        </div>
        <div class="sidebar-toggle-button" (click)="togglesidebar()">
            <div class="arrow-icon"></div>
        </div>
    </div>
</aside>
