<footer>
    <div class="parallax100 " style="height: 60px;">
        <!-- classs for parallax100 = kit-overlay1 p-t-10 p-b-10 -->
        <div class="container">
            <div class="row justify-content-center justify-content-md-start">
                <div class="col-sm-8 col-md-4 col-lg-4 " style="display: flex;align-items: center;">
                    <div >
                        <!-- <a href="#">
                            <img *ngIf="settingStore?.footer_logo?.value?.downloadURL" class="max-s-full"
                                src={{settingStore?.footer_logo?.value?.downloadURL}} alt="LOGO" width="132px"
                                height="auto">
                        </a> -->
                    </div>

                    <!-- <div>
                        <p class="t1-s-2 cl-13 p-b-17 footer-text">
                            {{this.informationStore?.arrInformation[0]?.company_description}}
                        </p>
                    </div> -->
                </div>

                <div class="col-sm-8 col-md-4 col-lg-8 p-b-20" style="display: flex;align-items: center; padding: 20px;">
                    <div>
                        <!-- p-b-17 -->
                        <!-- <p class="t1-s-2 cl-13  footer-text" style="line-height: 32px;color: #ffffff;">
                            {{this.informationStore?.arrInformation[0]?.company_description}}
                        </p> -->
                    </div>
                </div>

                <!-- <div class="col-sm-8 col-md-4 col-lg-4 p-b-20">
                    <div class="size-h-1 flex-s-e m-b-18">
                        <h4 class="t1-m-3 text-uppercase cl-0">
                            Contact us
                        </h4>
                    </div>

                    <ul>
                        <li class="flex-wr-s-s t1-s-2 cl-13 p-b-9 footer-text">
                            <span class="size-w-3">
                                <i class="fa fa-home" aria-hidden="true"></i>
                            </span>

                            <span class="size-w-4">
                              {{this.informationStore?.arrInformation[0]?.company_address}}
                            </span>
                        </li>

                        <li class="flex-wr-s-s t1-s-2 cl-13 p-b-9 footer-text">
                            <span class="size-w-3">
                                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            </span>

                            <span class="size-w-4">
                              {{this.informationStore?.arrInformation[0]?.company_email}}
                            </span>
                        </li>

                        <li class="flex-wr-s-s t1-s-2 cl-13 p-b-9 footer-text">
                            <span class="size-w-3">
                                <i class="fa fa-phone" aria-hidden="true"></i>
                            </span>

                            <span class="size-w-4">
                              {{this.informationStore?.arrInformation[0]?.company_phone_number}}
                            </span>
                        </li>
                    </ul>
                    <div class="flex-wr-s-c p-t-10">
                      <a href={{this.informationStore?.arrInformation[0]?.company_facebook}} class="flex-c-c size-a-7 borad-50per bg-11 fs-16 cl-0 hov-btn2 trans-02 m-r-10" target="new">
                          <i class="fa fa-facebook"></i>
                      </a>
                      <a href="https://gmail.com" class="flex-c-c size-a-7 borad-50per bg-11 fs-16 cl-0 hov-btn2 trans-02 m-r-10" target="new">
                          <i class="fa fa-google-plus"></i>
                      </a>
                  </div>
                </div> -->
    
                <!-- <div class="col-sm-8 col-md-4 col-lg-3 p-b-20">
                    <div class="size-h-1 flex-s-e m-b-18">
                        <h4 class="t1-m-3 text-uppercase cl-0">
                            Company
                        </h4>
                    </div>

                    <div class="flex-wr-s-s">
                        <ul class="w-50">
                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    Home
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/services']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    Services
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/news']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    News
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/projects']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    Projects
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/about']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    About us
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/contact']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    Contact
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/login']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    Login
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-8 col-md-4 col-lg-3 p-b-20">
                    <div class="size-h-1 flex-s-e m-b-18">
                        <h4 class="t1-m-3 text-uppercase cl-0">
                            Company
                        </h4>
                    </div>

                    <div class="flex-wr-s-s">
                        <ul class="w-50">
                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    Home
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/services']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    Services
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/news']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    News
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/projects']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    Projects
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/about']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    About us
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/contact']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    Contact
                                </a>
                            </li>

                            <li class="kit-list1 p-b-9 ">
                                <a [routerLink]="['/login']" class="t1-s-2 cl-13 hov-link2 trans-02 footer-text">
                                    Login
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <!-- <div class="bg-10">
        <div class="container txt-center p-tb-15">
            <span class="t1-s-2 cl-14">
                Copyright @ 2018 Designed by pencilblue. All rights reserved.
            </span>
        </div>
    </div> -->
</footer>