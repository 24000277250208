<mat-dialog-content class="mat-typography">
  <h2 class="form_title">Update user</h2>
  <form [formGroup]="editUserForm" class="EditUserContainer">
    <div>
      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Name</mat-label>
        <input matInput placeholder="enter name" formControlName="name" required>
        <mat-error *ngIf="editUserForm.get('name').hasError('required')">
          Name is required.
        </mat-error>
        <!-- <mat-error *ngIf="editUserForm.get('name').hasError('minLength')">
          Name can not be less than 4 character.
        </mat-error> -->
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Emil</mat-label>
        <input matInput placeholder="enter email" value="@gmail.com" formControlName="email" required>
        <mat-error *ngIf="editUserForm.get('email').hasError('required')">
          Email is required.
        </mat-error>
        <mat-error *ngIf="editUserForm.get('email').hasError('pattern')">
          Invalid Email.
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Date of birth</mat-label>
        <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" formControlName="dob">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="editUserForm.get('dob').hasError('required')">
          Date of Birth is required.
        </mat-error>
        <mat-error *ngIf="editUserForm.get('dob').hasError('matDatepickerMin')">
          Invalid minimum date.
        </mat-error>
        <mat-error *ngIf="editUserForm.get('dob').hasError('matDatepickerMax')">
          Invalid maximum date.
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <button mat-raised-button color="warn" (click)="changePW(editUserForm.value)" [disabled]="!editUserForm.valid">Change Password</button>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="onSubmit(editUserForm.value)" [disabled]="!editUserForm.valid">Save</button>
</mat-dialog-actions>
