<section class="bg-0 p-t-92 p-b-60">
    <div class="container">
        <!-- Title section -->
        <div class="flex-col-c-c p-b-50">
            <h3 class="t1-b-1 cl-3 txt-center m-b-11">
                Featured Projects
            </h3>

            <div class="size-a-2 bg-3"></div>
        </div>

        <div class="row justify-content-center">
            <div class="col-sm-10 col-md-8 col-lg-4 p-b-40" *ngFor="let item of this.projectStore.projectHomepage">
                <!-- <div [ngStyle]="{'background-image': 'url(\'' + item.images.downloadURL + '\')'}" class="block2 bg-img1">
                  <a (click)="projectClick(item)">
                    <div class="block2-content trans-04" (click)="projectClick(item)">
                      <h4 class="block2-title t1-m-1 cl-1 flex-s-c trans-04 cardTitle">
                        {{item.name}}
                      </h4>

                      <p class="t1-s-2 cl-1 p-b-26 cardText">
                        {{item.description| shorten: 180 : '...'}}
                      </p>

                      <a style="cursor:pointer;" class=" d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-6 bg-0 hov-btnCustom trans-02" href="{{item.link}}" target="new">
                          Read More
                      </a>
                    </div>
                  </a>
                </div> -->
                <div class="block2 shadow">
                    <a (click)="projectClick(item)">
                      <div class="block2 " (click)="projectClick(item)">
                        <div>
                            <h4 class=" t1-m-1 cl-1 p-b-10 p-t-10 flex-s-c cardTitle">
                                {{item.name}}
                              </h4>
        
                              <p class="t1-s-2 cl-1 p-b-10 p-t-10  cardText">
                                {{item.description| shorten: 180 : '...'}}
                              </p>
                        </div>
                        <div>
                            <a style="color:white;cursor:pointer;" class=" bg-11 d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-6 bg-0 hov-btnCustom trans-02"  (click)="projectClick(item)">
                                Read More
                            </a>
                        </div>
                       
                      </div>
                    </a>
                  </div>
            </div>

            <!-- <div class="col-sm-10 col-md-8 col-lg-4 p-b-40">
                <div class="block2 bg-img2" style="background-image: url(./assets/customization-assets/images/project-02.jpg);">
                    <div class="block2-content trans-04">
                        <h4 class="block2-title t1-m-1 cl-0 flex-s-c trans-04">
                            Training Skill
                        </h4>

                        <p class="t1-s-2 cl-13 p-b-26">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, various versions have evolved over the years.
                        </p>

                        <a href="projects-detail-01.html" class="d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-6 bg-0 hov-btn3 trans-02">
                            Read More
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-sm-10 col-md-8 col-lg-4 p-b-40">
                <div class="block2 bg-img2" style="background-image: url(./assets/customization-assets/images/project-03.jpg);">
                    <div class="block2-content trans-04">
                        <h4 class="block2-title t1-m-1 cl-0 flex-s-c trans-04">
                            Business Consulting
                        </h4>

                        <p class="t1-s-2 cl-13 p-b-26">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, various versions have evolved over the years.
                        </p>

                        <a href="projects-detail-01.html" class="d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-6 bg-0 hov-btn3 trans-02">
                            Read More
                        </a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
