import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tabs } from 'src/app/data/tabs';
import { IBusinessPartner } from 'src/app/models/business-partner.model';
import { DeleteConfirmationComponent } from 'src/app/shared/backend/delete-confirmation/delete-confirmation.component';
import { BusinessPartnerStore } from 'src/app/stores/business_partner.store';
import { SettingStore } from 'src/app/stores/setting.store';
import { AddBusinessPartnerComponent } from './add-business-partner/add-business-partner.component';
import { BusinessPartnerDetailComponent } from './business-partner-detail/business-partner-detail.component';

@Component({
  selector: 'app-business-partner-dashboard',
  templateUrl: './business-partner-dashboard.component.html',
  styleUrls: ['./business-partner-dashboard.component.scss']
})
export class BusinessPartnerDashboardComponent implements OnInit {

  TABS: Array<any> = tabs.service;


  constructor(
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public bussinessPartnerStore : BusinessPartnerStore,
    public settingStore : SettingStore,

  ) { }

  ngOnInit(): void {
    this.bussinessPartnerStore.getBusinessPartners();
    this.settingStore.getSetting('business-partner');
  }

  create(){
    const dialogRef = this.dialog.open(AddBusinessPartnerComponent,{
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Add Business Partner',
        formData: null
      }
    });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });
  }

  edit(data: IBusinessPartner) {
    const dialogRef = this.dialog.open(AddBusinessPartnerComponent, {
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Edit Business Partner',
        formData: data
      }
    });

    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }

  delete(data:any){
    const dialogRef = this.dialog.open(DeleteConfirmationComponent,{
      width : '350px',
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.bussinessPartnerStore.deleteBusinessPartner(data,(isSuccess)=>{
          if(isSuccess){
            this._snackBar.open('Business Partner is deleted successfully.', 'Done',{duration:5000})
          }
        });
      }
    })
  }

  rowClick(data:any){
    const dialogRef=this.dialog.open(BusinessPartnerDetailComponent,{
      width:'760px',
      height:'96vh',
      data:{...data},
    })
    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }

  disable(data:IBusinessPartner){
    var v:IBusinessPartner;
    if(data.status==='Active'){
      v={
        ...data,
        status:'Disable'
      }
    }else{
      v={
        ...data,
        status:'Active'
      }
    }
    this.bussinessPartnerStore.updateBusinessPartnerWithoutCallback(v);
  }

  toggleDisplay(data:any){
    this.settingStore.updateSetting('business-partner',data?.checked)
  }
}
