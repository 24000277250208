<div style="width: 100%;">
  <app-admin-command-bar [tabData]="TABS" [name]="'Project'" [buttonText]="'Add Project'" (onPress)="create()"></app-admin-command-bar>

  <!-- <div class="tableContainer">
      <table class="table table-hover">
          <thead>
              <tr>
                  <th scope="col" >No</th>
                  <th scope="col" >Project Name</th>
                  <th scope="col" >Category</th>
                  <th scope="col" >Show in Homepage</th>
                  <th scope="col" >Description</th>
                  <th scope="col" >Partner</th>
                  <th scope="col" >Client</th>
                  <th scope="col" >Action</th>
              </tr>
          </thead>
          <tbody>
              <ng-container>
                  <tr *ngFor=" let item of this.projectStore.projects;let i=index;">
                      <th scope="row" (click)=rowClick(item)>{{i+1}}</th>
                      <td (click)=rowClick(item)>{{item?.name}}</td>
                      <td (click)=rowClick(item)>{{item?.category}}</td>
                      <td (click)=rowClick(item)>{{item?.isHomepage}}</td>
                      <td (click)=rowClick(item)>{{item?.description | shorten : 50 : '...' }}</td>
                      <td (click)=rowClick(item)>{{item?.partners}}</td>
                      <td (click)=rowClick(item)>{{item?.client.name}}</td>
                      <td>
                        <div class="actionButtonsContainer">
                            <i class="fas fa-pencil-alt" style="margin-right: 20px;" (click)=edit(item)></i>
                            <i class="fas fa-trash-alt" (click)=deleteProject(item)></i>
                        </div>
                      </td>
                  </tr>

                  <tr *ngIf="this.projectStore.projects?.length === 0">
                    <td colspan="5" style="text-align: center;">No Project.</td>
                  </tr>
              </ng-container>
          </tbody>
      </table>
  </div> -->

  <ng-container *ngIf="!projectStore.loading && projectStore?.data && projectStore.infinite | async as items">
    <virtual-scroller #scroll [items]="items"  [checkResizeInterval]="0" style="height: calc(100vh - 175px);"
    (vsEnd)="scrollHandler($event, scroll?.cachedItemsLength)">

    <div class="tableContainer">
      <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col" width="5%">No</th>
                <th scope="col" width="78%">Project Name</th>
                <!-- <th scope="col" width="15%">Category</th> -->
                <!-- <th scope="col" width="30%">Description</th> -->
                <!-- <th scope="col" >Partner</th>
                <th scope="col" >Client</th> -->
                <th scope="col" width="15%">Display in Homepage</th>
                <th scope="col" width="1%">Status</th>
                <th scope="col" width="1%">Action</th>
            </tr>
        </thead>
        <tbody #container>
          <ng-container>
            <tr *ngFor="let item of scroll.viewPortItems; let i=index; trackBy:trackByIdx">
              <th scope="row" (click)=rowClick(item)>{{item?.rowIndex}}</th>
              <td (click)=rowClick(item)>{{item?.name}}</td>
              <!-- <td (click)=rowClick(item)>{{item?.category}}</td> -->
              <!-- <td (click)=rowClick(item)>{{item?.description | shorten : 250 : '...' }}</td> -->
              <!-- <td (click)=rowClick(item)>{{item?.partners}}</td>
              <td (click)=rowClick(item)>{{item?.client.name}}</td> -->
              <td (click)=rowClick(item)><span *ngIf="item?.isHomepage" class="material-icons">check</span></td>
              <td (click)=rowClick(item) [ngStyle]="{'color':(item?.status==='Active')?'blue':'red'}">{{item?.status}}</td>
              <td>
                <div class="actionButtonsContainer container">
                  <div class="row">
                    <div style="margin-right: 10px;">
                      <mat-icon aria-label="disable" (click)="disable(item)">hide_source</mat-icon>
                    </div>
                    <div  style="margin-right: 10px;"  >
                      <!-- <i class="fas fa-pencil-alt" (click)=edit(item)></i> -->
                      <mat-icon aria-label="edit" (click)=edit(item)>mode_edit_outline</mat-icon>

                    </div>
                    <div>
                      <!-- <i class="fas fa-trash-alt" (click)=deleteProject(item)></i> -->
                      <mat-icon aria-label="delete" (click)=deleteProject(item)>delete_outline</mat-icon>

                    </div>
                  </div>
                </div>
              </td>
          </tr>

          <tr *ngIf="this.projectStore.projects?.length === 0">
            <td colspan="5" style="text-align: center;">No Project.</td>
          </tr>

          </ng-container>
        </tbody>
      </table>
    </div>
  </virtual-scroller>
  </ng-container>

</div>
