<div style="width: 100%;">
  <app-admin-command-bar [tabData]="TABS" [name]="'Service Management'" [buttonText]="'Add Service'" (onPress)="create()"></app-admin-command-bar>

  <!-- <div class="tableContainer">
      <table class="table table-hover">
          <thead>
              <tr>
                  <th scope="col" >No</th>
                  <th scope="col" >Title</th>
                  <th scope="col" >Description</th>
                  <th scope="col" >Show in Home Page</th>
                  <th scope="col" >Action</th>
              </tr>
          </thead>
          <tbody>
              <ng-container>
                  <tr *ngFor=" let item of this.serviceStore.Services;let i=index;">
                      <th scope="row" (click)=rowClick(item)>{{i+1}}</th>
                      <td (click)=rowClick(item)>{{item?.title}}</td>
                      <td (click)=rowClick(item)>{{item?.description| shorten : 50 : '...' }}</td>
                      <td (click)=rowClick(item)>{{item?.isHomePage}}</td>
                      <td>
                        <div class="actionButtonsContainer">
                            <i class="fas fa-pencil-alt" style="margin-right: 20px;" (click)=edit(item)></i>
                            <i class="fas fa-trash-alt" (click)=deleteService(item)></i>
                        </div>
                      </td>
                  </tr>

                  <tr *ngIf="this.serviceStore.Services?.length === 0">
                    <td colspan="5" style="text-align: center;">No Services.</td>
                  </tr>
              </ng-container>
          </tbody>
      </table>
  </div> -->

  <ng-container *ngIf="!serviceStore.loading && serviceStore?.data && serviceStore.infinite | async as items">
    <virtual-scroller #scroll [items]="items"  [checkResizeInterval]="0" style="height: calc(100vh - 175px);"
    (vsEnd)="scrollHandler($event, scroll?.cachedItemsLength)">

      <div class="tableContainer">
        <table class="table table-hover">
          <thead>
              <tr>
                <th scope="col" width="1%" >No</th>
                <th scope="col" width="82%">Title</th>
                <!-- <th scope="col" >Description</th> -->
                <th scope="col" width="15%" >Display in Homepage</th>
                <th scope="col" width="1%" >Status</th>
                <th scope="col" width="1%">Action</th>
            </tr>
          </thead>
          <tbody #container>
            <ng-container>
              <tr *ngFor="let item of scroll.viewPortItems; let i=index; trackBy:trackByIdx">
                <th scope="row" (click)=rowClick(item)>{{item?.rowIndex}}</th>
                <td (click)=rowClick(item)>{{item?.title}}</td>
                <!-- <td (click)=rowClick(item)>{{item?.description| shorten : 50 : '...' }}</td> -->
                <td (click)=rowClick(item)><span *ngIf="item?.isHomePage" class="material-icons">check</span></td>
                <td (click)=rowClick(item) [ngStyle]="{'color':(item?.status==='Active')?'blue':'red'}">{{item?.status}}</td>
                <td>
                  <div class="actionButtonsContainer container">
                    <div class="row">
                      <div style="margin-right: 10px;">
                        <mat-icon aria-label="disable" (click)="disable(item)">hide_source</mat-icon>
                      </div>
                      <div style="margin-right: 10px;" >
                        <!-- <i class="fas fa-pencil-alt" (click)=edit(item)></i> -->
                        <mat-icon aria-label="edit" (click)=edit(item)>mode_edit_outline</mat-icon>
                      </div>
                      <div>
                        <!-- <i class="fas fa-trash-alt" (click)=deleteService(item)></i> -->
                        <mat-icon aria-label="delete" (click)=deleteService(item)>delete_outline</mat-icon>

                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr *ngIf="this.serviceStore.Services?.length === 0">
                <td colspan="5" style="text-align: center;">No Services.</td>
              </tr>
            </ng-container>
        </table>
      </div>
    </virtual-scroller>
  </ng-container>
</div>
