<div class="FormDetailContainer">
  <div class="dialog-header">
    <h2 mat-dialog-title>Project Detail</h2>
    <div class="space1"></div>
    <a mat-button mat-icon-button (click)="dialogRef.close()">
        <mat-icon class="mat-18">clear</mat-icon>
    </a>
  </div>
  <table>
    <tbody>
      <tr>
        <td class="meta" style="width: 150px;">
          <h3>Project Name  </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.name}}</h3>
        </td>
      </tr>
      <!-- <tr>
        <td class="meta">
          <h3>Category </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.category}}</h3>
        </td>
      </tr> -->
      <tr>
        <td class="meta">
          <h3>Partner </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.partners}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Created by </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.created_by?.name}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Created at </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.created_at?.seconds*1000|date:'medium'}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Updated by </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.updated_by?.name}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Updated at </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.updated_at?.seconds*1000|date:'medium'}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Display in Homepage  </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.isHomepage}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Status </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.status}}</h3>
        </td>
      </tr>

      <tr>
        <td class="meta">
          <h3>Description :</h3>
        </td>
        <td class="data">
        </td>
      </tr>

      <tr>
        <td colspan="10">
          <p>{{this.data?.description}}</p>
        </td>
        <td class="data">
        </td>
      </tr>

      <tr>
        <td class="meta">
          <h3>Client Information </h3>
        </td>
      </tr>
      <tr>
        <td class="meta" style="padding-left: 10px;">
          <h3>Name </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.client?.name}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta" style="padding-left: 10px; ">
          <h3>Website </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.client?.website}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta" style="padding-left: 10px;">
          <h3>Address </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.client?.location}}</h3>
        </td>
      </tr>
      <tr *ngIf="this.data?.client?.socialMedia?.facebook">
        <td style="padding-left: 10px;" class="meta">
          <h3>Facebook </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.client?.socialMedia?.facebook}}</h3>
        </td>
      </tr>

      <tr *ngIf="this.data?.client?.socialMedia?.email">
        <td style="padding-left: 10px;" class="meta">
          <h3>Email </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.client?.socialMedia?.email}}</h3>
        </td>
      </tr>

      <tr *ngIf="this.data?.client?.socialMedia?.instagram">
        <td style="padding-left: 10px;" class="meta">
          <h3>Instagram </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.client?.socialMedia?.instagram}}</h3>
        </td>
      </tr>

      <tr *ngIf="this.data?.client?.socialMedia?.twitter">
        <td style="padding-left: 10px;" class="meta">
          <h3>Twitter </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.client?.socialMedia?.twitter}}</h3>
        </td>
      </tr>

      <tr *ngIf="this.data?.client?.socialMedia?.linkedin">
        <td style="padding-left: 10px;" class="meta">
          <h3>Linkedin </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.client?.socialMedia?.linkedin}}</h3>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- <h1 mat-dialog-title>Project Detail</h1>
  <h3>Project Name : {{this.data.name}}</h3>
  <h3>Category : {{this.data.category}}</h3>
  <h3>Partner : {{this.data.partners}}</h3>
  <h3>Created by : {{this.data.created_by.name}}</h3>
  <h3>Created at : {{this.data.created_at.seconds*1000|date:'medium'}}</h3>
  <h3>Description :</h3>
  <p>{{this.data.description}}</p>
  <h3>Display in Homepage : {{this.data.isHomepage}}</h3>
  <h3>Status : {{this.data.status}}</h3>
  <h3>Client Information : </h3>
  <h3>Client Name : {{this.data.client.name}}</h3>
  <h3>Website : {{this.data?.client?.website}}</h3>
  <h3>Address : {{this.data.client.location}}</h3>
  <h3 *ngIf="this.data.client.socialMedia.email">Email : {{this.data.client.socialMedia.email}}</h3>
  <h3 *ngIf="this.data.client.socialMedia.facebook">Facebook : {{this.data.client.socialMedia.facebook}}</h3>
  <h3 *ngIf="this.data.client.socialMedia.twitter">Twitter : {{this.data.client.socialMedia.twitter}}</h3>
  <h3 *ngIf="this.data.client.socialMedia.instagram">Instagram : {{this.data.client.socialMedia.instagram}}</h3>
  <h3 *ngIf="this.data.client.socialMedia.linkin">Linkin : {{this.data.client.socialMedia.linkin}}</h3> -->
  <h3>Image :</h3>
  <mat-grid-list cols="4" rowHeight="150px">
    <!-- <mat-grid-tile *ngFor="let img of this.data.images">
      <a href={{img.imageDownloadURL}} target="new">
        <img style="width: 100%;height: 100%;object-fit: cover;" src={{img.imageDownloadURL}}>
      </a>
    </mat-grid-tile> -->
    <mat-grid-tile >
      <a href={{this.data.images.downloadURL}} target="new">
        <img style="width: 100%;height: 100%;object-fit: cover;" src={{this.data.images.downloadURL}}>
      </a>
    </mat-grid-tile>
  </mat-grid-list>
  <div mat-dialog-actions style="top:95vh">
    <button mat-flat-button color="warn" mat-dialog-close >Close</button>
  </div>
