import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, of, Subscription } from "rxjs";
import { distinct } from "rxjs/operators";

import { IService } from "../models/service.model";
import { UserMappingService } from "../services/mapping/user-mapping.service";
import { AuthStore } from "./auth.store";

@Injectable({providedIn:'root'})
export class ServiceStore {

  @observable public isLoading: boolean = false;
  @observable public Services: Array<IService>;
  @observable public ServicesHomepage: Array<IService>;
  @observable public ServicesNoDisable: Array<IService>;
  @observable public Service: IService;

  constructor(
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private userMapping: UserMappingService,
    private authStore: AuthStore,
  ) { }

  @action
  getService(){
    try{
      this.isLoading=true;
      this.afs.collection('services',ref=>ref.where('isDelete','==',false).orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.Services=data;
        this.isLoading=false;
      });
    }
    catch(e){
      console.log(e)
    }
  }

  @action
  getServiceNoDisable(){
    try{
      this.isLoading=true;
      this.afs.collection('services',ref=>ref.where('isDelete','==',false).where('status','==','Active').orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.ServicesNoDisable=data;
        this.isLoading=false;
      });
    }
    catch(e){
      console.log(e)
    }
  }

  @action
  getServicesLimit(number: number){
    try{
      this.isLoading=true;
      this.afs.collection('services',ref=>ref.where('isDelete','==',false).where('isHomePage','==',true).limit(number)).valueChanges().subscribe((data:any)=>{
        this.Services=data;
        this.isLoading=false;
      });
    }
    catch(e){
      console.log(e)
    }
  }

  @action
  getServicesHomepage(){
    try{
      this.isLoading=true;
      this.afs.collection('services',ref=>ref.where('isDelete','==',false).where('status','==','Active').where('isHomePage','==',true).orderBy('page_key',"desc")).valueChanges().subscribe((data:any)=>{
        this.ServicesHomepage=data;
        this.isLoading=false;
      });
    }
    catch(e){
      console.log(e)
    }
  }

  @action
  getServicesLimitCallback(number: number, callback: any){
    try{
      this.isLoading=true;
      this.afs.collection('services',ref=>ref.where('isDelete','==',false).limit(number)).valueChanges().subscribe((data:any)=>{
        this.Services=data;
        this.isLoading=false;
        callback(data)
      });
    }
    catch(e){
      console.log(e)
    }
  }

  @action
  getServiceByKey(key:any){
    try{
      this.isLoading=true;
      this.afs.collection('services').doc(key).valueChanges().subscribe((data:any)=>{
        this.Service=data;
        this.isLoading=false;
      });
    } catch(e){
      console.log(e)
    }
  }

  @action
  async addService(data: IService){
    try {
      this.isLoading=true;
      await this.afs.collection('services').doc(data.key).set(data).then(() => {
        this.isLoading = false;
      }).catch((error) => {
        console.log(error)
      })

    } catch(e){
      console.log(e);
    }
  }

  @action
  async updateService(data: IService){
    try{
      this.isLoading=true;
      await this.afs.collection('services').doc(data.key).update({
        ...data,
      }).then(()=>{
        this.isLoading=false;
      })
    }catch(e){
      console.log(e);
    }
  }

  @action
  async deleteService(data: IService){
    try{
      await this.afs.collection('services').doc(data.key).update({
        ...data,
        isDelete: true,
        updated_at : new Date(),
        updated_by : this.userMapping.mapUser(this.authStore.User)
      })
    }catch(e){
      console.log(e);
    }
  }



  lastVisible: any = null;
  infinite: Observable<any[]>;

  @observable public orderBy = null;
  @observable public searchText = null;
  @observable public filter = null;

  @observable public loading: boolean = false;
  @observable public fetching: boolean = false;
  @observable public process: boolean = false;

  @observable data: any = [];
  @observable fetchListingRef: Subscription;

  infiniteData(data: any) {
    this.infinite = of(data).pipe(distinct((p:any) => p.key));
  }

  async fetchData(search: any, filter: any, orderBy: any, callback: (arg0: any) => void) {
    this.orderBy = orderBy;
    this.searchText = search;
    this.filter = filter;
    this.loading = true;
    this.fetching = false;
    this.lastVisible = null;

    const ref = this.lazyDataRef(this.lastVisible, search, filter, orderBy);

    this.fetchListingRef = ref.snapshotChanges().subscribe((response: any) => {
      this.data = [];

      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        callback(this.data)
        return false;
      }

      this.lastVisible = response[response.length - 1].payload.doc;

      for (let item of response) {
        this.data.push(item.payload.doc.data() as any);
      }

      this.data = this.data.map((f: any, index: number) => ({
        ...f,
        rowIndex: index + 1
      }))

      this.infiniteData(this.data);
      this.loading = false
      this.fetching = false;
      callback(this.data);
      return true;
    }, (error: any) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  @action
  async fetchDataMore(callback: (arg0: any) => void) {
    this.fetching = true;

    this.lazyDataRef(this.lastVisible, this.searchText, this.filter, this.orderBy).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }

      this.lastVisible = response.docs[response.docs.length - 1];

      for (let item of response.docs) {
        this.data.push(item.data() as any);
      }

      this.data = this.data.map((f: any, index: number) => ({
        ...f,
        rowIndex: index + 1
      }))

      this.fetching = false;
      this.infiniteData(this.data);

      callback(this.data);
      return;
    }, (error: any) => {
      this.fetching = false;
    });
  }

  lazyDataRef(lastVisible: any, search: any, filter: any, orderBy: any) {
    return this.afs.collection<any>("services", ref => {

      let condition = ref.where('isDelete','==',false).orderBy('page_key')

      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }

      return condition;
    })

  }

}
