<ng-container class="dialog-content-wrapper">

    <form (ngSubmit)="formSubmit()">

      <div class="dialog-header">
        <h2 mat-dialog-title>{{this.data.formTitle}}</h2>
        <div class="space1"></div>
        <a mat-button mat-icon-button (click)="dialogRef.close()">
            <mat-icon class="mat-18">clear</mat-icon>
        </a>
      </div>

      <mat-dialog-content class="mat-typography">

        <div *ngIf="!this.data?.formData?.key" class="uploadFile" style="margin-bottom: 20px;">
          <p>Upload Images</p>
          <!-- <button mat-raised-button color="primary" (click)="imageFiles.click()" type="button">
            Select Images
          </button> -->

          <button type="button" class="btnWithSpinner1" (click)="imageFiles.click()">
            <span style="margin-left: 5px;">Select Images</span>
          </button>
        </div>

        <mat-form-field appearance="outline" *ngIf="this.data?.formData?.key">
          <mat-label>Order</mat-label>
          <mat-select [(value)]="selectVal" placeholder="selectVal">
            <mat-option *ngFor="item of this.slideShowStore.slideShows ;let i=index;" value={{i+1}}>{{i+1}}</mat-option>
          </mat-select>
        </mat-form-field>
        <input #imageFiles style="display: none;" type="file" id="imageFiles" accept='image/*'
            (change)="onSelectedImage($event)" />

        <div class="groupButtonSelectedImages" *ngIf="this.uploadedImage || this.selectedImage" style="margin: 0px;">
          <!-- <div class="buttonSelectedImage" *ngIf="this.uploadedImage">
            <p>{{ uploadedImage?.name }}</p>
            <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteUploadedImage(item)">
              Delete
            </button>
          </div> -->

          <div class="buttonSelectedImage" *ngIf="this.selectedImage">
            <p>{{ selectedImage?.name }}</p>
            <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteSelectedImage(item)">
              Delete
            </button>
          </div>
        </div>

        <ng-container *ngIf="this.percentage">
          <mat-progress-bar mode="determinate" [value]="this.percentage"></mat-progress-bar>
        </ng-container>

      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <!-- <button class="btnCancel" mat-dialog-close>
          <span class="material-icons">close</span>
          <span style="margin-left: 10px;">Close</span>
        </button> -->

        <button type="submit" class="btnWithSpinner">
          <span class="material-icons" *ngIf="!this.newsStore.isLoading && this.data?.formData?.key">save</span>
          <span class="material-icons" *ngIf="!this.newsStore.isLoading && !this.data?.formData?.key">add</span>
          <mat-spinner class="mat-spinner-color" [diameter]="20" *ngIf="this.newsStore.isLoading"></mat-spinner>
          <span style="margin-left: 10px;" *ngIf="this.data?.formData?.key">Save</span>
          <span style="margin-left: 10px;" *ngIf="!this.data?.formData?.key">Add SlideShow</span>
        </button>
      </mat-dialog-actions>

    </form>

  </ng-container>

