import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { MatSnackBar } from "@angular/material/snack-bar";

import { UserMappingService } from "../services/mapping/user-mapping.service";
import { AuthStore } from "./auth.store";
import { ITestimonial } from "../models/testimonial.model";


@Injectable({providedIn:'root'})
export class TestimonialStore {

  @observable public isLoading: boolean = false;
  @observable public testimonials : Array<ITestimonial> = [];
  @observable public testimonialsNoDisable : Array<ITestimonial> = [];

  constructor(
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private userMapping: UserMappingService,
    private authStore: AuthStore,
  ) { }

  @action
  getTestimonial() {
    try{
      this.isLoading=true;
      this.afs.collection('testimonial', ref => ref.where('isDelete','==',false).orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.testimonials = data;
        this.isLoading = false;
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  getTestimonialNoDisable() {
    try{
      this.isLoading=true;
      this.afs.collection('testimonial', ref => ref.where('isDelete','==',false).where('status','==','Active').orderBy("page_key","desc")).valueChanges().subscribe((data:any)=>{
        this.testimonialsNoDisable = data;
        this.isLoading = false;
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  async addTestimonial(testimonial: ITestimonial, callback: (isSuccess: boolean) => void){
    try{
      this.isLoading = true;

      await this.afs.collection('testimonial').doc(testimonial.key).set(testimonial).then(()=>{
        this.isLoading = false;
        callback(true);
      }).catch((e)=>{
        console.log(e);
        this.isLoading = false;
        callback(false);
      });
    }catch(e){
      console.log(e)
    }
  }

  @action
  async updateTestimonial(testimonial: ITestimonial, callback: (isSuccess: boolean) => void){
    try{
      this.isLoading = true;

      await this.afs.collection('testimonial').doc(testimonial.key).update({
        ...testimonial,
      }).then(() => {
        this.isLoading = false;
        callback(true);
      }).catch((e) => {
        this.isLoading = false;
        callback(false);
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  async updateTestimonialWithoutCallback(testimonial: ITestimonial){
    this.afs.collection('testimonial').doc(testimonial.key).update(testimonial);
  }

  @action
  async deleteTestimonial(testimonial: ITestimonial, callback: (isSuccess: boolean) => void){
    try {
      this.isLoading = true;

      await this.afs.collection('testimonial').doc(testimonial.key).update({
        ...testimonial,
        isDelete: true,
        updated_at: new Date(),
        updated_by: this.userMapping.mapUser(this.authStore.User)
      });

      this.isLoading = false;
      callback(true);
    }catch(e){
      console.log(e);
      this.isLoading = false;
      callback(false);
    }
  }
}
