import { Component, OnInit } from '@angular/core';
import { BusinessPartnerStore } from 'src/app/stores/business_partner.store';
import { TestimonialStore } from 'src/app/stores/testimonial.store';

@Component({
  selector: 'app-service-grid-page',
  templateUrl: './service-grid-page.component.html',
  styleUrls: ['./service-grid-page.component.scss']
})
export class ServiceGridPageComponent implements OnInit {

  constructor(
    private testimonialStore : TestimonialStore,
    private businessPartnerStore : BusinessPartnerStore,
  ) { }

  ngOnInit(): void {
    this.testimonialStore.getTestimonialNoDisable();
    this.businessPartnerStore.getBusinessPartnersNoDisable();
  }

}
