<div class="FormDetailContainer">
  <div class="dialog-header">
    <h2 mat-dialog-title>News Category Detail</h2>
    <div class="space1"></div>
    <a mat-button mat-icon-button (click)="dialogRef.close()">
        <mat-icon class="mat-18">clear</mat-icon>
    </a>
  </div>
  <table>
    <tbody>
      <tr>
        <td class="meta" style="width: 140px;">
          <h3>Name </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.name}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Created by </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.created_by?.name}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Created at </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.created_at?.seconds*1000|date:'medium'}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Updated by </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.updated_by?.name}}</h3>
        </td>
      </tr>
      <tr>
        <td class="meta">
          <h3>Updated at </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.updated_at?.seconds*1000|date:'medium'}}</h3>
        </td>
      </tr>

      <tr>
        <td class="meta">
          <h3>Status </h3>
        </td>
        <td class="data">
          <h3>: {{this.data?.status}}</h3>
        </td>
      </tr>

      <tr>
        <td class="meta">
          <h3>Description :</h3>
        </td>
        <td class="data">
        </td>
      </tr>
      <tr>
        <td colspan="10">
          <p>{{this.data?.description}}</p>
        </td>
        <td class="data">
        </td>
      </tr>
    </tbody>
  </table>
  <!-- <h3>Created by : {{this.data?.created_by.name}}</h3>
  <h3>Created at : {{this.data?.created_at.seconds*1000|date:'medium'}}</h3>
  <h3>Description :</h3>
  <p>{{this.data?.description}}</p>
  <h3>Display in Homepage : {{this.data?.isHomePage}}</h3>
  <h3>Status : {{this.data?.status}}</h3> -->
  <!-- <h3>Image :</h3>
  <mat-grid-list cols="4" rowHeight="150px">
    <mat-grid-tile *ngFor="let img of this.data?.images">
      <a href={{img.downloadURL}} target="new">
        <img style="width: 100%;height: 100%;object-fit: cover;" src={{img?.downloadURL}}>
      </a>
    </mat-grid-tile>
  </mat-grid-list> -->
  <div mat-dialog-actions style="top:95vh">
    <button mat-flat-button color="warn" mat-dialog-close>Close</button>
  </div>
</div>
