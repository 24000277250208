import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { MatSnackBar } from "@angular/material/snack-bar";

import { UserMappingService } from "../services/mapping/user-mapping.service";
import { AuthStore } from "./auth.store";
import { ISlideShow } from "../models/slideshow.model";

@Injectable({providedIn:'root'})
export class SlideShowStore {

  @observable public isLoading: boolean = false;
  @observable public display: boolean = true;
  @observable public slideShows: Array<ISlideShow> = [];
  @observable public slideShowsNoDisable: Array<ISlideShow> = [];

  constructor(
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private userMapping: UserMappingService,
    private authStore: AuthStore,
  ) { }

  @action
  async getSlideShows(){
    try{
      this.isLoading=true;
      await  this.afs.collection('slideShows',ref=>ref.where('isDelete','==',false).orderBy('order',"asc")).valueChanges().subscribe((data:any)=>{
        this.slideShows=data;
        this.isLoading = false;
      });
    }catch(e){
      console.log(e);
    }
  }


  @action
  async getSlideShowsNoDisable(){
    try{
      this.isLoading=true;
      await  this.afs.collection('slideShows',ref=>ref.where('isDelete','==',false).where('status','==','Active').orderBy('order',"asc")).valueChanges().subscribe((data:any)=>{
        this.slideShowsNoDisable=data;
        this.isLoading = false;
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  async addSlideShow(slideShow: ISlideShow, callback: (isSuccess: boolean) => void){
    try{
      this.isLoading = true;

      await this.afs.collection('slideShows').doc(slideShow.key).set(slideShow).then(()=>{
        this.isLoading = false;
        callback(true);
      }).catch((e)=>{
        console.log(e);
        this.isLoading = false;
        callback(false);
      });
    }catch(e){
      console.log(e)
    }
  }

  @action
  async updateSlideShow(slideShow: ISlideShow, callback: (isSuccess: boolean) => void){
    try{
      this.isLoading = true;

      await this.afs.collection('slideShows').doc(slideShow.key).update({
        ...slideShow,
      }).then(() => {
        this.isLoading = false;
        callback(true);
      }).catch((e) => {
        this.isLoading = false;
        callback(false);
      });
    }catch(e){
      console.log(e);
    }
  }

  // @action
  // async swapOrder(slideShow1: any,slideShow2:any){
  //   this.isLoading=true;
  //   try{
  //     await this.afs.collection('slideShows').doc(slideShow2.key).update({
  //       order:slideShow1.order
  //     }).then(async ()=>{
  //       await this.afs.collection('slideShows').doc(slideShow1.key).update({
  //         order:slideShow2.order
  //       }).then(()=>{
  //         this.isLoading=false
  //       })
  //     })

  //   }catch(e){
  //     this.isLoading=false
  //   }
  // }

  @action
  changeOrder(from,to,callback){
    try{
      this.isLoading=true;
      let selectedSlideShow=null;
      this.getSlideShows().then(async ()=>{
        let i=0;
        for(let item of this.slideShows){
          i+=1;
          if(i===from){
            selectedSlideShow=item;
          }
          if(from<to){
            if(i>from&&i<=to){
              const data={
                ...item,
                order:i-1,
              }
              await this.updateSlideShowWithoutCallback(data)
            }
          }else if(from>to){
            if(i>=to&&i<=from){
              const data={
                ...item,
                order:i+1,
              }
              await this.updateSlideShowWithoutCallback(data)
            }
          }else{}
        }
        if(selectedSlideShow){
          const data={
            ...selectedSlideShow,
            order:to,
          }
          await this.updateSlideShowWithoutCallback(data)
        }
      })
      callback(true)
      this.isLoading=false;
    }catch(e){
      callback(false)
      console.log(e);
      this.isLoading=false;
    }
  }


  @action
  async updateSlideShowWithoutCallback(slideShow: ISlideShow){
    try{
      this.afs.collection('slideShows').doc(slideShow.key).update(slideShow);
    }catch(e){

    }
  }

  @action
  async deleteSlideShow(slideShow: ISlideShow, callback: (isSuccess: boolean) => void){
    try {
      this.isLoading = true;

      await this.afs.collection('slideShows').doc(slideShow.key).update({
        ...slideShow,
        isDelete: true,
        updated_at: new Date(),
        updated_by: this.userMapping.mapUser(this.authStore.User)
      });

      this.isLoading = false;
      callback(true);
    }catch(e){
      console.log(e);
      this.isLoading = false;
      callback(false);
    }
  }

  @action
  reorder(){
    this.isLoading=true;
    var index=1;
    this.getSlideShows().then(async ()=>{
      for(let item of this.slideShows){
        const data={
          ...item,
          order:index
        }
        await this.updateSlideShowWithoutCallback(data)
        index+=1;
      }
      this.isLoading=false
    })
  }
}
