import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tabs } from 'src/app/data/tabs';
import { IService } from 'src/app/models/service.model';
import { DeleteConfirmationComponent } from 'src/app/shared/backend/delete-confirmation/delete-confirmation.component';
import { ServiceStore } from 'src/app/stores/service.store';
import { AddServiceComponent } from './add-service/add-service.component';
import { ServiceDetailComponent } from '../service-detail/service-detail.component';

@Component({
  selector: 'app-service-dashboard',
  templateUrl: './service-dashboard.component.html',
  styleUrls: ['./service-dashboard.component.scss']
})
export class ServiceDashboardComponent implements OnInit {
  TABS: Array<any> = tabs.service;
  Loading: boolean = false;
  constructor(
    private dialog: MatDialog,
    public serviceStore : ServiceStore,
  ) { }

  ngOnInit(): void {
    this.serviceStore.fetchData(null,null,null,res=>{
      this.Loading=false;
    });
  }

  create(){
    const dialogRef = this.dialog.open(AddServiceComponent,{
      width:'760px',
      height:'96vh',
      data:{formTitle:'Add Service'}
    });

    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });
  }
  edit(serviceData:any){
    const dialogRef = this.dialog.open(AddServiceComponent,{
      width:'760px',
      height:'96vh',
      data:{serviceData,formTitle:'Update Service'}
    });

    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }
  deleteService(data:any){
    const dailogRef = this.dialog.open(DeleteConfirmationComponent,{
    })
    dailogRef.afterClosed().subscribe(res=>{
      if(res)
        this.serviceStore.deleteService(data)
    })
  }

  disable(ServiceData:IService){
    var data : IService;
    if(ServiceData.status==='Active'){
      data ={
        ...ServiceData,
        status:'Disable'
      }
    }else{
      data ={
        ...ServiceData,
        status:'Active'
      }
    }
    this.serviceStore.updateService(data);
  }

  rowClick(data:IService){
    const dialogRef=this.dialog.open(ServiceDetailComponent,{
      width:'760px',
      height:'96vh',
      data:{...data},
    });
    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }
  async scrollHandler(e, size: number) {
    if (size < 20) return;
    if (e.endIndex === size - 1) {
      await this.serviceStore.fetchDataMore(res => {
        this.Loading = false;
      })
    }
  }


  trackByIdx(i: any) {
    return i;
  }
}
