<div style="width: 100%;">
  <app-admin-command-bar [tabData]="TABS" [name]="'Project Category'" [buttonText]="'Add Project Category'"
    (onPress)="create()"></app-admin-command-bar>

  <!-- <div class="tableContainer">
      <table class="table table-hover">
          <thead>
              <tr>
                  <th scope="col" >No</th>
                  <th scope="col" >Name</th>
                  <th scope="col" >Action</th>
              </tr>
          </thead>
          <tbody>
              <ng-container>
                  <tr *ngFor=" let item of this.projectCategoryStore.categorys;let i=index;">
                      <th scope="row" (click)=rowClick(item)>{{i+1}}</th>
                      <td (click)=rowClick(item)>{{item?.name}}</td>
                      <td>
                        <div class="actionButtonsContainer">
                            <i class="fas fa-pencil-alt" style="margin-right: 20px;" (click)=edit(item)></i>
                            <i class="fas fa-trash-alt" (click)=deleteService(item)></i>
                        </div>
                      </td>
                  </tr>

                  <tr *ngIf="this.projectCategoryStore.categorys?.length === 0">
                    <td colspan="5" style="text-align: center;">No Services.</td>
                  </tr>
              </ng-container>
          </tbody>
      </table>
  </div> -->


  <ng-container *ngIf="!projectCategoryStore.loading && projectCategoryStore?.data && projectCategoryStore.infinite | async as items">
    <virtual-scroller #scroll [items]="items" [checkResizeInterval]="0" style="height: calc(100vh - 175px);"
      (vsEnd)="scrollHandler($event, scroll?.cachedItemsLength)">

      <div class="tableContainer">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Name</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody #container>
            <ng-container>
              <tr *ngFor="let item of scroll.viewPortItems; let i=index; trackBy:trackByIdx">
                <th scope="row" (click)=rowClick(item)>{{item?.rowIndex}}</th>
                <td (click)=rowClick(item)>{{item?.name}}</td>
                <td (click)=rowClick(item) [ngStyle]="{'color':(item?.status==='Active')?'blue':'red'}">{{item?.status}}</td>
                <td>
                  <!-- <div class="actionButtonsContainer">
                    <i class="fas fa-pencil-alt" style="margin-right: 20px;" (click)=edit(item)></i>
                    <i class="fas fa-trash-alt" (click)=deleteService(item)></i>
                  </div> -->
                  <div class="actionButtonsContainer container">
                    <div class="row">
                      <div style="margin-right: 10px;">
                        <mat-icon aria-label="disable" (click)="disable(item)">hide_source</mat-icon>
                      </div>
                      <div style="margin-right: 10px;" >
                        <!-- <i class="fas fa-pencil-alt" (click)=edit(item)></i> -->
                        <mat-icon aria-label="edit" (click)=edit(item)>mode_edit_outline</mat-icon>

                      </div>
                      <div>
                        <!-- <i class="fas fa-trash-alt" (click)=deleteService(item)></i> -->
                        <mat-icon aria-label="delete" (click)=deleteService(item)>delete_outline</mat-icon>

                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr *ngIf="this.projectCategoryStore.categorys?.length === 0">
                <td colspan="5" style="text-align: center;">No Services.</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </virtual-scroller>
  </ng-container>
</div>
