<div style="width: 100%;" class="pageContainerForSpinner">
  <app-admin-command-bar [tabData]="TABS" [name]="'Page Settings'" [buttonText]="'Save'">
  </app-admin-command-bar>

  <div class='formContainer'>
  <form [formGroup]="form" (ngSubmit)="formSubmit(form.value)">
    <mat-dialog-content class="mat-typography">
      <div>Company Logo</div>
      <div class="rows" style="margin: 12px 0 12px 3px;">
        <button mat-raised-button (click)="imageFiles.click()" color="custom">
          Select Images
        </button>
      </div>
      <div *ngIf="company_logo?.name" class="groupButtonSelectedImages">
        <div class="buttonSelectedImage">
          <button mat-raised-button type="button">
            {{ company_logo?.name | shorten : 50 : '...' }}
          </button>
          <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteImage(company_logo?.name)">
            Delete
          </button>
        </div>
      </div>
      <input #imageFiles style="display: none;" type="file" id="imageFiles" accept='image/*' single
      (change)="onSelectedImages($event)" />
      <div>Font</div>

      <mat-form-field appearance="outline">
      <mat-label>Color theme</mat-label>
      <input matInput placeholder="" formControlName="color">
    </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <!-- <button mat-raised-button color="warn" mat-dialog-close>Close</button> -->
      <button mat-raised-button color="primary" cdkFocusInitial type="submit">Add</button>
    </mat-dialog-actions>
  </form>
  </div>
</div>
