<div class="FormDetailContainer">
  <div class="dialog-header">
    <h2 mat-dialog-title>Business Partner Detail</h2>
    <div class="space1"></div>
    <a mat-button mat-icon-button (click)="dialogRef.close()">
        <mat-icon class="mat-18">clear</mat-icon>
    </a>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-9">
        <table>
          <tbody>
            <tr>
              <td class="meta" style="width: 140px;">
                <h3>Name </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.name}}</h3>
              </td>
            </tr>
            <tr>
              <td class="meta">
                <h3>Created by </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.created_by?.name}}</h3>
              </td>
            </tr>
            <tr>
              <td class="meta">
                <h3>Created at </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.created_at?.seconds*1000|date:'medium'}}</h3>
              </td>
            </tr>
            <tr>
              <td class="meta">
                <h3>Updated by </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.updated_by?.name}}</h3>
              </td>
            </tr>
            <tr>
              <td class="meta">
                <h3>Updated at </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.updated_at?.seconds*1000|date:'medium'}}</h3>
              </td>
            </tr>

            <tr>
              <td class="meta">
                <h3>Status </h3>
              </td>
              <td class="data">
                <h3>: {{this.data?.status}}</h3>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class="col-3" style="display: flex; flex-direction: row-reverse;">
        <div>
        </div>
        <div style="margin-left:auto;">
          <img class="image--cover" [defaultImage]="'./assets/images/circle-loading.gif'" [lazyLoad]="data?.image?.downloadURL" alt="{{ data?.image?.name }}">
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <h3>Description :</h3>
    <div>
      <p>{{this.data?.description}}</p>
    </div>
  </div>

  <div mat-dialog-actions style="top:95vh">
    <button mat-flat-button color="warn" mat-dialog-close>Close</button>
  </div>
</div>
