<ng-container class="dialog-content-wrapper">

  <form [formGroup]="form" (ngSubmit)="formSubmit(form.value)">

    <div class="dialog-header">
      <h2 mat-dialog-title>{{this.data.formTitle}}</h2>
      <div class="space1"></div>
      <a mat-button mat-icon-button (click)="dialogRef.close()">
          <mat-icon class="mat-18">clear</mat-icon>
      </a>
    </div>

    <mat-dialog-content class="mat-typography">
      <div class="inputsDialogContainer">
        <div class="inputContainer">
          <p>Name</p>
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="enter name" formControlName="name">
            <mat-error *ngIf="form.controls['name']?.touched && form.controls['name']?.errors?.required">Name is required.</mat-error>
          </mat-form-field>
        </div>

        <div class="inputContainer">
          <p>Job</p>
          <mat-form-field appearance="outline">
            <mat-label>Job</mat-label>
            <input matInput placeholder="enter job position" formControlName="job">
            <mat-error *ngIf="form.controls['job']?.touched && form.controls['job']?.errors?.required">Job is required.</mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="textArea">
          <p>Speech</p>
          <mat-form-field appearance="outline" >
            <mat-label>Speech</mat-label>
            <textarea matInput rows="10" placeholder="enter speech" formControlName="speech"></textarea>
            <mat-error *ngIf="form.controls['speech']?.touched && form.controls['speech']?.errors?.required">Speech is required.</mat-error>
          </mat-form-field>
        </div> -->
        <div class="inputContainer">
          <quill-editor [modules]="modules" [styles]="{height: '200px'}"
            formControlName="descriptions"></quill-editor>
        </div>
        <div class="uploadFile">
          <p>Upload Images</p>
          <!-- <button mat-raised-button color="primary" (click)="imageFiles.click()" type="button">
            Select Images
          </button> -->

          <button type="button" class="btnWithSpinner1" (click)="imageFiles.click()">
            <span style="margin-left: 5px;">Select Images</span>
          </button>
        </div>

        <input #imageFiles style="display: none;" type="file" id="imageFiles" accept='image/*' multiple
            (change)="onSelectedImages($event)" />

        <div class="groupButtonSelectedImages">
            <div class="buttonSelectedImage" *ngIf="uploadedImage">
                <p>{{ this.data?.formData?.image?.name }}</p>
                <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteUploadedImage(this.data?.formData?.image?.name)">Delete</button>
            </div>

            <div class="buttonSelectedImage" *ngIf="selectedImage">
                <p>{{ selectedImage?.name }}</p>
                <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteSelectedImage(selectedImage?.name)">Delete</button>
            </div>
        </div>

        <ng-container *ngIf="this.percentage">
          <mat-progress-bar mode="determinate" [value]="this.percentage"></mat-progress-bar>
        </ng-container>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <!-- <button class="btnCancel" mat-dialog-close>
        <span class="material-icons">close</span>
        <span style="margin-left: 10px;">Close</span>
      </button> -->

      <button type="submit" class="btnWithSpinner">
        <span class="material-icons" *ngIf="!this.testimonialStore.isLoading && this.data?.formData?.key">save</span>
        <span class="material-icons" *ngIf="!this.testimonialStore.isLoading && !this.data?.formData?.key">add</span>
        <mat-spinner class="mat-spinner-color" [diameter]="20" *ngIf="this.testimonialStore.isLoading"></mat-spinner>
        <span style="margin-left: 10px;" *ngIf="this.data?.formData?.key">Save</span>
        <span style="margin-left: 10px;" *ngIf="!this.data?.formData?.key">Add</span>
      </button>
    </mat-dialog-actions>

  </form>

</ng-container>

