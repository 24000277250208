<section class="bg-0 p-t-35 p-b-40">
    <div class="container">
        <div class="row">
            <div class="col-sm-10 col-md-12 p-b-30">
                <div class="p-l-0-sr767">
                    <h3 class="t1-m-5 cl-3 m-b-15">
                        Contact Info
                    </h3>

                    <p class="t1-s-2 cl-6 m-b-9" style="word-break: break-all;">
                        {{informationStore?.arrInformation[0]?.company_contact_description}}
                    </p>
                    
                    <ul class="p-t-11">
                        <!-- <li class="flex-wr-s-s t1-s-2 cl-6 p-b-8">
                            <span class="size-w-3 cl-5">
                                <i class="fa fa-home" aria-hidden="true"></i>
                            </span>

                            <span class="size-w-4">
                                {{informationStore?.arrInformation[0]?.company_address}}
                            </span>
                        </li>

                        <li class="flex-wr-s-s t1-s-2 cl-6 p-b-8">
                            <span class="size-w-3 cl-5">
                                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            </span>

                            <span class="size-w-4">
                              {{informationStore?.arrInformation[0]?.company_email}}
                            </span>
                        </li>

                        <li class="flex-wr-s-s t1-s-2 cl-6 p-b-8">
                            <span class="size-w-3 cl-5">
                                <i class="fa fa-phone" aria-hidden="true"></i>
                            </span>

                            <span class="size-w-4">
                              {{informationStore?.arrInformation[0]?.company_phone_number}}
                            </span>
                        </li> -->
                        <div class="flex-wr-s-c p-t-10 cl-1">
                            <div class="font-khmer" [innerHTML]="informationStore?.arrInformation[0]?.company_info_kh | safe:'html'"></div>

                        </div>
                        <div class="flex-wr-s-c p-t-10 cl-1">
                            <div class="font-khmer" [innerHTML]="informationStore?.arrInformation[0]?.company_info_en | safe:'html'"></div>

                        </div>
                        <div class="flex-wr-s-c p-t-10">
                            <a *ngIf="informationStore?.arrInformation[0]?.company_facebook" href={{informationStore?.arrInformation[0]?.company_facebook}} class="flex-c-c size-a-7 borad-50per bg-11 fs-16 cl-0 hov-btn2 trans-02 m-r-10" target="new">
                                <i class="fa fa-facebook"></i>
                            </a>
                            <a *ngIf="informationStore?.arrInformation[0]?.company_email" href={{informationStore?.arrInformation[0]?.company_email}} class="flex-c-c size-a-7 borad-50per bg-11 fs-16 cl-0 hov-btn2 trans-02 m-r-10" target="new">
                                <i class="fa fa-google-plus"></i>
                            </a>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
