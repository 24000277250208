<section class="bg-10 p-t-92 p-b-45">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 p-b-30">
                <!-- Title section -->
                <div class="flex-col-c-s p-b-20">
                    <h3 class="t1-b-1 cl-0 m-b-11">
                        Request A Call Back
                    </h3>

                    <div class="size-a-2 bg-0"></div>
                </div>

                <p class="t1-s-2 cl-0">
                    Leave your information and email address. We will call back and advise you.
                </p>
            </div>

            <form class="col-lg-8 p-b-30 p-t-8">
                <div class="row">
                    <div class="col-sm-6 p-b-25">
                        <div class="size-a-3">
                            <input class="s-full bg-0 t1-m-2 cl-6 plh-6 p-rl-20" type="text" name="name" placeholder="Your Name">
                        </div>
                    </div>

                    <div class="col-sm-6 p-b-25">
                        <div class="size-a-3">
                            <input class="s-full bg-0 t1-m-2 cl-6 plh-6 p-rl-20" type="text" name="phone" placeholder="Phone Number">
                        </div>
                    </div>

                    <!-- <div class="col-sm-6 p-b-25">
                        <div class="rs1-select2 bor9 bg0">
                            <select class="js-select2" name="subject">
                                <option>Subject</option>
                                <option>Subject 1</option>
                                <option>Subject 2</option>
                                <option>Subject 3</option>
                            </select>
                            <div class="dropDownSelect2"></div>
                        </div>
                    </div> -->

                    <div class="col-sm-6 p-b-25">
                        <button class="flex-c-c size-a-4 bg-11 t1-s-2 text-uppercase cl-0 hov-btn2 trans-02 p-rl-15">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
