<div>
  <app-admin-command-bar [tabData]="TABS" [name]="'Requested callback'">
  </app-admin-command-bar>

  <div style="padding: 20px !important;" *ngIf="store.loading">
    <ng-container>
      <p-skeleton styleClass="p-mb-2"></p-skeleton> <br>
      <p-skeleton width="10rem" styleClass="p-mb-2"></p-skeleton> <br>
      <p-skeleton width="5rem" styleClass="p-mb-2"></p-skeleton> <br>
      <p-skeleton height="2rem" styleClass="p-mb-2"></p-skeleton> <br>
      <p-skeleton width="10rem" height="4rem"></p-skeleton>
    </ng-container>
  </div>

<!--  -->
  <div class="cs-container file-content">
    <ng-container *ngIf="!store?.loading">
        <ng-container *ngIf="!store.loading && store?.data && store?.infinite | async as items">
            <virtual-scroller #scroll [items]="items" [checkResizeInterval]="0" style="height: calc(100vh - 23vh);"
            (vsEnd)="scrollHandler($event, scroll?.cachedItemsLength)">
                <div class="tableContainer">
                    <table class="table table-hover">
                        <thead class="table-header">
                          <tr>
                            <th scope="col" width="3%"></th>
                            <th scope="col" width="1%">No</th>
                            <th scope="col" width="40%">Name</th>
                            <th scope="col" width="30%">Phone Number</th>
                            <th scope="col" width="35%">Date/Time</th>
                            <th scope="col" width="1%">Action</th>
                          </tr>
                        </thead>
                        <tbody class="table-body" #container>
                        <ng-container>

                            <tr *ngFor="let item of scroll.viewPortItems ;let i=index; trackBy: trackByIdx">
                              <th (click)=rowClick(item)><div style="display: flex;align-items: center;justify-content: center;"><div [ngClass]="{'active-new':item?.status==='new'}"></div></div></th>
                              <th (click)=rowClick(item) scope="row">{{i+1}}</th>
                              <td (click)=rowClick(item)>{{item.name}}</td>
                              <td (click)=rowClick(item)>
                                {{item.phone}}
                              </td>
                              <td (click)=rowClick(item)>{{item.created_at?.seconds*1000|date:'medium'}}</td>
                              <td>
                                <div class="actionButtonsContainer container">
                                  <div class="row">
                                    <div>
                                      <mat-icon aria-label="delete" (click)=delete(item)>delete_outline</mat-icon>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>

              <div class="spinnerContainer" *ngIf="this.store.fetching">
                <mat-spinner></mat-spinner>
              </div>
            </virtual-scroller>
        </ng-container>
    </ng-container>
  </div>
</div>
