import { action, observable } from 'mobx';
import { computed } from 'mobx-angular';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { pushToObject } from '../services/random_functions/random-function.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({ providedIn: 'root' })
export class AuthStore {

  @observable public User: any;
  public keepMeSignIn : boolean = false;
  @observable profile: any = null;
  @observable public isLoading: boolean = false;

  constructor(
    private afs: AngularFirestore,
    private auth: AngularFireAuth
  ) { }

  @computed
  get isLoggedIn(): boolean {
    const user = localStorage.getItem('SessionUser');
    return user != null ? true : false;
  }

  @computed
  get isLoggedIn_admin(): boolean {
    const user = localStorage.getItem('SessionAdmin');
    return user != null ? true : false;
  }

  @action
  async getCurrentLoggedInUser() {
    this.auth.authState.subscribe(async (data: any) => {
      this.User = data;
      this.User = pushToObject(await this.afs.collection('users').doc(data?.uid).get().toPromise());
    });
  }
//  @action
//   async fetchUser(){
//     const profile = this.afs.collection('users').doc(this.User.user.uid).get().toPromise()
//     const dataProfile = pushToObject(await profile)
//     this.profile = dataProfile
//     console.log('Profile',this.profile);
//   }
  @action
  async updateUser(data:any){
    this.isLoading = true
    await this.afs.collection('users').doc(data.key).update({
        ...data,
    }).then(()=>{
        this.isLoading = false;
    }).catch((e)=>{
        console.log(e,"Error")
        this.isLoading =false;
    })
  }
}
