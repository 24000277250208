import { Component, OnInit } from '@angular/core';
import { BusinessPartnerStore } from 'src/app/stores/business_partner.store';
import { NewsStore } from 'src/app/stores/news.store';
import { ProjectStore } from 'src/app/stores/project.store';
import { ServiceStore } from 'src/app/stores/service.store';
import { SettingStore } from 'src/app/stores/setting.store';
import { TestimonialStore } from 'src/app/stores/testimonial.store';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  result: any;

  constructor(
    private serviceStore : ServiceStore,
    private newsStore : NewsStore,
    private projectStore : ProjectStore,
    private businessPartnerStore : BusinessPartnerStore,
    private testimonialStore : TestimonialStore,
    public settingStore : SettingStore,
  ) {
    this.serviceStore.getServicesHomepage();
    this.newsStore.getTop3News();
    this.projectStore.getProjectHomepage();
    this.businessPartnerStore.getBusinessPartnersNoDisable();
    this.testimonialStore.getTestimonialNoDisable();
    this.settingStore.getHomeSettings();
  }

  ngOnInit(): void {
  }
}
