<mat-dialog-content class="mat-typography">
  <div class="dialog-header">
    <h2 mat-dialog-title>Add new user</h2>
    <div class="space1"></div>
    <a mat-button mat-icon-button (click)="dialogRef.close()">
        <mat-icon class="mat-18">clear</mat-icon>
    </a>
  </div>
  <form [formGroup]="addUserForm" class="addUserContainer" (ngSubmit)="onSubmit(addUserForm.value)">
    <div>
      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Name</mat-label>
        <input matInput placeholder="name" formControlName="name" required>
        <mat-error *ngIf="addUserForm.get('name').hasError('required')">
          Name is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Email</mat-label>
        <input matInput placeholder="email" formControlName="email" required>
        <mat-error *ngIf="addUserForm.get('email').hasError('required')">
          Email is required.
        </mat-error>
        <mat-error *ngIf="addUserForm.get('email').hasError('email')">
          Invalid Email.
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Password</mat-label>
        <input type="password" matInput placeholder="password" formControlName="pw" required>
        <mat-error *ngIf="addUserForm.get('pw').hasError('required')">
          Password is required.
        </mat-error>
        <mat-error *ngIf="addUserForm.get('pw').hasError('minlength')">
          Password is too short.
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Confirm Password</mat-label>
        <input type="password" matInput placeholder="confirm password" formControlName="conpw" required>
        <mat-error *ngIf="addUserForm.get('conpw').hasError('required')">
          Confirm Password is required.
        </mat-error>
        <mat-error *ngIf="addUserForm.get('conpw').hasError('confirmedValidator')">
          Passwords don't match.
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="matInputForm">
        <mat-label>Date of birth</mat-label>
        <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" formControlName="dob">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="addUserForm.get('dob').hasError('required')">
          Date of Birth is required.
        </mat-error>
        <mat-error *ngIf="addUserForm.get('dob').hasError('matDatepickerMin')">
          Invalid minimum date.
        </mat-error>
        <mat-error *ngIf="addUserForm.get('dob').hasError('matDatepickerMax')">
          Invalid maximum date.
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true" (click)="onSubmit(addUserForm.value)" [disabled]="!addUserForm.valid">Add</button>
</mat-dialog-actions>
