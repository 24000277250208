import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { IProjectCategory } from 'src/app/models/project-category.model';
import { generateKeywords } from 'src/app/services/generators/generate-keywords.service';
import { UserMappingService } from 'src/app/services/mapping/user-mapping.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { ProjectCategoryStore } from 'src/app/stores/project-category.store';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {
  form: any;

  constructor(
    private projectCategoryStore: ProjectCategoryStore,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private authStore: AuthStore,
    private userMappingService: UserMappingService,


    public dialogRef: MatDialogRef<AddCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.form = this.formBuilder.group({
        name: new FormControl(this.data?.name, Validators.required),
        description : new FormControl(this.data?.description, Validators.required)
      })
    }
    else {
      this.form = this.formBuilder.group({
        name: new FormControl(null, Validators.required),
        description : new FormControl(null, Validators.required)
      })
    }

  }

  ngOnInit(): void {
  }
  formSubmit(formData: any) {
    if (this.form.valid) {
      if (this.data) {
        const { name,description } = formData;
        const data: IProjectCategory = {
          ...this.data,
          name: name,
          description:description
        }
        this.projectCategoryStore.updateCategory(data);
        this._snackBar.open("Category is update successfully.", "Close",{ duration: 5000 });

      }
      else {
        const key = this.afs.createId();
        const { name,description } = formData;
        const data: IProjectCategory = {
          key,
          name: name,
          description:description,
          created_at: new Date(),
          created_by: this.userMappingService.mapUser(this.authStore.User),
          updated_by: this.userMappingService.mapUser(this.authStore.User),
          updated_at: new Date(),
          page_key: this.pageKey(),
          keyword: generateKeywords([name]),

          status: 'Active',
          isDelete: false,
        }
        this.projectCategoryStore.addCategory(data);
        this._snackBar.open("Category is create successfully.", "Close",{ duration: 5000 });
      }
    }
  }

  pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }
}
