<form [formGroup]="form">
  <div style="width: 100%;" class="pageContainerForSpinner">
    <app-admin-command-bar [tabData]="TABS" [name]="'Page Settings'" [buttonText]="'Save'"
      (onPress)="formSubmit(form.value)">
    </app-admin-command-bar>

    <div style="padding: 20px !important;" *ngIf="informationStore.isLoading">
      <ng-container>
        <p-skeleton styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton width="10rem" styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton width="5rem" styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton height="2rem" styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton width="10rem" height="4rem"></p-skeleton>
      </ng-container>
    </div>

    <!-- *ngIf="!informationStore.isLoading" -->

    <div class="formContainer" *ngIf="!informationStore.isLoading">
      <div style="background-color: #fff;padding:20px">
        <!-- <div>Company Logo</div>
            <div style="margin-top: 10px;">
              <img class="image--cover" [defaultImage]="'./assets/images/loading.gif'" [lazyLoad]="imgURL" alt="IMG">
            </div>
            <div class="rows" style="margin: 20px 0 20px 0px;">
              <button mat-raised-button color="custom" (click)="imageFiles.click()">
                Change logo
              </button>
            </div>
            <div class="inputContainer">
              <mat-form-field appearance="outline" >
                <mat-label>Color theme</mat-label>
                <input matInput placeholder="" [value]="color_theme" (change)=colorPreview($event.target.value)>
              </mat-form-field>
            </div>

            <div class='row' style='margin-top:-70px;padding-right:15px'>
              <div></div>
              <div style="margin-left:auto" >
                <button class="mat-raised-button" [ngStyle]="{'background-color':color_theme}" style="color:white;" (click)=colorChange()>Preview Color</button>
              </div>
            </div>

            <div class="inputContainer">
              <div style="height:40px"></div>
            </div>

            <input #imageFiles style="display: none;" type="file" id="imageFiles" accept='image/*' single
            (change)="onSelectedImages($event)" /> -->

        <!-- <div class="inputContainer textArea">
          <mat-form-field appearance="outline">
            <mat-label>Company Description</mat-label>
            <textarea matInput rows="5" placeholder="enter company description"
              formControlName="company_description"></textarea>
          </mat-form-field>
        </div>

        <div class="inputContainer textArea">
          <mat-form-field appearance="outline">
            <mat-label>Company Contact Description</mat-label>
            <textarea matInput rows="5" placeholder="enter company contact description"
              formControlName="company_contact_description"></textarea>
          </mat-form-field>
        </div>

        <div class="inputContainer">
          <mat-form-field appearance="outline">
            <mat-label>Company Email</mat-label>
            <input matInput placeholder="enter company email" formControlName="company_email">
          </mat-form-field>
        </div>

        <div class="inputContainer">
          <mat-form-field appearance="outline">
            <mat-label>Company Facebook</mat-label>
            <input matInput placeholder="enter link" formControlName="company_facebook">
          </mat-form-field>
        </div>

        <div class="inputContainer">
          <mat-form-field appearance="outline">
            <mat-label>Company Phone Number</mat-label>
            <input matInput placeholder="enter company phone number" formControlName="company_phone_number">
          </mat-form-field>
        </div>

        <div class="inputContainer">
          <mat-form-field appearance="outline">
            <mat-label>Company Address</mat-label>
            <input matInput placeholder="enter company address" formControlName="company_address">
          </mat-form-field>
        </div>

        <div class="inputContainer">
          <mat-form-field appearance="outline">
            <mat-label>Company working hours</mat-label>
            <input matInput placeholder="example: Mon-Sat 09:00 am - 17:00 pm/Sunday CLOSE"
              formControlName="company_working_hours">
          </mat-form-field>
        </div> -->

        <div class="inputContainer">
          <mat-form-field appearance="outline">
            <mat-label>Company Google Map</mat-label>
            <input matInput placeholder="enter company google map" formControlName="company_google_map">
          </mat-form-field>
        </div>
        <div class="inputContainer">
          <label class="label">Information Company (KH)</label>
          <quill-editor [modules]="modules" [styles]="{height: '200px'}"
            formControlName="company_info_kh"></quill-editor>
        </div>
        <div class="inputContainer">
          <label class="label">Information Company (EN)</label>
          <quill-editor [modules]="modules" [styles]="{height: '200px'}"
            formControlName="company_info_en"></quill-editor>
        </div>

        <!-- <div class="inputContainer">
          <label class="label">Mission & Vision</label>
          <quill-editor [modules]="modules" [styles]="{height: '200px'}"
            formControlName="company_mission_vision"></quill-editor>
        </div>
        <div class="inputContainer">
          <label class="label">Company History</label>
          <quill-editor [modules]="modules" [styles]="{height: '200px'}"
            formControlName="company_history"></quill-editor>
        </div> -->
      </div>
    </div>
  </div>
</form>