import { Component, OnInit } from '@angular/core';
import { TestimonialStore } from 'src/app/stores/testimonial.store';
import { Autoplay, SwiperOptions } from 'swiper';

@Component({
  selector: 'app-home-nineth-block',
  templateUrl: './home-nineth-block.component.html',
  styleUrls: ['./home-nineth-block.component.scss']
})
export class HomeNinethBlockComponent implements OnInit {

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: true,
  };


  constructor(
    public testimonialStore : TestimonialStore,
  ) { }

  ngOnInit(): void {
  }

}
