import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { AngularFireStorage } from "@angular/fire/storage";

import { IUser } from "../models/user.model";
import { UserMappingService } from "../services/mapping/user-mapping.service";
import { pushToObject } from "../services/random_functions/random-function.service";
import { AuthStore } from "./auth.store";

@Injectable({providedIn:'root'})
export class UserStore {
  @observable public User:IUser;
  @observable public isLoading: boolean = false;
  @observable public isEnrollLoading: boolean = false;
  @observable public isAddLoading: boolean = false;

  @observable data: any = [];
  @observable loading = false;
  public lastVisible: any = null;
  @observable public fetching: boolean = false;
  @observable public searchText = null;
  @observable public filter = null;

  @observable instructorInfo: any;

  constructor(
    private router: Router,
    private afs: AngularFirestore,
    private auth: AngularFireAuth,
    private userMapping: UserMappingService,
    private authStore: AuthStore,
  ) { }

  @action
  async getCurrentLoggedInUser() {
    this.auth.authState.subscribe((data) => {
      if (data) {
        const { uid } = data;
        this.afs.collection('users').doc(uid).valueChanges().subscribe((data: any) => {
          this.User = this.userMapping.mapUser(data);
        });
      } else {
        // this.User = undefined;
      }
    });
  }

  @action
  async getCurrentLoggedInUser_2(callback:any) {
    this.isLoading = true;
    this.auth.authState.subscribe((data) => {
      if (data) {
        const { uid } = data;
        this.afs.collection('users').doc(uid).valueChanges().subscribe((data: any) => {
          this.User = this.userMapping.mapUser(data);
          this.isLoading = false;
          callback(this.User);
        });
      } else {
        // this.User = undefined;
        callback(undefined);
      }
    })
  }

  @action
  async getCurrentLoggedInUser_AllData(callback:any) {
    this.isLoading = true;
    this.auth.authState.subscribe((data) => {
      if (data) {
        const { uid } = data;
        this.afs.collection('users').doc(uid).valueChanges().subscribe((data: any) => {
          this.isLoading = false;
          callback(data);
        });
      } else {
        // this.User = undefined;
        callback(undefined);
      }
    })
  }
  @action
  async registerUser(userData: IUser, pw:string) {
    try {
      this.isLoading = true;

      await this.auth.createUserWithEmailAndPassword(userData.email, pw).then(async (res) =>{

        await this.afs.collection('users').doc(res.user?.uid).set({
          ...userData,
          key: res.user?.uid,
          status: {
            key: 1,
            text: "Active"
          },

          create_date: new Date(),
          create_by: this.userMapping.mapUser(this.authStore.User),
          update_date: new Date(),
          update_by: this.userMapping.mapUser(this.authStore.User),
        }).then(() => {
          this.isLoading = false;
          this.router.navigate(['/admin/users']);
        }).catch((error) => {
          console.log(error)
        });

      })

    } catch(error) {
      console.log(error)
    }
  }

  @action
  async getAllUserData(userId:any) {
    const data = pushToObject(await this.afs.collection('users').doc(userId).get().toPromise());
    return data;
  }

  @action
  async updateUser(userData:any) {
    try {
      this.isLoading = true;
      await this.afs.collection('users').doc(userData.key).update({
        ...userData,
        update_date: new Date(),
        update_by: this.userMapping.mapUser(userData)
      }).then(() => {
        this.isLoading = false;
      });
    } catch(error) {
      console.log(error)
    }
  }
  @action
  async resetPasswordUser(userData:any) {
    console.log('userData', userData);
    

    try {
      this.isLoading = true;
      await this.afs.collection('users').doc(userData.key).update({
        resetPassword: true,
        resetPassword_date: new Date(),
        resetPassword_by: this.userMapping.mapUser(userData)
      }).then(() => {
        this.isLoading = false;
      });
    } catch(error) {
      console.log(error)
    }
  }

  @action
  async deleteUser(userID:any){
    try{
      await this.afs.collection('users').doc(userID).delete().then(()=>{
        this.loading=false;
      })
    }
    catch(error){
      console.log(error);
    }
  }

  @action
  async fetchData(search: any, filter: any) {
    this.loading = true;
    this.lastVisible = null;
    this.fetching = false;
    this.searchText = search;
    this.filter = filter;

    const ref = this.lazyRef(this.lastVisible, search, filter);

    ref.auditTrail().subscribe();
    ref.snapshotChanges().subscribe(response => {
      this.data = [];
      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        return false;
      }

      this.lastVisible = response[response.length - 1].payload.doc;

      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }
      this.loading = false
      this.fetching = false;
      return true;
    }, error => {
      this.loading = false;
    });
  }

  @action
  async fetchDataMore(userId: any) {
    this.fetching = true;

    this.lazyRef(this.lastVisible, this.searchText, this.filter).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }

      this.lastVisible = response.docs[response.docs.length - 1];

      for (let item of response.docs) {
        this.data.push(item.data());
      }

      this.fetching = false;
    }, error => {
      this.fetching = false;
    });
  }

  lazyRef(lastVisible: any, search:any, filter:any) {
    return this.afs.collection<any>("users", ref => {
      let condition = ref.limit(20);

      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }

      return condition
    })
  }

}
