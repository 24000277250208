import { Component, OnInit } from '@angular/core';
import { tabs } from 'src/app/data/tabs';
import { ICallbackInbox } from 'src/app/models/callbackInbox.model';
import { InboxStore } from 'src/app/stores/inbox.store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationComponent } from 'src/app/shared/backend/delete-confirmation/delete-confirmation.component';


@Component({
  selector: 'app-callback-inbox',
  templateUrl: './callback-inbox.component.html',
  styleUrls: ['./callback-inbox.component.scss']
})
export class CallbackInboxComponent implements OnInit {

  TABS: Array<any> = tabs.inbox;
  loading:false;

  constructor(
    public store : InboxStore,

    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.store.fetchData(null,null,null);
  }

  async scrollHandler(e: { endIndex: number; }, size: number) {
    if (size < 10) return;
    if (e.endIndex === size - 1) await this.store.fetchDataMore();
  }

  rowClick(data:ICallbackInbox){
    if(data.status==='new'){
      const temp ={
        ...data,
        status:'read'
      }
      this.store.updateInbox(temp,(success)=>{
      })
    }
  }

  delete(data:ICallbackInbox){
    const dialogRef = this.dialog.open(DeleteConfirmationComponent,{
      width : '350px',
    });
    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.store.delete(data,(success)=>{
          this._snackBar.open("Successfully deleted.","Done",{ duration: 5000 });
        })
      }
    })
  }
}
