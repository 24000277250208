import { action, observable } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { MatSnackBar } from "@angular/material/snack-bar";

import { UserMappingService } from "../services/mapping/user-mapping.service";
import { AuthStore } from "./auth.store";
import { ISlideShow } from "../models/slideshow.model";
import { IInformation } from "../models/information.model";

@Injectable({providedIn:'root'})
export class InformationStore {

  @observable public isLoading: boolean = false;
  @observable public arrInformation: Array<IInformation> = [];

  constructor(
    private afs: AngularFirestore,
  ) { }

  @action
  getInformation() {
    try{
      this.isLoading=true;
      this.afs.collection('information').valueChanges().subscribe((data:any)=>{
        this.arrInformation=data;
        
        this.isLoading = false;
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  getInformation2(callback: (data: IInformation) => void) {
    try{
      this.isLoading=true;
      this.afs.collection('information').valueChanges().subscribe((data:any)=>{
        this.isLoading = false;
        callback(data);
      });
    }catch(e){
      console.log(e);
    }
  }

  @action
  async addInformation(informationData: IInformation, callback: (isSuccess: boolean) => void){
    try{
      this.isLoading = true;

      await this.afs.collection('information').doc(informationData.key).set(informationData).then(()=>{
        this.isLoading = false;
        callback(true);
      }).catch((e)=>{
        console.log(e);
        this.isLoading = false;
        callback(false);
      });
    }catch(e){
      console.log(e)
    }
  }

  @action
  async updateInformation(informationData: IInformation, callback: (isSuccess: boolean) => void){
    try{
      this.isLoading = true;

      await this.afs.collection('information').doc(informationData.key).update({
        ...informationData,
      }).then(() => {
        this.isLoading = false;
        callback(true);
      }).catch((e) => {
        this.isLoading = false;
        callback(false);
      });
    }catch(e){
      console.log(e);
    }
  }

}
