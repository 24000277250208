import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';

import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";

import { IBusinessPartner } from 'src/app/models/business-partner.model';
import { UserMappingService } from 'src/app/services/mapping/user-mapping.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { BusinessPartnerStore } from 'src/app/stores/business_partner.store';
import { generateKeywords } from 'src/app/services/generators/generate-keywords.service';
import { IImage } from 'src/app/models/image.model';

interface DialogData {
  formTitle: string;
  formData: IBusinessPartner;
}

@Component({
  selector: 'app-add-business-partner',
  templateUrl: './add-business-partner.component.html',
  styleUrls: ['./add-business-partner.component.scss']
})
export class AddBusinessPartnerComponent implements OnInit {

  form: any;
  uploadedImage: any;
  selectedImage: File;
  task: AngularFireUploadTask
  percentage: any = 0;
  constructor(
    public businessPartnerStore: BusinessPartnerStore,

    private authStore: AuthStore,
    private userMappingService: UserMappingService,
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddBusinessPartnerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.form = formBuilder.group({
      // name: new FormControl(data?.formData?.name || '', Validators.required),
      // description: new FormControl(data?.formData?.description || null, Validators.required),
      name: new FormControl(data?.formData?.name || ''),
      description: new FormControl(data?.formData?.description || null),
    });

    if (data.formData) {
      this.uploadedImage = data?.formData?.image;
      // this.selectedImage=new File(["dummy"],"dummy.png",{type:"image"})
    }
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async formSubmit(formData: any) {

    const { name, description } = formData;

    if (this.form.valid) {
      if (!this.selectedImage && !this.uploadedImage) {
        alert("Please select any image to upload.")
      } else {
        if (!this.data?.formData?.key) {
          const key = this.afs.createId();

          if(this.selectedImage){
            await this.uploadFileToFirebase(this.selectedImage, 'business_partner_images').then(uplodedFile => {
              this.uploadedImage = uplodedFile;
            });
          }

          const data: IBusinessPartner = {
            key,
            name,
            description,
            image: this.uploadedImage,

            created_at: new Date(),
            created_by: this.userMappingService.mapUser(this.authStore.User),
            updated_by: null,
            updated_at: null,
            page_key: this.pageKey(),
            keyword: generateKeywords([name]),

            status: 'Active',
            isDelete: false,
            isPublished: false,
          }

          this.businessPartnerStore.addBusinessPartner(data, (isSuccess) => {
            this.dialogRef.close();
            this._snackBar.open("Business Partner is added successfully.", "Done",{ duration: 5000 });
          });
        } else {
          if (this.selectedImage) {
            try {
              const storageRef = firebase.storage().ref();
              storageRef.child(this.data.formData.image.fileName).delete();
            } catch (e) {
              console.log(e);
            }
          }
          if (this.selectedImage) {
            await this.uploadFileToFirebase(this.selectedImage, 'business_partner_images').then(uplodedFile => {
              this.uploadedImage = uplodedFile;
            });
          }
          const data: IBusinessPartner = {
            ...this.data.formData,
            key: this.data.formData.key,
            name,
            description,

            image: this.uploadedImage,
            updated_by: this.userMappingService.mapUser(this.authStore.User),
            updated_at: new Date(),
          }

          this.businessPartnerStore.updateBusinessPartner(data, (isSuccess) => {
            this.dialogRef.close();
            this._snackBar.open("Business Partner is updated successfully.", "Done",{ duration: 5000 });
          });
        }
      }
    }
  }
  onSelectedImages(event: any) {
    if (event.target.files) {
      this.selectedImage = event.target.files[0];
      this.uploadedImage = null;
    }
  }


  // async uploadFileToFirebase(item: File, basePath: string) {
  //   const filePath = `${basePath}/${Date.now()}_${item.name}`;
  //   const storageRef = this.storage.ref(filePath);

  //   this.task = this.storage.upload(filePath, item);
  //   this.task.percentageChanges().subscribe((percentage) => {
  //     this.percentage = percentage;
  //   });

  //   return new Promise<IImage>((resolve, reject) => {
  //     this.task.then((f) => {
  //       f.ref.getDownloadURL().then((downloadURL) => {
  //         resolve({
  //           key: uuidv4(),
  //           downloadURL: downloadURL,
  //           fileName: filePath,
  //           fileSize: item.size,
  //           fileType: item.type,
  //           name: item.name,
  //           type: 'image',
  //         });
  //       }).catch(e => reject(e))
  //     });
  //   })
  // }

  async uploadFileToFirebase(item: File, basePath: string) {
    const filePath = `${basePath}/${Date.now()}_${item.name}`;
    const storageRef = this.storage.ref(filePath);

    this.task = this.storage.upload(filePath, item);
    this.task.percentageChanges().subscribe((percentage) => {
      this.percentage = percentage;
    });

    return new Promise<IImage | any>((resolve, reject) => {
      this.task.then((f) => {
        f.ref.getDownloadURL().then((downloadURL) => {
          resolve({
            key: uuidv4(),
            downloadURL: downloadURL,
            fileName: filePath,
            fileSize: item.size,
            fileType: item.type,
            name: item.name,
            type: 'image',
          });
        }).catch(e => reject(e))
      });
    })
  }

  deleteSelectedImage(selectedImage: File) {
    this.selectedImage = undefined;
  }

  deleteUploadedImage(selectedImage: IImage) {
    this.uploadedImage = undefined;
  }

  pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }
}
