<section class="bg-0 p-t-92 p-b-90">
  <div class="container" style="margin-bottom: 30px;">
    <div class="flex-col-c-c p-b-50">
      <h3 class="t1-b-1 cl-3 txt-center m-b-11">
        Business Partners
      </h3>

      <div class="size-a-2 bg-3"></div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-4 col-lg-2 flex-c-c p-b-60" *ngFor="let item of this.businessPartnerStore?.businessPartnersNoDisable">
        <a>
          <img class=" trans-02 max-s-full" src={{item?.image?.downloadURL}} alt="IMG">
        </a>
      </div>
    </div>
  </div>

  <!-- <div class="container">
    <div class="flex-col-c-c p-b-50">
      <h3 class="t1-b-1 cl-3 txt-center m-b-11">
        Testimonials
      </h3>

      <div class="size-a-2 bg-3"></div>
    </div>

    <div class="row justify-content-center">

      <div class="col-sm-10 col-md-11 col-lg-6 p-b-30 wrap-block3"
        *ngFor="let item of this.testimonialStore.testimonialsNoDisable">
        <div class="padding-10px" style="height: 100%;border: 1px solid rgba(204,204,204,1);">
          <div class="block3-Noborder d-flex">
            <div class="block3-content d-flex">
              <div class="block3-pic wrap-pic-w">
                <img [defaultImage]="'./assets/images/loading.gif'" [lazyLoad]="item?.image?.downloadURL" alt="IMG"
                  style="width:120px;height: 180px; object-fit:cover;">
              </div>

              <div class="block3-text d-flex w-full-sr575">
                <span class="block3-text-child t1-m-1 text-uppercase cl-3 p-b-4 cardTitle">
                  {{item?.name}}
                </span>

                <span class="block3-text-child t1-s-3 cl-7 p-b-9 cardText">
                  {{item?.job}}
                </span>

                <p class="block3-text-child t1-s-2 cl-6 cardText">
                  {{item?.speech}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

</section>
