import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss']
})
export class ServiceCardComponent implements OnInit {
  @Input() serviceData:any;

  constructor(
    private router: Router,

  ) { }

  ngOnInit(): void {
  }
  serviceCardClick(serviceID:any){
    this.router.navigate(['/service/'+serviceID])
  }
}
