import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";

import { ITestimonial } from 'src/app/models/testimonial.model';
import { generateKeywords } from 'src/app/services/generators/generate-keywords.service';
import { UserMappingService } from 'src/app/services/mapping/user-mapping.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { TestimonialStore } from 'src/app/stores/testimonial.store';
import { IImage } from 'src/app/models/image.model';
import { modulesEditor } from 'src/app/data/editor';

interface DialogData {
  formTitle: string;
  formData: ITestimonial;
}

@Component({
  selector: 'app-add-testimonial',
  templateUrl: './add-testimonial.component.html',
  styleUrls: ['./add-testimonial.component.scss']
})
export class AddTestimonialComponent implements OnInit {
  form: any;
  uploadedImage: any;
  selectedImage: File;
  filePath: Array<string> = [];
  task: AngularFireUploadTask;
  percentage: any = 0;
  modules = modulesEditor;

  constructor(
    public testimonialStore: TestimonialStore,
    private authStore: AuthStore,
    private userMappingService: UserMappingService,
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddTestimonialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.form = formBuilder.group({
      name: new FormControl(data?.formData?.name || '', Validators.required),
      job: new FormControl(data?.formData?.job || null, Validators.required),
      speech: new FormControl(data?.formData?.speech || ''),
      descriptions: new FormControl(data?.formData?.descriptions || ''),
    });

    if (data.formData) {
      this.uploadedImage = data?.formData?.image;
    }
  }

  ngOnInit(): void {
  }

  async formSubmit(formData: any) {
    console.log('this.selectedImage',this.selectedImage);
    const { name, job, speech,descriptions } = formData;

    if (this.form.valid) {
      // if (!this.selectedImage && !this.uploadedImage) {
      //   alert("Please select any image to upload.")
      // } else {
        
      // }
      if (!this.data?.formData?.key) {
        const key = this.afs.createId();
        if(this.selectedImage){
          await this.uploadFileToFirebase(this.selectedImage, 'testimonial_images').then(uplodedFile => {
            this.uploadedImage = uplodedFile;
          });
        }
        

        const data: ITestimonial = {
          key,
          name,
          job,
          speech,
          descriptions,
          image: this.uploadedImage || null,

          created_at: new Date(),
          created_by: this.userMappingService.mapUser(this.authStore.User),
          updated_by: null,
          updated_at: null,
          page_key: this.pageKey(),
          keyword: generateKeywords([name]),

          status:'Active',
          isDelete: false,
          isPublished: false,
        }

        this.testimonialStore.addTestimonial(data, (isSuccess) => {
          this.dialogRef.close();
          this._snackBar.open("Testimonial is added successfully.", "Done",{ duration: 5000 });
        });
      } else {
        if (this.selectedImage) {
          try {
            const storageRef = firebase.storage().ref();
            storageRef.child(this.data.formData.image.fileName).delete();
          } catch (e) {
            console.log(e);
          }
        }
        if (this.selectedImage) {
          await this.uploadFileToFirebase(this.selectedImage, 'testimonial_images').then(uplodedFile => {
            this.uploadedImage = uplodedFile;
          });
        }
        const data: ITestimonial = {
          ...this.data.formData,
          key: this.data.formData.key,
          name,
          job,
          speech,
          descriptions,

          image: this.uploadedImage || null,
          updated_by: this.userMappingService.mapUser(this.authStore.User),
          updated_at: new Date(),
        }

        this.testimonialStore.updateTestimonial(data, (isSuccess) => {
          this.dialogRef.close();
          this._snackBar.open("Testimonial is updated successfully.", "Done",{ duration: 5000 });
        });
      }
    }
  }
  onSelectedImages(event: any) {
    if (event.target.files) {
      this.selectedImage = event.target.files[0];
      this.uploadedImage = null;
    }
  }

  async uploadFileToFirebase(item: File, basePath: string) {
    const filePath = `${basePath}/${Date.now()}_${item.name}`;
    const storageRef = this.storage.ref(filePath);

    this.task = this.storage.upload(filePath, item);
    this.task.percentageChanges().subscribe((percentage) => {
      this.percentage = percentage;
    });

    return new Promise<IImage | any>((resolve, reject) => {
      this.task.then((f) => {
        f.ref.getDownloadURL().then((downloadURL) => {
          resolve({
            key: uuidv4(),
            downloadURL: downloadURL,
            fileName: filePath,
            fileSize: item.size,
            fileType: item.type,
            name: item.name,
            type: 'image',
          });
        }).catch(e => reject(e))
      });
    })
  }

  deleteSelectedImage(selectedImage: File) {
    this.selectedImage = undefined;
  }

  deleteUploadedImage(selectedImage: IImage) {
    this.uploadedImage = undefined;
  }

  pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }

}
