
  <div class="bg-10 h-full" (click)="serviceCardClick(serviceData.key)">
      <a class="hov-img0 of-hidden bg-0">
          <img src={{serviceData.images[0].downloadURL}} alt="IMG">
      </a>

      <div class="p-rl-30 p-t-26 p-b-20">
          <h4 class="p-b-9">
              <a class="t1-m-1 cl-0 hov-link2 trans-02">
                  {{serviceData.title}}
              </a>
          </h4>
          <p class="t1-s-2 cl-13">
              {{serviceData.description}}
          </p>
      </div>
  </div>
