import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ICallbackInbox } from 'src/app/models/callbackInbox.model';
import { InboxStore } from 'src/app/stores/inbox.store';
import { getConstantValue } from 'typescript';

@Component({
  selector: 'app-home-third-block',
  templateUrl: './home-third-block.component.html',
  styleUrls: ['./home-third-block.component.scss']
})
export class HomeThirdBlockComponent implements OnInit {

  form:any;
  constructor(
    private inboxStore : InboxStore,
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private _snackBar: MatSnackBar,

  ) {
    this.form = formBuilder.group({
      name:new FormControl(''),
      phone:new FormControl(''),
    })
  }

  ngOnInit(): void {
  }

  requestCallback(formData:ICallbackInbox){
    if(formData){
      const {name,phone }= formData;
      const key = this.afs.createId();

      const data: ICallbackInbox={
        key,
        name : name,
        phone : phone,

        created_at : new Date(),
        status : 'new',
        isDelete : false,
      }
      this.inboxStore.addInbox(data,(isSuccess)=>{
        this.form.reset();
        this._snackBar.open("Successfully submitted.","Done",{ duration: 5000 });
      })
    }
  }
}
