import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { tabs } from 'src/app/data/tabs';
import { SettingStore } from 'src/app/stores/setting.store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { v4 as uuidv4 } from 'uuid';

import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import { IImage } from 'src/app/models/image.model';
import { ConfigStore } from 'src/app/stores/config.store';


@Component({
  selector: 'app-homepage-setting-dashboard',
  templateUrl: './homepage-setting-dashboard.component.html',
  styleUrls: ['./homepage-setting-dashboard.component.scss']
})
export class HomepageSettingDashboardComponent implements OnInit {

  TABS : Array<any> = tabs.pagesettings;
  task: AngularFireUploadTask;
  imgURL : string;
  imgFooterURL:string;
  color_theme : string;
  selectFont : string;
  primaryFont: string;
  loading=false;
  company_logo:any;
  footer_logo:any;
  form: any;
  font:Array<string>;
  selectedItem : any;

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private storage: AngularFireStorage,


    public settingStore : SettingStore,
    public configStore : ConfigStore
  ) {

    this.settingStore.getFontSize().then(()=>{

      this.form=formBuilder.group({
        companyName : new FormControl(this.settingStore?.fontSize?.value?.company_name,Validators.required),
        titleContent : new FormControl(this.settingStore?.fontSize?.value?.title_content,Validators.required),

        headerInfo : new FormControl(this.settingStore?.fontSize?.value?.top_bar,Validators.required),
        headerMenu : new FormControl(this.settingStore?.fontSize?.value?.menu,Validators.required),

        cardTitle : new FormControl(this.settingStore?.fontSize?.value?.card_title,Validators.required),
        cardText : new FormControl(this.settingStore?.fontSize?.value?.card_text,Validators.required),

        cardDetailTitle : new FormControl(this.settingStore?.fontSize?.value?.card_detail_title,Validators.required),
        cardDetailText : new FormControl(this.settingStore?.fontSize?.value?.card_detail_text,Validators.required),

        footerTitle : new FormControl(this.settingStore?.fontSize?.value?.footer_title,Validators.required),
        footerText : new FormControl(this.settingStore?.fontSize?.value?.footer_text,Validators.required),
      })
      this.loading=false;
    })
  }

  ngOnInit(): void {
    this.loading=true;
    this.font=this.settingStore.getAllFont();
    this.settingStore.getHomeSettings();
    this.settingStore.getAboutSettings();
    this.configStore.getConfigMenus()
    this.settingStore.getSetting('company_logo').then(()=>{
      this.imgURL=this.settingStore?.setting?.value?.downloadURL
    })

    this.settingStore.getSetting('footer_logo').then(()=>{
      this.imgFooterURL=this.settingStore?.setting?.value?.downloadURL
    })

    this.settingStore.getSetting('color_theme').then(()=>{
      this.color_theme=this.settingStore?.setting?.value;
    });

    this.settingStore.getFont().then(()=>{
      this.selectedItem=this.settingStore.font.value;
    })
    // this.selectedItem=1;
  }


  toggleDisplay(event:any,item:any){
    this.settingStore.updateSetting(item?.key,event?.checked);
  }

  toggleDisplayMenu(event:any,item:any){
    console.log('event',event);
    console.log('item',item);


    this.configStore.updateConfigMenu(item?.key,event?.checked);
  }
  fontChange(event:any){

    this.settingStore.changeFont(event.value)
    document.documentElement.style.setProperty(`--appFont`, event.value);
    this._snackBar.open("Saved.", "Done",{ duration: 5000 });

    // this.settingStore.changeFont(this.font[parseInt(event.value)-1])
    // document.documentElement.style.setProperty(`--appFont`, this.font[parseInt(event.value)-1]);
  }

  colorPreview(event:any){
    this.settingStore.updateSetting('color_theme',event);
    document.documentElement.style.setProperty(`--primaryColor`, event);
    this._snackBar.open("Saved.", "Done",{ duration: 5000 });
  }

  colorChange(){
    this.settingStore.updateSetting('color_theme',this.color_theme);
  }

  async onSelectedImages(event:any){
    this.company_logo =event.target.files[0]
    this.imgURL=(window.URL ? URL : webkitURL).createObjectURL(event.target.files[0])
    if(this.company_logo){
      await this.uploadFileToFirebase(this.company_logo,'company_logo').then(uplodedFile=>{
        this.settingStore.getSetting('company_logo').then(()=>{
          const storageRef = firebase.storage().ref();
          storageRef.child(this.settingStore?.setting?.value?.fileName).delete();
          this.settingStore.updateSetting('company_logo',uplodedFile);
        });
      })
    }
  }

  async onSelectedImagesFooter(event:any){
    this.footer_logo =event.target.files[0]
    this.imgFooterURL=(window.URL ? URL : webkitURL).createObjectURL(event.target.files[0])
    if(this.footer_logo){
      await this.uploadFileToFirebase(this.footer_logo,'footer_logo').then(uplodedFile=>{
        this.settingStore.updateSetting('footer_logo',uplodedFile);

        // this.settingStore.getSetting('footer_logo').then(()=>{
        //   const storageRef = firebase.storage().ref();
        //   storageRef.child(this.settingStore?.setting?.value?.fileName).delete();
        //   this.settingStore.updateSetting('footer_logo',uplodedFile);
        // });
      })
    }
  }

  formSubmit(formData:any){
    const {top_bar,menu,card_title,card_text,card_detail_title,card_detail_text,footer_title,footer_text}=formData;
    const {headerInfo,headerMenu,cardTitle,cardText,cardDetailTitle,cardDetailText,footerTitle,footerText,companyName,titleContent}=formData;
    const data:any = {
      company_name: companyName,
      title_content: titleContent,
      top_bar:headerInfo,
      menu:headerMenu,
      card_title:cardTitle,
      card_text:cardText,
      card_detail_title:cardDetailTitle,
      card_detail_text:cardDetailText,
      footer_title:footerTitle,
      footer_text:footerText
    }

    this.settingStore.changeFontSize(data).then(()=>{
      this._snackBar.open("Saved.", "Done",{ duration: 5000 });
    });
  }
  async uploadFileToFirebase(item: File, basePath: string) {
    const filePath = `${basePath}/${Date.now()}_${item.name}`;
    const storageRef = this.storage.ref(filePath);

    this.task = this.storage.upload(filePath, item);
    this.task.percentageChanges().subscribe((percentage) => {
      // this.percentage = percentage;
    });

    return new Promise<IImage | any>((resolve, reject) => {
      this.task.then((f) => {
        f.ref.getDownloadURL().then((downloadURL) => {
          resolve({
            key: uuidv4(),
            downloadURL: downloadURL,
            fileName: filePath,
            fileSize: item.size,
            fileType: item.type,
            name: item.name,
            type: 'image',
          });
        }).catch(e => reject(e))
      });
    })
  }
}
