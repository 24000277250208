import { Component, OnInit } from '@angular/core';
import { TestimonialStore } from 'src/app/stores/testimonial.store';

@Component({
  selector: 'app-about-fifth-block',
  templateUrl: './about-fifth-block.component.html',
  styleUrls: ['./about-fifth-block.component.scss']
})
export class AboutFifthBlockComponent implements OnInit {

  constructor(
    public testimonialStore : TestimonialStore,

  ) { }

  ngOnInit(): void {
  }

}
