import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { AuthStore } from 'src/app/stores/auth.store';
import { Router } from '@angular/router';
import { UserStore } from 'src/app/stores/user.store';
import { AngularFirestore } from '@angular/fire/firestore';
import { pushToObject } from '../random_functions/random-function.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoading: boolean = false;

  constructor(
    private router: Router,
    private authStore: AuthStore,
    private userStore: UserStore,
    private firebaseAuth: AngularFireAuth,
    private afs: AngularFirestore,


  ) { }

  signin_admin(email: string, password: string,keepMeSingin:boolean) {
    this.isLoading = true;
    this.firebaseAuth.signInWithEmailAndPassword(email, password).then(async (value: any) => {
      this.isLoading = false;
        this.authStore.User = value;
        this.authStore.keepMeSignIn = keepMeSingin;
        localStorage.setItem('SessionAdmin', JSON.stringify(value.user));
        // this.router.navigate(['/admin']);
        const profile = this.afs.collection('users').doc(this.authStore.User.user.uid).get().toPromise()
        const dataProfile = pushToObject(await profile)
        const {isFirstLog}= dataProfile
        if(!isFirstLog){
        this.router.navigate(['/admin/change-password']);
      }
      else{
        this.router.navigate(['/admin']);

      }

    }).catch((err) => {
      alert('Invalid Email and Password');
      this.isLoading=false
    });
  }

  signout_admin() {
    this.isLoading = false;
    this.firebaseAuth.signOut().then(() => {
      this.authStore.User = undefined;
      localStorage.removeItem('SessionAdmin');
      localStorage.removeItem('SessionUser');
      this.router.navigate(['/admin/login']);
    });
  }

}
