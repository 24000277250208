<div style="width: 100%;" class="pageContainerForSpinner">
    <app-admin-command-bar [tabData]="TABS" [name]="'Homepage'" [buttonText]="'Add SlideShow'" (onPress)="create()">
    </app-admin-command-bar>
    <div style="padding: 20px !important;" *ngIf="slideShowStore.isLoading">
      <ng-container>
        <p-skeleton styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton width="10rem" styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton width="5rem" styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton height="2rem" styleClass="p-mb-2"></p-skeleton> <br>
        <p-skeleton width="10rem" height="4rem"></p-skeleton>
      </ng-container>
    </div>

    <div class="tableContainer" *ngIf="!slideShowStore.isLoading">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col" width="1%">No</th>
              <th scope="col"width="96%">Preview</th>
              <th scope="col"width="1%">Order</th>
              <th scope="col"width="1%">Status</th>
              <th scope="col"width="1%">Action</th>
            </tr>
          </thead>
          <tbody #container>
            <ng-container>
              <tr *ngFor="let item of slideShowStore.slideShows ;let i=index;">
                <th (click)=rowClick(item) scope="row">{{i + 1}}</th>
                <td (click)=rowClick(item)>
                    <img [defaultImage]="'./assets/images/circle-loading.gif'" [lazyLoad]="item?.image?.downloadURL" alt="{{ item?.image?.name }}" style="width: 220px;height: 100px;object-fit: cover;">
                </td>
                <td (click)=rowClick(item)>{{item?.order}}</td>
                <td (click)=rowClick(item) [ngStyle]="{'color':(item?.status==='Active')?'blue':'red'}">{{item?.status}}</td>
                <td>
                  <div class="actionButtonsContainer container">
                    <div class="row">
                      <div style="margin-right: 10px;">
                        <mat-icon aria-label="disable" (click)="disable(item)">hide_source</mat-icon>
                      </div>
                      <div  style="margin-right: 10px;"  >
                        <mat-icon aria-label="edit" (click)=edit(item)>mode_edit_outline</mat-icon>

                      </div>
                      <div>
                        <mat-icon aria-label="delete" (click)=delete(item)>delete_outline</mat-icon>

                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr *ngIf="this.slideShowStore.slideShows?.length === 0">
                <td colspan="9"><div style="text-align: center;">No News.</div></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
    </div>
</div>
