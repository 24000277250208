<div style="width: 100%;" class="pageContainerForSpinner">
  <app-admin-command-bar [tabData]="TABS" [name]="'Page Settings'">
  </app-admin-command-bar>
</div>

<div style="background-color: #fff; margin:10px;padding:20px">
  <div>
    <div>
      <p style="font-size: 20px;font-weight: 500;color:rgba(0, 0, 0, 0.87);">Company Logo</p>
    </div>
    <div style="margin-top: 10px;">
      <img class="image--cover" [defaultImage]="'./assets/images/circle-loading.gif'" [lazyLoad]="imgURL" alt="IMG">
    </div>
    <div class="rows" style="margin: 20px 0 20px 0px;">
      <button mat-raised-button (click)="imageFiles.click()" color='custom'>
        Change logo
      </button>
    </div>
    <!-- <div style="margin-top: 10px;background:#05233a;">
      <div style="padding:-20px">
        <img class="image--cover" [defaultImage]="'./assets/images/circle-loading.gif'" [lazyLoad]="imgFooterURL" alt="IMG">
      </div>
    </div>
    <div class="rows" style="margin: 20px 0 20px 0px;">
      <button mat-raised-button (click)="imageFilesFooter.click()" color='custom'>
        Change footer logo
      </button>
    </div> -->
    <div>
      <p style="margin-bottom: 15px;margin-top: 30px;font-size: 20px;font-weight: 500;color:rgba(0, 0, 0, 0.87);">Color Theme</p>
    </div>
    <div class="inputContainer">
      <mat-form-field appearance="outline" >
        <mat-label>Color theme</mat-label>
        <input matInput placeholder="" [value]="color_theme" (change)=colorPreview($event.target.value)>
      </mat-form-field>
    </div>
    <!-- <div class='row' style='margin-top:-70px;padding-right:15px'>
      <div></div>
      <div style="margin-left:auto" >
        <button class="mat-raised-button" [ngStyle]="{'background-color':color_theme}" style="color:white;" (click)=colorChange()>Save</button>
      </div> -->
    <!-- </div> -->
  <!-- <div class="inputContainer">
    <div style="height:40px"></div>
  </div> -->

  <input #imageFiles style="display: none;" type="file" id="imageFiles" accept='image/*' single
  (change)="onSelectedImages($event)" />
  <input #imageFilesFooter style="display: none;" type="file" id="imageFiles" accept='image/*' single
  (change)="onSelectedImagesFooter($event)" />
  <div>
    <p style="margin-bottom: 15px;margin-top: 20px;font-size: 20px;font-weight: 500;color:rgba(0, 0, 0, 0.87);">Font</p>
  </div>
    <div class="inputContainer" *ngIf="this.font">
      <mat-form-field appearance="outline">
        <mat-label>Select Font</mat-label>
        <mat-select (selectionChange)="fontChange($event)" [(value)]="selectedItem">
          <mat-option value={{selectedItem}}>{{selectedItem}}</mat-option>
          <ng-container *ngFor="item of font;let i=index">
            <mat-option *ngIf="this.font[i]!=selectedItem" value={{this.font[i]}}>{{this.font[i]}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="inputContainer" style="margin-bottom: 20px;" [ngStyle]="{'font-family':selectFont}">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title style="margin-left: -15px;">Font Size</mat-card-title>
          <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
        </mat-card-header>
        <mat-card-content [ngStyle]="{'font-family':selectFont}">
          <form *ngIf="!this.loading" [formGroup]="form" class="dialog-content-wrapper">
            <hr/>
            <br/>
            <p style="font-weight: 500;" class="m-b-10">Header :</p>
            <div class="inputContainer1">
              <mat-form-field appearance="outline" >
                <mat-label>Company Name</mat-label>
                <input matInput placeholder="" formControlName="companyName">
              </mat-form-field>
            </div>
            <div class="inputContainer1">
              <mat-form-field appearance="outline" >
                <mat-label>Top-bar</mat-label>
                <input matInput placeholder="" formControlName="headerInfo">
              </mat-form-field>
            </div>
            <div class="inputContainer1">
              <mat-form-field appearance="outline" >
                <mat-label>Menu</mat-label>
                <input matInput placeholder="" formControlName="headerMenu">
              </mat-form-field>
            </div>
            <hr/>
            <br/>
            <p style="font-weight: 500;" class="m-b-10">Footer :</p>
            <div class="inputContainer1">
              <mat-form-field appearance="outline" >
                <mat-label>Tittle</mat-label>
                <input matInput placeholder="" formControlName="footerTitle">
              </mat-form-field>
            </div>
            <div class="inputContainer1">
              <mat-form-field appearance="outline" >
                <mat-label>Text</mat-label>
                <input matInput placeholder="" formControlName="footerText">
              </mat-form-field>
            </div>
            <hr/>
            <br/>
            <p style="font-weight: 500;" class="m-b-10">Card :</p>
            <div class="inputContainer1">
              <mat-form-field appearance="outline" >
                <mat-label>Tittle</mat-label>
                <input matInput placeholder="" formControlName="cardTitle">
              </mat-form-field>
            </div>
            <div class="inputContainer1">
              <mat-form-field appearance="outline" >
                <mat-label>Text</mat-label>
                <input matInput placeholder="" formControlName="cardText">
              </mat-form-field>
            </div>
            <hr/>
            <br/>
            <p style="font-weight: 500;" class="m-b-10">Card Details page :</p>
            <div class="inputContainer1">
              <mat-form-field appearance="outline" >
                <mat-label>Tittle</mat-label>
                <input matInput placeholder="" formControlName="cardDetailTitle">
              </mat-form-field>
            </div>
            <div class="inputContainer1">
              <mat-form-field appearance="outline" >
                <mat-label>Text</mat-label>
                <input matInput placeholder="" formControlName="cardDetailText">
              </mat-form-field>
            </div>
            <hr/>
            <br/>
            <p style="font-weight: 500;" class="m-b-10">Title Content :</p>
            <div class="inputContainer1">
              <mat-form-field appearance="outline" >
                <mat-label>Title Content</mat-label>
                <input matInput placeholder="" formControlName="titleContent">
              </mat-form-field>
            </div>
            <hr/>
            <button mat-raised-button color="custom" (click)="formSubmit(form.value)">Save</button>
          </form>
        </mat-card-content>
        <!-- <mat-card-actions>
          <button style="margin-left: 5px;" mat-raised-button color='primary'>Save</button>
        </mat-card-actions> -->
      </mat-card>
    </div>



  </div>
  <div>
    <!-- <p style="font-size: 20px;font-weight: 500;color:rgba(0, 0, 0, 0.87);">Show/Hide</p> -->
  </div>
  <hr/>
  <div class="row" >
    <div class="col-3">
      <h3 style="font-size: 16px;margin-bottom: 10px;color:#333">Homepage</h3>
      <div class="tableContainer" style="overflow:hidden">
        <table>
          <tbody>
            <tr>
              <td style="width:40%;">
                Display
              </td>
              <td>
                Content block
              </td>
            </tr>
            <tr *ngFor="let item of this.settingStore?.homeSettings">
              <td >
                <mat-slide-toggle (change)=toggleDisplay($event,item) [checked]="item?.value"></mat-slide-toggle>
              </td>
              <td>
                {{item?.text}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-3 tableContainer">
      <h3 style="font-size: 16px; margin-bottom: 10px;color:#333">About us page</h3>
      <div class="tableContainer" style="overflow:hidden">
        <table>
          <tbody>
            <tr>
              <td style="width:40%;">
                Display
              </td>
              <td>
                Content block
              </td>
            </tr>
            <tr *ngFor="let item of this.settingStore?.aboutSettings">
              <td >
                <mat-slide-toggle (change)=toggleDisplay($event,item) [checked]="item?.value"></mat-slide-toggle>
              </td>
              <td>
                {{item?.text}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-3 tableContainer">
      <h3 style="font-size: 16px; margin-bottom: 10px;color:#333">Menu Header</h3>
      <div class="tableContainer" style="overflow:hidden">
        <table>
          <tbody>
            <tr>
              <td style="width:40%;">
                Display
              </td>
              <td>
                Menu block
              </td>
            </tr>
            <tr *ngFor="let item of this.configStore?.menuConig">
              <td >
                <mat-slide-toggle (change)=toggleDisplayMenu($event,item) [checked]="item?.value"></mat-slide-toggle>
              </td>
              <td>
                {{item?.text}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
