<div id="signinContainer">
  <!-- <div class="lottie-container">
    <ng-lottie [options]="lottie" width="100%" ></ng-lottie>
  </div> -->
  <div class="signinBox">
    <div class="aboveContainer">
        <h2>Sign in</h2>
        <p>Login with your email and password.</p>

        <div class="formLoginAdminContainer">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
                <input type="email" placeholder="Enter email" formControlName="email">
                <input type="password" placeholder="Enter password" formControlName="password">

                <div class="checkboxContainer">
                    <mat-checkbox class="example-margin" formControlName="check">Keep me sing in.</mat-checkbox>
                </div>

                <button type="submit" style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
                  <span style="margin-right: 10px;">Sign in</span>
                  <mat-spinner class="mat-spinner-color" [diameter]="20" *ngIf="this.authService.isLoading === true"></mat-spinner>
                </button>
            </form>
        </div>

        <div class="helpContainer">
            <!-- <p>Doesn't have any account or forget password?</p>
            <p>
                <span>Contact Us: </span>
                <span>
                    <a href="https://auxswot.com/" target="_blank">auxswot.com</a>
                </span>
            </p> -->

            <p>
              <!-- <span>Back to Website: </span> -->
              <span>
                  <a [routerLink]="['']">Back to homepage.</a>
              </span>
          </p>
        </div>
    </div>
    <div class="belowContainer">
        <p>
            <span>Terms of use</span>|
            <span>Privacy</span>|
            <span>Status</span>
        </p>
    </div>
  </div>
  <!-- <div class="fill-blur"></div> -->
  <div class="signInContent">
      <!-- <h1>Camwin E-commerce</h1> -->
  </div>
</div>
