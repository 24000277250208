<div>
    <div class="pageContainer">
        <div class="wrapperContainer">
            <div class="wrapperHeaderContainer">
                <div class="imageContainer">
                    <img src="../../../../assets/images/CamVin logo.png" alt="">
                </div>
                <h4>CAMwin Quick Access</h4>
            </div>
            <div class="buttonContainer">
                <a class="button" href="https://co.camwin.com.kh/login">
                    <i class="fas fa-file-pdf"></i>
                    <span>Document Manager</span>
                </a>
                <a class="button" href="https://doc.camwin.com.kh/login">
                    <i class="fas fa-file-archive"></i>
                    <span>Cloud File Manager</span>
                </a>
                <a class="button" href="https://camwin-consulting.odoo.com/web/login">
                    <i class="fas fa-users"></i>
                    <span>CAMwin Accounting</span>
                </a>
            </div>
        </div>
    </div>
</div>