<section class="bg-0 p-t-60 ">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-10 col-md-10 p-b-35">
                <!-- Title section -->
                <div class="flex-col-c-c p-b-50">
                    <h3 class="t1-b-1 cl-3 m-b-11 txt-center">
                        Company History
                    </h3>

                    <div class="size-a-2 bg-3"></div>
                </div>

                <div class="p-r-0-sr767">
                    <p class="t1-s-2 cl-1 m-b-10 cardText" [innerHTML]="informationStore?.arrInformation[0]?.company_history | safe:'html'" style="word-break: break-word;">
                    </p>

                </div>
            </div>

        </div>
    </div>
</section>
