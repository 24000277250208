<!-- <section class="bg-img1 kit-overlay1">
  <div class="container size-h-3 p-tb-30 flex-col-c-c">
      <h2 class="t1-b-1 text-uppercase cl-0 txt-center m-b-25">
        {{this.serviceStore?.Service?.title}}
      </h2>

      <div class="flex-wr-c-c">
          <a [routerLink]="['/']" class="breadcrumb-item">
              Home
          </a>

          <a [routerLink]="['/services']" class="breadcrumb-item">
              Services
          </a>

          <span class="breadcrumb-item">
              Service Detail
          </span>
      </div>
  </div>
</section> -->
<section class="bg-0 p-t-100">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-lg-9 p-b-30">
        <div class="row p-r-35 p-r-0-sr991">
          <div class="col-sm-12 p-b-35">
            <div>
              <div class="p-b-14">
                <h4 class="t1-b-3 cl-3 m-b-11 cardDetailTitle">
                  {{this.serviceStore?.Service?.title}}
                </h4>
                <div class="flex-wr-s-c m-b-11 p-b-30">
                  <div class="p-r-20">
                    <i class="fs-14 cl-7 fa fa-calendar m-r-2 cardDetailText"></i>

                    <span class="t1-s-2 cl-7 cardDetailText">
                      {{ serviceStore?.Service?.created_at.toDate() | date : 'medium' }}
                    </span>
                  </div>

                  <div class="p-l-20 bo-l-1 bcl-12">
                    <i class="fs-14 cl-7 fa fa-user m-r-2 cardDetailText"></i>

                    <a class="t1-s-2 cl-11 hov-link3 cardDetailText">
                      {{ serviceStore?.Service?.created_by?.name }}
                    </a>
                  </div>
                </div>

                <!-- <p class="t1-s-2 cl-6 m-b-9 cardDetailText">
                  {{this.serviceStore?.Service?.description}}
                </p> -->
                <p class="t1-s-2 cl-1 m-b-9 cardDetailText" [innerHTML]="this.serviceStore?.Service?.descriptions">
                  <!-- {{this.serviceStore?.Service?.description}} -->
                </p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <mat-grid-list cols="3" rowHeight="200px">
              <mat-grid-tile *ngFor="let img of this.serviceStore?.Service?.images">
                <a href={{img?.downloadURL}} target="new">
                  <img style="width: 100%;height: 100%;object-fit: cover;" src={{img?.downloadURL}}>
                </a>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 p-b-30 serviceSidebar-container">
        <!-- Rightbar -->
        <!-- <div class="p-b-70">
          <ng-container *ngFor="let item of this.serviceStore?.ServicesNoDisable">
            <ul class="p-b-5">
              <li class="m-b-5">
                <a class='flex-wr-s-c size-a-20 bg-12 t1-m-1 cl-3 hov-3 p-l-20 trans-02'
                  [ngClass]="{'active-1':item?.key===this.serviceStore.Service?.key,'':item?.key!==this.serviceStore.Service?.key}"
                  (click)=sidebarServiceClicked(item)>
                  {{item?.title}}
                </a>
              </li>
            </ul>
          </ng-container>
          <div class="bg-11 p-all-30">
            <p class="t1-m-1 cl-0 m-b-13">
              How can we help you?
            </p>

            <p class="t1-s-2 cl-15 m-b-25">
              Many desktop publishing packages and web page editors now use Lorem Ipsum model text.
            </p>

            <a routerLink="/contact"
              class="d-inline-flex flex-c-c size-a-1 bg-0 t1-s-2 cl-6 text-uppercase hov-btn1 trans-02 p-rl-10">
              <i class="fa fa-phone-square m-r-6"></i>
              Contacts
            </a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>