import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { action, observable } from "mobx";
import { pushToObject } from "../services/random_functions/random-function.service"

@Injectable({providedIn:'root'})
export class SettingStore{

  @observable public loading:boolean=true;
  @observable public setting:any;
  @observable public settings:Array<any>;
  @observable public company_logo:any;
  @observable public footer_logo:any;
  @observable public color_theme:any;
  @observable public homeSettings:Array<any>;
  @observable public aboutSettings:Array<any>;
  @observable public font:any;
  @observable public fontSize:any;

  @observable public slide_show:any;
  @observable public our_service:any;
  @observable public why_chose_us:any;
  @observable public request_a_call_back:any;
  @observable public feature_projects:any;
  @observable public static:any;
  @observable public lastest_from_news:any;
  @observable public business_partner:any;
  @observable public testimonial:any;
  @observable public sing_up:any;

  @observable public about_mission:any;
  @observable public about_why_chose_us:any;
  @observable public about_statistic:any;
  @observable public about_meet_our_team:any;
  @observable public about_testimonial:any;

  constructor(
    private afs: AngularFirestore,
  ) { }

  @action
  async changeFontSize(value:any){
    this.loading=true;
    try{
      await this.afs.collection('settings').doc('font_size').update({'value':value})
      this.loading=false;
    }catch(e){
      this.loading=true;
    }
  }

  async getFontSize(){
    this.loading=true;
    try{
      this.fontSize = pushToObject(await this.afs.collection('settings').doc('font_size').get().toPromise())
      this.loading=false;
    }catch(e){
      this.loading=false;
    }
  }

  @action
  async getFont(){
    this.loading=true;
    try{
      this.font = pushToObject(await this.afs.collection('settings').doc('font_family').get().toPromise())
      this.loading=false;
    }catch(e){
      this.loading=false;
    }
  }

  @action
  changeFont(value:any){
    this.loading=true;
    try{
      this.afs.collection('settings').doc('font_family').update({'value':value})
      this.loading=false;
    }catch(e){
      this.loading=true;
    }
  }

  @action
  getAllFont(){
    const arrFont = [
      'Roboto',
      'Open Sans',
      'Lato',
      'Montserrat',
      'Otomanopee One',
    ]
    return arrFont;
  }

  @action
  async getSetting(id:string){
    this.loading=true;
    this.setting = pushToObject(await this.afs.collection('settings').doc(id).get().toPromise());
    this.loading=false;
  }

  @action
  async getCompanyLogo(){
    this.company_logo = pushToObject(await this.afs.collection('settings').doc("company_logo").get().toPromise());
  }
  @action
  async getFooterLogo(){
    this.footer_logo = pushToObject(await this.afs.collection('settings').doc("footer_logo").get().toPromise());
  }

  @action
  async getHomeSettings(){
    try{
      this.loading=true;
      await this.afs.collection('settings',ref=>ref.where("type","==","home").orderBy('order',"asc")).valueChanges().subscribe((data:any)=>{
        this.homeSettings=data;
        for(let item of this.homeSettings){
          if(item.key==='slide_show'){
            this.slide_show=item.value;
          }
          if(item.key==='our_service'){
            this.our_service=item.value;
          }
          if(item.key==='why_choose_us'){
            this.why_chose_us=item.value;
          }
          if(item.key==='request_a_call_back'){
            this.request_a_call_back=item.value
          }
          if(item.key==='featured_projects'){
            this.feature_projects=item.value;
          }
          if(item.key==='statistic'){
            this.static=item.value;
          }
          if(item.key==='latest_from_news'){
            this.lastest_from_news=item.value;
          }
          if(item.key==='business_partners'){
            this.business_partner=item.value;
          }
          if(item.key==='testimonial'){
            this.testimonial=item.value;
          }
          if(item.key==='sign_up'){
            this.sing_up=item.value;
          }
        }
        this.loading=false;
      })
    }catch(e){
    }
  }

  @action
  async getAboutSettings(){
    try{
      this.loading=true;
      this.afs.collection('settings',ref=>ref.where("type","==","about").orderBy('order',"asc")).valueChanges().subscribe((data:any)=>{
        this.aboutSettings=data;

        for(let item of data){
          if(item.key==="about_mission"){
            this.about_mission=item.value;
          }
          if(item.key==="about_our_team"){
            this.about_meet_our_team=item.value;
          }
          if(item.key==="about_statistic"){
            this.about_statistic=item.value;
          }
          if(item.key==="about_testimonial"){
            this.about_testimonial=item.value;
          }
          if(item.key==="about_why_choose_us"){
            this.about_why_chose_us=item.value;
          }
        }


        this.loading=false;
      })
    }catch(e){
    }
  }

  @action
  async getSettings(){
    this.afs.collection('settings').valueChanges().subscribe((data:any)=>{
      this.settings=data;
    });
  }

  @action
  updateSetting(id:string,value:any){
    try{
      this.afs.collection('settings').doc(id).update({'value':value})
    }catch(e){
      console.log(e);
    }
  }
}
