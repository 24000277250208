<div class="bg-0 p-t-95 p-b-50">
    <div class="container">
        <div class="p-b-45">
            <div class="flex-wr-c-c filter-tope-group display-collapse">
                <button class="t1-m-6 cl-16 hov-btn3 trans-02 p-rl-10 p-tb-3 m-all-5 " [routerLink]="['/news']"
                    [ngClass]="{'active-2':this.Active==='allCategory','':false}" (click)="categoryClicked(null)">
                    All Categories
                </button>
                <button class="t1-m-6 cl-1 hov-btn3 trans-02 p-rl-10 p-tb-3 m-all-5"
                    *ngFor="let item of newsCategoryStore.newsCategoriesNoDisable" [routerLink]="['/news']"
                    [queryParams]="{categoryKey:item?.key}" routerLinkActive="active-2" (click)="categoryClicked(item)">
                    {{ item.name }}
                </button>
            </div>
            <!-- <div class="category-btn flex-wr-c-c ">
                <div class="btn-collapse hov-btn3">
                    <button mat-button [matMenuTriggerFor]="menu">
                        <span class="flex-group dropdown_toggle">
                            <span style="margin-right: 12px;">Category News</span>
                            <i class="fa fa-sort-desc"></i>

                        </span>
                    </button>

                </div>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="hov-btn3" [ngClass]="{'active-2':this.Active==='allCategory','':false}"
                        [routerLink]="['/news']" (click)="categoryClicked(null)">All Categories</button>
                    <button mat-menu-item class="hov-btn3"
                        *ngFor="let item of newsCategoryStore.newsCategoriesNoDisable"
                        [queryParams]="{categoryKey:item?.key}" [routerLink]="['/news']" routerLinkActive="active-2"
                        (click)="categoryClicked(item)">{{ item.name }}</button>
                </mat-menu>
            </div> -->
        </div>

        <div class="row isotope-grid display-collapse" *ngIf="newsStore.isLoading">
            <div class="col-sm-6 col-lg-4 p-b-50 isotope-item business-n" *ngFor="let item of dummyArray">
                <div class="bg-0 h-full">
                    <p-skeleton height="10rem" styleClass="p-mb-2"></p-skeleton> <br>
                    <p-skeleton width="100%" styleClass="p-mb-2"></p-skeleton> <br>
                    <p-skeleton width="5rem" styleClass="p-mb-2"></p-skeleton> <br>
                    <p-skeleton width="100%" styleClass="p-mb-2"></p-skeleton> <br>
                    <p-skeleton width="100%" styleClass="p-mb-2"></p-skeleton> <br>
                </div>
            </div>
        </div>


        <div class="emptyContentContainer"
            *ngIf="(!newsStore?.news || newsStore?.newsByCategory.length === 0) && !this.newsStore.isLoading">
            <ng-lottie width="600px" [options]="lottieConfig" (animationCreated)="animationCreated($event)"></ng-lottie>
            <p>Oops! No News Found!</p>
        </div>

        <div class="row isotope-grid display-collapse" *ngIf="!newsStore.isLoading">

            <div class="col-sm-6 col-lg-4 p-b-50 isotope-item business-n" *ngFor="let item of newsStore.newsByCategory">
                <div class="bg-0 h-full">
                    <div class="imageThumbnailContainer">
                        <img [defaultImage]="'./assets/images/circle-loading.gif'"
                            [lazyLoad]="item?.images[0]?.downloadURL" alt="IMG" class="hov-img0 of-hidden" />
                    </div>

                    <div class="bg-0 p-t-26">
                        <h4 class="p-b-12">
                            <a [routerLink]="['/news/' + item.key]" class="t1-m-1 cl-3 hov-link2 trans-02 cardTitle">
                                {{ item?.title }}
                            </a>
                        </h4>

                        <div class="flex-wr-s-c p-b-9">
                            <div class="p-r-20">
                                <i class="fs-14 cl-7 fa fa-calendar m-r-2 cardText"></i>

                                <span class="t1-s-2 cl-7 cardText">
                                    {{ item?.created_at?.toDate() | date : 'medium' }}
                                </span>
                            </div>

                            <div class="p-l-20 bo-l-1 bcl-12">
                                <i class="fs-14 cl-7 fa fa-user m-r-2 cardText"></i>

                                <a class="t1-s-2 cl-11 hov-link3 cardText">
                                    {{ item?.created_by?.name }}
                                </a>
                            </div>
                        </div>

                        <p class="t1-s-2 cl-1 p-b-20 cardText"
                            [innerHTML]="item?.descriptions | shorten : '200' : '...'">
                        </p>

                        <a [routerLink]="['/news/' + item.key]"
                            class="d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-0 bg-11 hov-btn1 trans-02">
                            Read More
                        </a>
                    </div>
                </div>
            </div>

        </div>


        <div class="row isotope-grid category-btn" *ngIf="!newsStore.isLoading">
            <ng-container *ngFor="let item of newsStore.groupBy | keyvalue">
                <div class="group-title">
                    <h1 class="t1-b-1 cl-3 txt-center m-b-11">
                        {{item?.key}}
                    </h1>
                </div>
               <div class=" col-lg-4 p-b-50 isotope-item business-n">
                
                <ng-container *ngFor="let value of item.value">
                    <div class="bg-0 h-full p-b-20">
                        <div class="imageThumbnailContainer">
                            <img [defaultImage]="'./assets/images/circle-loading.gif'"
                                [lazyLoad]="value?.images[0]?.downloadURL" alt="IMG" class="hov-img0 of-hidden" />
                        </div>

                        <div class="bg-0 p-t-26">
                            <h4 class="p-b-12">
                                <a [routerLink]="['/news/' + item.key]"
                                    class="t1-m-1 cl-3 hov-link2 trans-02 cardTitle">
                                    {{ value?.title }}
                                </a>
                            </h4>

                            <div class="flex-wr-s-c p-b-9">
                                <div class="p-r-20">
                                    <i class="fs-14 cl-7 fa fa-calendar m-r-2 cardText"></i>

                                    <span class="t1-s-2 cl-7 cardText">
                                        {{ value?.created_at?.toDate() | date : 'medium' }}
                                    </span>
                                </div>

                                <div class="p-l-20 bo-l-1 bcl-12">
                                    <i class="fs-14 cl-7 fa fa-user m-r-2 cardText"></i>

                                    <a class="t1-s-2 cl-11 hov-link3 cardText">
                                        {{ value?.created_by?.name }}
                                    </a>
                                </div>
                            </div>

                            <p class="t1-s-2 cl-1 p-b-20 cardText"
                                [innerHTML]="value?.descriptions | shorten : '200' : '...'">
                            </p>

                            <a [routerLink]="['/news/' + value.key]"
                                class="d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-0 bg-11 hov-btn1 trans-02">
                                Read More
                            </a>
                        </div>
                    </div>
                </ng-container>
            </div>
            </ng-container>

        </div>
    </div>
</div>