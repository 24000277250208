import { Component, OnInit } from '@angular/core';
import { InformationStore } from 'src/app/stores/information.store';

@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss']
})
export class ContactUsPageComponent implements OnInit {

  constructor(
    private informationStore : InformationStore,
  ) { }

  ngOnInit(): void {
    this.informationStore.getInformation();
  }

}
