<ng-container class="dialog-content-wrapper">

    <form [formGroup]="form" (ngSubmit)="formSubmit(form.value)">

      <div class="dialog-header">
        <h2 mat-dialog-title>{{this.data.formTitle}}</h2>
        <div class="space1"></div>
        <a mat-button mat-icon-button (click)="dialogRef.close()">
            <mat-icon class="mat-18">clear</mat-icon>
        </a>
      </div>

      <mat-dialog-content class="mat-typography">
        <div class="inputsDialogContainer">
          <div class="inputContainer">
            <p>News Title</p>
            <mat-form-field appearance="outline">
              <mat-label>News Title</mat-label>
              <input matInput placeholder="enter news title" formControlName="title">
              <mat-error *ngIf="form.controls['title']?.touched && form.controls['title']?.errors?.required">Name is required.</mat-error>
            </mat-form-field>
          </div>

          <div class="inputContainer">
            <p>News Category</p>
            <mat-form-field appearance="outline">
              <mat-label>News Category</mat-label>
              <mat-select formControlName="category" [compareWith]="compareFn">
                <mat-option *ngFor="let item of newsCategoryStore.newsCategoriesNoDisable" [value]="item">
                  {{item.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['category']?.touched && form.controls['category']?.errors?.required">Category is required.</mat-error>
            </mat-form-field>
          </div>

          <!-- <div class="textArea">
            <p>News Description</p>
            <mat-form-field appearance="outline" >
              <mat-label>News Description</mat-label>
              <textarea matInput rows="10" placeholder="enter news description" formControlName="description"></textarea>
              <mat-error *ngIf="form.controls['description']?.touched && form.controls['description']?.errors?.required">Description is required.</mat-error>
            </mat-form-field>
          </div> -->
          <div class="inputContainer">
            <p>News Description</p>

            <quill-editor [modules]="modules" [styles]="{height: '200px'}"
              formControlName="descriptions"></quill-editor>
          </div>
        </div>

        <div class="uploadFile">
          <p>Upload News Images</p>
          <!-- <button mat-raised-button color="primary" (click)="imageFiles.click()" type="button">
            Select Images
          </button> -->
          <button type="button" class="btnWithSpinner1" (click)="imageFiles.click()">
            <span style="margin-left: 5px;">Select Images</span>
          </button>
        </div>

        <input #imageFiles style="display: none;" type="file" id="imageFiles" accept='image/*' multiple
            (change)="onSelectedImages($event)" />

        <div class="groupButtonSelectedImages">
          <div class="buttonSelectedImage" *ngFor="let item of uploadedImages; let i=index;">
            <p>{{ item?.name }}</p>
            <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteUploadedImage(item)">
              Delete
            </button>
          </div>

          <div class="buttonSelectedImage" *ngFor="let item of selectedImages; let i=index;">
            <p>{{ item.name }}</p>
            <button mat-raised-button type="button" color="warn" class="btnDelete" (click)="deleteSelectedImage(item)">
              Delete
            </button>
          </div>
        </div>

        <ng-container *ngIf="this.percentage">
          <mat-progress-bar mode="determinate" [value]="this.percentage"></mat-progress-bar>
        </ng-container>

      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <!-- <button class="btnCancel" mat-dialog-close>
          <span class="material-icons">close</span>
          <span style="margin-left: 10px;">Close</span>
        </button> -->

        <button type="submit" class="btnWithSpinner">
          <span class="material-icons" *ngIf="!this.newsStore.isLoading && this.data?.formData?.key">save</span>
          <span class="material-icons" *ngIf="!this.newsStore.isLoading && !this.data?.formData?.key">add</span>
          <mat-spinner class="mat-spinner-color" [diameter]="20" *ngIf="this.newsStore.isLoading"></mat-spinner>
          <span style="margin-left: 10px;" *ngIf="this.data?.formData?.key">Save</span>
          <span style="margin-left: 10px;" *ngIf="!this.data?.formData?.key">Add News</span>
        </button>
      </mat-dialog-actions>

    </form>

  </ng-container>

