<section class="bg-0 p-t-92 p-b-60">
    <div class="container">
        <!-- Title section -->
        <div class="flex-col-c-c p-b-50">
            <h3 class="t1-b-1 cl-3 txt-center m-b-11">
                Our Services
            </h3>
            <div class="size-a-2 bg-3"></div>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-10 col-md-8 col-lg-4 p-b-40" *ngFor="let item of this.serviceStore.ServicesHomepage"  style="cursor:pointer;">
                <div class="bg-0 h-full shadow">
                    <!-- <div class="imageThumbnailContainer">
                        <img [defaultImage]="'./assets/images/circle-loading.gif'" [lazyLoad]="item?.images[0]?.downloadURL" alt="IMG" class="hov-img0 of-hidden" />
                    </div> -->

                    <div class="p-rl-30 p-t-26 p-b-20">
                        <h4 class="p-b-9 home-ourservice-title cardTitle">
                          {{item.title}}
                        </h4>
                        <!-- <p class="t1-s-2 cl-1 cardText">
                          {{item.description| shorten:150:'...'}}
                        </p> -->
                        <p class="t1-s-2 cl-1 cardText" [innerHTML]="item.descriptions | shorten:150:'...'">
                            <!-- {{item.description| shorten:150:'...'}} -->
                          </p>
                          <div style="margin-top: 20px;">
                            <a style="color:white;cursor:pointer;" class=" bg-11 d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-6 bg-0 hov-btnCustom trans-02"  (click)=serviceClicked(item)>
                                Read More
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
