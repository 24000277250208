import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tabs } from 'src/app/data/tabs';
import { IManagementTeam } from 'src/app/models/management-team.model';
import { DeleteConfirmationComponent } from 'src/app/shared/backend/delete-confirmation/delete-confirmation.component';
import { ManagementTeamStore } from 'src/app/stores/management_team.store';
import { AddManagementTeamComponent } from './add-management-team/add-management-team.component';
import { ManagementTeamDetailComponent } from './management-team-detail/management-team-detail.component';

@Component({
  selector: 'app-management-team-dashboard',
  templateUrl: './management-team-dashboard.component.html',
  styleUrls: ['./management-team-dashboard.component.scss']
})
export class ManagementTeamDashboardComponent implements OnInit {

  loading: boolean = false;
  TABS: Array<any> = tabs.aboutus;

  constructor(
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public managementTeamStore: ManagementTeamStore
  ) { }

  ngOnInit(): void {
    this.managementTeamStore.getManagementTeam();
  }

  async create() {
    const dialogRef = this.dialog.open(AddManagementTeamComponent, {
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Add Management Team',
        formData: null
      }
    });

    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });
  }

  edit(data: IManagementTeam) {
    const dialogRef = this.dialog.open(AddManagementTeamComponent, {
      width:'760px',
      height:'96vh',
      data: {
        formTitle: 'Edit Management Team',
        formData: data
      }
    });

    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }

  delete(data: IManagementTeam) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.managementTeamStore.deleteManagementTeam(data, (isSucess) => {
          if (isSucess) {
            this._snackBar.open('Management Team is deleted successfully.', 'Done',{ duration: 5000 })
          }
        })
      }
    });
  }

  rowClick(data:IManagementTeam){
    const dialogRef=this.dialog.open(ManagementTeamDetailComponent,{
      width:'760px',
      height:'96vh',
      data:{...data},
    })
    // dialogRef.updatePosition({ 'top': '2vh', 'right': '2vh' });
    dialogRef.updatePosition({ 'top': '2vh', 'right': '50vh' });

  }

  disable(data:IManagementTeam){
    var v : IManagementTeam;
    if(data.status==='Active'){
      v={
        ...data,
        status:'Disable'
      }
    }else{
      v={
        ...data,
        status:'Active'
      }
    }
    this.managementTeamStore.updateManagementTeamWithoutCallback(v);
  }
}
