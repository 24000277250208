<div class="bg-0 p-t-95 p-b-50">
    <div class="p-rl-50 p-rl-15-sr1199 container">
        <!-- <div class="p-b-45">
            <div class="flex-wr-c-c filter-tope-group">
                <button #allproject class="t1-m-6 cl-16 hov-btn3 trans-02 p-rl-10 p-tb-3 m-all-5 active-2" [ngClass]="{'active-2':this.Active==='allProject','':this.Active}" (click)=allCategoryClick()>
                    All Projects
                </button>

                <button class="t1-m-6 cl-16 hov-btn3 trans-02 p-rl-10 p-tb-3 m-all-5" *ngFor="let item of this.projectCategoryStore?.categorys"  (click)=categoryClick(item) [ngClass]="{'active-2':this.Active===item.name,'':false}">
                    {{item.name}}
                </button>
            </div>
        </div> -->

        <div class="emptyContentContainer" *ngIf="(!projectList || projectList.length === 0)&&this.Active!=='allProject'">
          <ng-lottie width="600px" [options]="lottieConfig" (animationCreated)="animationCreated($event)"></ng-lottie>
          <p>Oops! No Projects Found!</p>
        </div>

        <!-- <div class="row isotope-grid" *ngIf="!this.projectStore.isLoading && this.Active==='allProject';else byCategory"> -->
        <div class="row isotope-grid" *ngIf="!this.projectStore.isLoading">
          <div class="col-sm-6 col-lg-4 p-b-45 isotope-item business-n" *ngFor="let item of this.projectStore.projectsNoDisable">
              <div class="flex-col-s-c">
                  <div class="w-full pos-relative wrap-pic-w m-b-16">
                      <!-- <img *ngIf="item?.images?.downloadURL; else defaultImage" style="object-fit: cover;" src={{item?.images?.downloadURL}} alt="IMG" height="200vh">
                      <ng-template #defaultImage>
                        <img style="object-fit: cover;" src="../../../../../assets/images/default-image.png" alt="IMG" height="200vh">
                      </ng-template>
                      <img style="object-fit: cover;" src="./assets/customization-assets/images/project-05.jpg" alt="IMG" height="300">
                      <div class="s-full ab-t-l flex-col-c-c hov-1 p-tb-30 p-rl-15" >
                          <a style="cursor:pointer;color:white;" class="size-a-15 d-inline-flex flex-c-c bg-11 t1-s-2 text-uppercase cl-0 hov-btn2 trans-02 p-rl-10 hov-1-2 hov-project" (click)="projectClick(item)">
                              View Projects
                          </a>
                      </div> -->
                      <div class="block2 shadow">
                        <a (click)="projectClick(item)">
                          <div class="block2 " (click)="projectClick(item)">
                            <div>
                                <h4 class=" t1-m-1 cl-1 p-b-10 p-t-10 flex-s-c cardTitle">
                                    {{item.name}}
                                  </h4>
            
                                  <!-- <p class="t1-s-2 cl-1 p-b-10 p-t-10 cardText">
                                    {{item.description| shorten: 180 : '...'}}
                                  </p> -->
                                  <p class="t1-s-2 cl-1 p-b-10 p-t-10 cardText" [innerHTML]="item.descriptions | shorten: 180 : '...'">
                                    <!-- {{item.description| shorten: 180 : '...'}} -->
                                  </p>
                            </div>
                            <div>
                                <a style="color:white;cursor:pointer;" class=" bg-11 d-inline-flex flex-c-c size-a-1 p-rl-15 t1-s-2 text-uppercase cl-6 bg-0 hov-btnCustom trans-02"  (click)="projectClick(item)">
                                    Read More
                                </a>
                            </div>
                            
                          </div>
                        </a>
                      </div>
                  </div>
                  <!-- <a style="cursor:pointer;" class="t1-m-1 cl-3 hov-link2 trans-02 cardTitle" (click)='projectClick(item)'>
                      {{item.name}}
                  </a> -->
              </div>
          </div>
        </div>

        <!-- <ng-template #byCategory>
            <div class="row isotope-grid" *ngIf="!this.projectStore.isLoading">
                <div class="col-sm-6 col-lg-4 p-b-45 isotope-item business-n" *ngFor="let item of this.projectList">
                    <div class="flex-col-s-c">
                        <div class="w-full pos-relative wrap-pic-w m-b-16">
                            <img *ngIf="item?.images[0]?.downloadURL; else defaultImage" style="object-fit: cover;" src={{item?.images[0]?.downloadURL}} alt="IMG" height="200vh">
                            <ng-template #defaultImage>
                              <img style="object-fit: cover;" src="../../../../../assets/images/default-image.png" alt="IMG" height="200vh">
                            </ng-template>
                            <div class="s-full ab-t-l flex-col-c-c hov-1 p-tb-30 p-rl-15">
                                <a style="cursor:pointer;" class="size-a-15 d-inline-flex flex-c-c bg-11 t1-s-2 text-uppercase cl-0 hov-btn2 trans-02 p-rl-10 hov-1-2" (click)=projectClick(item)>
                                    View Projects
                                </a>
                            </div>
                        </div>

                        <a style="cursor:pointer;" class="t1-m-1 cl-3 hov-link2 trans-02 cardTitle" (click)=projectClick(item)>
                            {{item.name}}
                        </a>
                    </div>
                </div>
            </div>
        </ng-template> -->
    </div>
</div>
