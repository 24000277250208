<section class="bg-0 p-t-60 p-b-52">
    <div class="container">
        <!-- Title section -->
        <div class="flex-col-c-c p-b-50">
            <h3 class="t1-b-1 cl-3 txt-center m-b-11">
                Meet Our Team
            </h3>

            <div class="size-a-2 bg-3"></div>
        </div>

        <!--  -->
        <div class="row justify-content-center">
            <div class="col-sm-6 col-md-5 col-lg-4 p-b-40" *ngFor="let item of this.managementTeamStore.managementTeamNoDisable">
                <div>
                    <!-- <div class="wrap-pic-w pos-relative">
                        <img src={{item?.image?.downloadURL}} alt="IMG">

                        <div class="s-full ab-t-l flex-wr-c-c p-tb-30 hov-2">
                            <a *ngIf="item?.socialMedia?.facebook" href={{item?.socialMedia?.facebook}} class="flex-c-c size-a-7 borad-50per bg-0 fs-16 cl-11 hov-btn3 m-all-5 hov-2-1" target="new">
                                <i class="fa fa-facebook"></i>
                            </a>

                            <a *ngIf="item?.socialMedia?.twitter" href={{item?.socialMedia?.twitter}} class="flex-c-c size-a-7 borad-50per bg-0 fs-16 cl-11 hov-btn3 m-all-5 hov-2-1" target="new">
                                <i class="fa fa-twitter"></i>
                            </a>

                            <a *ngIf="item?.socialMedia?.email" href={{item?.socialMedia?.email}} class="flex-c-c size-a-7 borad-50per bg-0 fs-16 cl-11 hov-btn3 m-all-5 hov-2-1" target="new">
                                <i class="fa fa-google-plus"></i>
                            </a>

                            <a *ngIf="item?.socialMedia?.instagram" href={{item?.socialMedia?.instagram}} class="flex-c-c size-a-7 borad-50per bg-0 fs-16 cl-11 hov-btn3 m-all-5 hov-2-1" target="new">
                                <i class="fa fa-instagram"></i>
                            </a>

                            <a *ngIf="item?.socialMedia?.linkedin" href={{item?.socialMedia?.linkedin}} class="flex-c-c size-a-7 borad-50per bg-0 fs-16 cl-11 hov-btn3 m-all-5 hov-2-1" target="new">
                                <i class="fa fa-linkedin"></i>
                            </a>
                        </div>
                    </div>

                    <div class="flex-col-c-c p-t-28">
                        <a class="t1-m-1 text-uppercase cl-3 txt-center hov-link2 trans-02 m-b-5 cardTitle">
                            {{item?.name}}
                        </a>

                        <span class="t1-s-5 cl-6 txt-center cardText">
                            {{item?.position}}
                        </span>
                    </div> -->
                    <div class="wrapper-card shadow ">
                        <div class="content-title ">
                            <div class="p-b-10 p-t-10 title cl-1">
                                {{item?.name}}
                            </div>
                            <p class="p-b-10 p-t-10 position">
                            {{item?.position}}

                            </p>
                        </div>
                        <div class="content t1-s-2 cl-1 cardText" [innerHTML]="item?.descriptions"></div>                 

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
